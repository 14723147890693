<template>
  <v-container v-for="a in accounts" :key="a.id">
    <v-row>
-      <v-col>Created at {{ epochToLocalTimeStr(a.created) }}</v-col>
-   </v-row>
    <v-row class="text-center py-2" justify="space-around" no-gutters>
      <v-col>
        <v-table density="compact">
          <thead>
            <tr>
              <th class="text-center header-text">Name</th>
              <th class="text-center header-text">Email</th>
              <th class="text-center header-text">Phone</th>
              <th class="text-center header-text">Is Adult?</th>
              <th class="text-center header-text">Role</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="u in a.users" :key="u.id">
              <td>{{ u.name }}</td>
              <td>{{ u.email }}</td>
              <td>{{ u.phone }}</td>
              <td>{{ u.is_adult }}</td>
              <td>{{ rolesToStr(u.roles) }}</td>
            </tr>
          </tbody>
        </v-table>
      </v-col>
    </v-row>
  </v-container>
</template>
      
<script>
import common from '@/assets/mixins/common.js';
import Service from '@/service.js'
    
export default {
  name: 'ba-admin-account-page',
  mixins: [common],
  data: () => ({
    accounts: []
  }),
  computed: {
  },
  methods: {
  },
  mounted() {
    this.accounts = [];
    new Service().getAdminLoadAccount(
      this.getToken,
      (response) => { // eslint-disable-line no-unused-vars
        this.accounts = response.data.accounts;
      },
      (error) => { // eslint-disable-line no-unused-vars
        console.log(error);
      }
    );
  }
}
</script>
    
<style lang="scss" scoped>
.header-text {
  font-family: "Work Sans";
  color: #460303;
  font-weight: 600;
  font-size: 1.5rem;
}

.body-text {
  font-family: "Work Sans";
  font-weight: 400;
  font-size: 1rem;
}
</style>
        