<template>
  <v-container>
    <v-row justify="space-around">
      <v-col class="v-col-xl-8 v-col-lg-10 v-col-md-10">
        <v-container class="py-1">
          <v-row class="text-center " align="center" justify="space-around" no-gutters>
            <v-col class="px-2">
              <div class="ba-header d-flex justify-space-between">
                <div class="d-flex flex-row">
                  <router-link to="/" >
                    <v-img src="@/assets/logo.png" class="logo"></v-img>
                  </router-link>
                </div>
                
                <div class="d-flex flex-row-reverse my-2">
                  <div v-if="isLogined" style="display: flex;">
                    <router-link to="/profile" class="px-1 py-1">{{ getUserName }}</router-link><span class="px-1 py-1">/</span><a href="/" class="px-1 py-1" @click="signout">{{ $t('header_signout') }}</a>
                  </div>
                  <div v-if="false" style="display: flex;">
                    <router-link to="/signin" class="px-1 py-1">{{ $t('signinview_signin') }}</router-link>
                    <span class="px-1 py-1">/</span>
                    <router-link to="/signup" class="px-1 py-1">{{ $t('signinview_signup') }}</router-link>
                  </div>
                  <router-link to="/courses" class="px-8 py-1">{{ $t('header_allcourses') }}</router-link>
                  <router-link to="/#categories" class="px-8 py-1">{{ $t('header_categories') }}</router-link>
                </div>
              </div>
            </v-col>
            <v-col cols="2">
              <v-select class="py-0 my-0" v-model="language" :items="getLanguage" item-title="name" item-value="value" variant="underlined">
              </v-select>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>
  
<script>
import Service from '@/service.js'
import common from '@/assets/mixins/common.js';

export default {
  name: 'ba-header',
  mixins: [common],
  data: () => ({
    language: null
  }),
  computed: {
    getLanguage() {
      return this.$store.getters.getLocalization
    }
  },
  methods: {
    signout() {
      this.$store.commit('clearUser');
      this.$router.push('/login');
    },
  },
  watch: {
    language(newValue) {
      if (this.$i18n.locale != newValue) {
        this.$i18n.locale = newValue;
      }
    },
    '$i18n.locale': function(newValue) {
      if (this.language != newValue) {
        this.language = newValue;
      }
      new Service().loadWithLang(this.$store, newValue);
    }
  },
  mounted () {
    this.language = this.$store.getters.getLocalization.filter(x=>x.value == this.$i18n.locale)[0].value
  }
}
</script>

<style lang="scss" scoped>
.ba-header {
  width: 100%;
}

.logo {
  width: 48px;
  height: 48px;
}

a {
  text-decoration: none;
  display: block;
  font-family: sans-serif;
  font-weight: 600;
  font-size: 1rem;
  color: #2b0101;
}

</style>
  