import axios from 'axios';
import ServiceBase from './base.js'

export default {
  ...ServiceBase,

  // load data
  getAdminLoadAccount(token, succeed = (response)=>{}, failure = (error)=>{}, postExecution = ()=>{}) {  // eslint-disable-line no-unused-vars
    axios.get(this.V1_SERVICE_PREFIX + '/admin/listaccounts', {
      params: {
        token: this.trim(token)
      }
      
    })
    .then(succeed)
    .catch(failure)
    .finally(postExecution)
    ;
  },

  getAdminLoadPayment(token, succeed = (response)=>{}, failure = (error)=>{}, postExecution = ()=>{}) {  // eslint-disable-line no-unused-vars
    axios.get(this.V1_SERVICE_PREFIX + '/admin/listpayments', {
      params: {
        token: this.trim(token)
      }
      
    })
    .then(succeed)
    .catch(failure)
    .finally(postExecution)
    ;
  },

  getAdminLoadCourse(token, succeed = (response)=>{}, failure = (error)=>{}, postExecution = ()=>{}) {  // eslint-disable-line no-unused-vars
    axios.get(this.V1_SERVICE_PREFIX + '/admin/listcourses', {
      params: {
        token: this.trim(token)
      }
    })
    .then(succeed)
    .catch(failure)
    .finally(postExecution)
    ;
  },

  postAdminLoadAccount(token, succeed = (response)=>{}, failure = (error)=>{}, postExecution = ()=>{}) {  // eslint-disable-line no-unused-vars
    axios.get(this.V1_SERVICE_PREFIX + '/admin/getaccount', {
      params: {
        token: this.trim(token)
      }
      
    })
    .then(succeed)
    .catch(failure)
    .finally(postExecution)
    ;
  },
}