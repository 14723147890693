<template>
<v-container>
  <v-row>
    <v-col class="text-center table-head">All Users</v-col>
  </v-row>
  <v-row class="text-center py-2" justify="space-around" no-gutters>
    <v-col class="v-col-auto">
      <v-table fixed-header density="compact">
        <thead>
          <th class="px-1">ID</th>
          <th class="px-1">Name</th>
          <th class="px-1">Email</th>
          <th class="px-1">Roles</th>
          <th class="px-1">Phone</th>
        </thead>
        <tr v-for="(u, index) in getUsers" :key="index">
          <td class="px-1">{{ u.id }}</td>
          <td class="px-1">{{ u.name }}</td>
          <td class="px-1">{{ u.email }}</td>
          <td class="px-1">{{ rolesToStr(u.roles) }}</td>
          <td class="px-1">{{ u.phone }}</td>
        </tr>
      </v-table>
    </v-col>
  </v-row>
</v-container>
</template>

<script>
import Service from '@/service.js'
import common from '@/assets/mixins/common.js';

export default {
  name: 'ba-user-mgr',
  mixins: [common],
  data: () => ({
    users: [],
  }),
  computed: {
    getLoading() {
      return this.isLoading;
    },
    getUsers() {
      console.log(this.users)
      return this.users;
    }
  },
  methods: {
  },
  mounted () {
    this.users = [];
    new Service()
    .getListUsers(
      this.getToken, 
      (response) => {
        this.users = response.data.account.users
      },
      (error) => { // eslint-disable-line no-unused-vars

      }
    )
  }
}
</script>
    
<style lang="scss" scoped>
.table-head {
  color: #2b0101;
  font-weight: 700;
  font-family: "Work Sans";
  padding-bottom: 1.25rem;
  padding-top: 0rem;
  letter-spacing: 0rem;
  font-size: 1.5rem;
  line-height: 1.5;
  white-space: pre-line;
  margin-top: 0;
  margin-bottom: 1rem;
  display: block;
}
</style>
        