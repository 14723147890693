import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AllCourseView from '../views/AllCourseView.vue'
import AboutView from '../views/AboutView.vue'
import ContactUsView from '../views/ContactUsView.vue'
import CheckoutView from '../views/GuestCheckout.vue'
import ThankyouView from "../views/ThankyouView.vue"
import CourseView from "../views/CourseView.vue"
import SignInView from "../views/SignInView.vue"
import SignUpView from "../views/SignInView.vue"
import ForgotPasswordView from "../views/ForgotPasswordView.vue"
import UserProfileView from "../views/UserProfileView.vue"
import PrivacyPolicyView from "../views/PrivacyPolicyView.vue"
import TermsOfServiceView from "../views/TermsOfServiceView.vue"
import ServiceProviderAgreementView from "../views/ServiceProviderAgreementView.vue"
import AdminView from "../views/AdminView.vue"

const routes = [
  {
    path: '/', name: 'home', component: HomeView,
    meta: {
      title: 'Beyond Activity',
      metaTags: [{
        name: 'description',
        contact: 'The home page of Beyond Activity'
      }]
    }
  },
  {
    path: '/courses', name: 'courses',
    component: AllCourseView, meta: {
      title: 'Courses provided by Beyond Activity',
      metaTags: [{
        name: 'description',
        contact: 'The courses page provided by Beyond Activity'
      }]
    }
  },
  {
    path: '/about', name: 'about',
    component: AboutView, meta: {
      title: 'About Beyond Activity',
      metaTags: [{
        name: 'description',
        contact: 'The about page for Beyond Activity'
      }]
    }
  },
  {
    path: '/contact-us', name: 'contactus',
    component: ContactUsView, meta: {
      title: 'Contact Beyond Activity',
      metaTags: [{
        name: 'description',
        contact: 'The contact page of Beyond Activity'
      }]
    }
  },
  {
    path: '/checkout/:name', name: 'checkout',
    component: CheckoutView, meta: {
      title: 'Beyond Activity Checkout',
      metaTags: [{
        name: 'description',
        contact: 'The checkout page of Beyond Activity'
      }]
    }
  },
  {
    path: '/thankyou', name: 'thankyou',
    component: ThankyouView, meta: {
      title: 'Thank you',
      metaTags: [{
        name: 'description',
        contact: 'The Thank you page of Beyond Activity'
      }]
    }
  },
  {
    path: '/course/:name', name: 'course',
    component: CourseView, meta: {
      title: 'Course Page',
      metaTags: [{
        name: 'description',
        contact: 'The Course page of Beyond Activity'
      }]
    }
  },
  {
    path: '/signin', name: 'signin',
    component: SignInView, meta: {
      title: 'Sign In',
      metaTags: [{
        name: 'description',
        contact: 'The Sign In page of Beyond Activity'
      }]
    }
  },
  {
    path: '/signup', name: 'signup',
    component: SignUpView, meta: {
      title: 'Sign Up',
      metaTags: [{
        name: 'description',
        contact: 'The Sign Up page of Beyond Activity'
      }]
    }
  },
  {
    path: '/forgotpassword', name: 'forgotpassword',
    component: ForgotPasswordView, meta: {
      title: 'Forgot Password',
      metaTags: [{
        name: 'description',
        contact: 'The Forgot password page of Beyond Activity'
      }]
    }
  },
  {
    path: '/profile', name: 'profile',
    component: UserProfileView, meta: {
      title: 'User Profile',
      metaTags: [{
        name: 'description',
        contact: 'The User Profile page of Beyond Activity'
      }]
    }
  },
  {
    path: '/privacypolicy', name: 'privacypolicy',
    component: PrivacyPolicyView, meta: {
      title: 'Privacy Policy',
      metaTags: [{
        name: 'description',
        contact: 'The Privacy Policy of Beyond Activity'
      }]
    }
  },
  {
    path: '/termsofservice', name: 'termsofservice',
    component: TermsOfServiceView, meta: {
      title: 'Terms of Service',
      metaTags: [{
        name: 'description',
        contact: 'The Terms of Service of Beyond Activity'
      }]
    }
  },
  {
    path: '/serviceprovideragreement', name: 'serviceProviderAgreement',
    component: ServiceProviderAgreementView, meta: {
      title: 'Service Provider Agreement',
      metaTags: [{
        name: 'description',
        contact: 'The Service Provider Agreement with Beyond Activity'
      }]
    }
  },
  {
    path: '/admin', name: 'Admin Page', component: AdminView,
    meta: {
      title: 'Beyond Activity Admin Page',
      metaTags: [{
        name: 'description',
        contact: 'The admin page of Beyond Activity'
      }]
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if(nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if(previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if(!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });

    // We use this to track which meta tags we create so we don't interfere with other ones.
    //tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  })
  // Add the meta tags to the document head.
  .forEach(tag => document.head.appendChild(tag));

  next();
});

export default router
