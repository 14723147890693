<template>
  <v-container>
    <v-row class="text-center py-2" justify="space-around" no-gutters>
      <v-col cols="2">
        <v-list lines="one">
          <v-list-item :class="{ 'item-active': isAccountActive }" title="Account" @click="choose('account')"></v-list-item>
          <v-list-item :class="{ 'item-active': isCourseActive }" title="Course" @click="choose('course')"></v-list-item>
          <v-list-item :class="{ 'item-active': isPaymentActive }" title="Payment" @click="choose('payment')"></v-list-item>
        </v-list>
      </v-col>
      <v-col>
        <AccountPage v-if="isAccountActive"></AccountPage>
        <CoursePage v-if="isCourseActive"></CoursePage>
        <PaymentPage v-if="isPaymentActive"></PaymentPage>
      </v-col>
    </v-row>
  </v-container>
</template>
    
<script>
import common from '@/assets/mixins/common.js';
import AccountPage from '@/components/admin/pages/AccountPage.vue'
import CoursePage from '@/components/admin/pages/CoursePage.vue'
import PaymentPage from '@/components/admin/pages/PaymentPage.vue'

export default {
  name: 'ba-admin-page',
  mixins: [common],
  components: {
    AccountPage,
    CoursePage,
    PaymentPage,
  },
  data: () => ({
    selected: 'account'
  }),
  computed: {
    isAccountActive() {
      return this.selected === 'account';
    },
    isCourseActive() {
      return this.selected === 'course';
    },
    isPaymentActive() {
      return this.selected === 'payment';
    }
  },
  methods: {
    choose(clicked) {
      this.selected = clicked
    }
  }
}
</script>
    
<style lang="scss" scoped>
.item-active {
  color: white;
  background-color: #460303;
  font-family: "Work Sans";
  font-weight: 600;
}
</style>
      