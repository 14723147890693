<template>
  <div class="d-flex justify-space-between pt-3 pb-3 ml-5 mr-5">
    <div class="d-flex flex-row">
      <router-link to="/" >
        <v-img src="@/assets/logo.png" class="logo"></v-img>
      </router-link>
    </div>
    <div class="d-flex flex-row-reverse">
      <v-app-bar-nav-icon @click.stop="displayDrawer = !displayDrawer"></v-app-bar-nav-icon>
    </div>
  </div>
  <div height="100vh"></div>
  <v-navigation-drawer temporary location="right" v-model="displayDrawer">
    <v-img src="@/assets/logo.png" class="logo"></v-img>
    <v-divider></v-divider>
    <v-list nav>
      <v-list-item><a target href="/#categories" class="list-item px-0 py-1">{{ $t('header_categories') }}</a></v-list-item>
      <v-list-item><router-link to="/courses" class="list-item px-0 py-1">{{ $t('header_allcourses') }}</router-link></v-list-item>
    </v-list>
    <v-select class="mx-2" v-model="language" :items="getLanguage" item-title="name" item-value="value" variant="underlined">
    </v-select>
    <v-btn class="pl-1 pr-1 ml-2 mr-2" @click="onClose">{{ $t('common_close') }}</v-btn>
  </v-navigation-drawer>
</template>
  
<script>
import Service from '@/service.js'

export default {
  name: 'ba-right-navigation',
  data: () => ({
    displayDrawer: null,
    language: null
  }),
  computed: {
    getLanguage() {
      return this.$store.getters.getLocalization
    }
  },
  methods: {
    goto(url) {
      this.$router.push(url)
    },
    onClose() {
      this.displayDrawer = false;
    }
  },
  watch: {
    language(newValue) {
      if (this.$i18n.locale != newValue) {
        this.$i18n.locale = newValue;
      }
    },
    '$i18n.locale': function(newValue) {
      if (this.language != newValue) {
        this.language = newValue
      }
      new Service().loadWithLang(this.$store, newValue);
    }
  },
  mounted () {
    this.language = this.$store.getters.getLocalization.filter(x=>x.value == this.$i18n.locale)[0].value
  }
}
</script>

<style lang="scss" scoped>

.logo {
  width: 48px;
  height: 48px;
}

.list-item {
  font-size: 0.8125rem;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 1rem;
  hyphens: auto;
  padding: 0;
  white-space: nowrap;
  word-break: normal;
  word-wrap: break-word;
  text-transform: none;
  margin: 0;
  display: block;
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

</style>
  