import axios from 'axios';
import ServiceBase from './base.js'

let currentLang = null;

export default {
  ...ServiceBase,

  // load with new language
  loadWithLang(store, lang = 'en') {
    if (currentLang != lang) {
      currentLang = lang;
      axios.get('https://resource.beyondactivity.com/lang/course_'+lang+'.json')
      .then((resp) => {
        store.commit('setAllCourses', resp.data.courses);
    })
    }
  },
}