<template>
<CommonPageFramework>
  <div v-if="this.course == null">
    <v-container fill-height>
      <v-row>
        <v-col class="">{{ $t('courseview_sorry') }}</v-col>
      </v-row>
    </v-container>
  </div>
  <div class="py-10" style="background-color: #f7f2f2;" v-else-if="$vuetify.display.mdAndUp">
    <v-container fluid fill-height>
      <v-row no-gutters>
        <v-col class="offset-lg-1 pl-10">
          <v-container>
            <v-row>
              <v-col>
                <p class="ba-course-title py-4">{{ course.title }}</p>
                <p class="ba-course-provider py-4">{{ course.providedBy }}</p>
              </v-col>
            </v-row>
            <v-row v-if="false">
              <v-col>
                <p>{{ $t('courseview_instructedby') }}</p>
              </v-col>
              <v-col>
                <p>{{ $t('courseview_categorydance') }}</p>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="ba-course-desc">
                <p class="py-4">{{ $t('courseview_coursedescription') }}</p>
                <!-- temporarily hard coded -->
                <div v-if="course.title == 'ELA and Reading'" class="d-flex justify-space-around align-center ba-course-desc-content">
                  <div class="ma-4">
                    <div class="text-subtitle-2">Reading Class</div>
                    <v-img class="bg-white" min-width="300" src="https://resource.beyondactivity.com/reading_2023.jpg"></v-img>
                  </div>
                  <div class="ma-4">
                    <div class="text-subtitle-2">Writing Class</div>
                    <v-img class="bg-white" min-width="300" src="https://resource.beyondactivity.com/writing_2023.jpg"></v-img>
                  </div>
                </div>
                <!-- temporarily hard coded -->
                <div class="ba-course-desc-content pb-4" v-for="(desc, key) in course.description" :key="key">
                  <p v-if="this.isDescription(desc)"><span v-html="desc"></span></p>
                  <div v-if="this.isTitleInObject(desc)" class="courseDescriptionHighlight pl-5">
                    <ul>
                      <li><span v-html="this.getTitleInObject(desc)"></span></li>
                    </ul>
                  </div>
                  <p v-if="this.isDescriptionInObject(desc)"><span v-html="this.getDescriptionInObject(desc)"></span></p>
                  <div v-if="this.isListInObject(desc)" class="pl-8">
                    <ul>
                      <li v-for="(item, key) in this.getListInObject(desc)" :key="key"><span v-html="item"></span></li>
                    </ul>
                  </div>
                </div>
              </v-col>
            </v-row>
            <v-row v-if="displayOptions">
              <v-col>
                <v-radio-group v-model="selectedOption" column>
                  <v-radio v-for="(item, index) in this.course.options" :key="index" :value="index" :label="item.name" class="px2"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row justify="start">
              <v-col class="px-0 pt-4 pb-8">
                <InterestForm :courseOptions="this.courseOptions"></InterestForm>
                <router-link class="clickToRegisterBtn px-4 mx-4" v-if="course.acceptRegistration"  :to="getCheckoutUrl">{{ $t('courseview_clicktoregister') }}</router-link>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <p class="ba-session-title">{{ $t('courseview_sessions') }}</p>
              </v-col>
            </v-row>
            <v-row class="pb-6">
              <v-col>
                <v-tabs v-model="sessionType">
                  <v-tab value="upcoming">{{ $t('courseview_upcoming') }}</v-tab>
                  <v-tab value="completed">{{ $t('courseview_completed') }}</v-tab>
                </v-tabs>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <div v-for="(session, key) in displaySession" :key="key">
                  <p class="ba-session-date"><font-awesome-icon class="px-2" icon="fas fa-clock"/>{{ session.date }}
                    <span v-if="isUpcomingSession && session.price != null" class="ba-text ml-4">${{ session.price }}</span>
                    <router-link v-if="isUpcomingSession && session.price != null" class="clickToRegisterSessionBtn ml-2" :to="this.getCheckoutUrlBySession(session.id)">{{ $t('courseview_register') }}</router-link>
                  </p>
                  <p class="ba-session-time pl-8">From {{ session.start }} to {{ session.end }}</p>
                  <p class="ba-session-location pl-8">At {{ session.location }}</p>
                  <v-divider class="my-6 mx-6" v-if="key != displaySession.length-1"></v-divider>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
        <v-col class="v-col-md-4 v-col-lg-3 pr-10">
          <!-- This is sidebar -->
          <v-container class="ba-card my-8" style="box-shadow: 0 2px 4px 3px rgb(223 223 223 / 50%);">
            <v-row>
              <v-col><v-img :src="course.image"></v-img></v-col>
            </v-row>
            <v-row justify="space-between">
              <v-col class="text-left" style="display: flex;"><font-awesome-icon class="px-2 ba-icon" icon="fas fa-users"/><span class="ba-text">{{ $t('courseview_attendees') }}</span></v-col>
              <v-col class="text-right"><span class="ba-text">{{ course.seats }}</span></v-col>
            </v-row>
            <v-row justify="space-between">
              <v-col class="text-left" style="display: flex;"><font-awesome-icon class="px-2 ba-icon" icon="fas fa-clock"/><span class="ba-text">{{ $t('courseview_duration') }}</span></v-col>
              <v-col class="text-right"><span class="ba-text">{{ course.duration }}</span></v-col>
            </v-row>
            <v-row justify="space-between">
              <v-col class="text-left" style="display: flex;"><font-awesome-icon class="px-2 ba-icon" icon="fas fa-child"/><span class="ba-text">{{ $t('courseview_age') }}</span></v-col>
              <v-col class="text-right"><span class="ba-text">{{ course.age }}</span></v-col>
            </v-row>
            <v-row justify="space-between">
              <v-col class="text-left" style="display: flex;"><font-awesome-icon class="px-2 ba-icon" icon="far fa-credit-card"/><span class="ba-text">{{ $t('courseview_price') }}</span></v-col>
              <v-col class="text-right"><span class="ba-text">{{ getCoursePrice }}</span></v-col>
            </v-row>
            <v-row justify="space-between">
              <v-col class="text-left" style="display: flex;"><font-awesome-icon class="px-2 ba-icon" icon="fas fa-language"/><span class="ba-text">{{ $t('courseview_languageofinstruction') }}</span></v-col>
              <v-col class="text-right"><span class="ba-text">{{ course.language }}</span></v-col>
            </v-row>
            <v-row justify="space-between">
              <v-col class="text-left" style="display: flex;"><font-awesome-icon class="px-2 ba-icon" icon="fab fa-connectdevelop"/><span class="ba-text">{{ $t('courseview_languagelevel') }}</span></v-col>
              <v-col class="text-right"><span class="ba-text">{{ course.level }}</span></v-col>
            </v-row>
          </v-container>
          <v-container class="ba-card my-8" style="box-shadow: 0 2px 4px 3px rgb(223 223 223 / 50%);">
            <v-row>
              <v-col>
                <p class="ba-material">{{ $t('courseview_materials') }}</p>
                <p>{{ course.materials }}</p>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </div>
  <div v-else style="background-color: #f7f2f2;">
    <v-container fill-height>
      <v-row>
        <v-col class="px-10">
          <v-container>
            <v-row>
              <v-col>
                <p class="ba-course-title py-4">{{ course.title }}</p>
                <p class="ba-course-provider py-4">{{ course.providedBy }}</p>
              </v-col>
            </v-row>
            <v-row v-if="false">
              <v-col>
                <p>{{ $t('courseview_instructedby') }}</p>
              </v-col>
              <v-col>
                <p>{{ $t('courseview_categorydance') }}</p>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <p class="ba-course-desc py-4">{{ $t('courseview_coursedescription') }}</p>
                <!-- temporarily hard coded -->
                <div v-if="course.title == 'ELA and Reading'" class="justify-space-around align-center ba-course-desc-content">
                  <div class="ma-4">
                    <div class="text-subtitle-2">Reading Class</div>
                    <v-img class="bg-white" min-width="300" src="https://resource.beyondactivity.com/reading_2023.jpg"></v-img>
                  </div>
                  <div class="ma-4">
                    <div class="text-subtitle-2">Writing Class</div>
                    <v-img class="bg-white" min-width="300" src="https://resource.beyondactivity.com/writing_2023.jpg"></v-img>
                  </div>
                </div>
                <!-- temporarily hard coded -->
                <div class="ba-course-desc-content pb-4" v-for="(desc, key) in course.description" :key="key">
                  <p v-if="this.isDescription(desc)"><span v-html="desc"></span></p>
                  <div v-if="this.isTitleInObject(desc)" class="courseDescriptionHighlight pl-5">
                    <ul>
                      <li><span v-html="this.getTitleInObject(desc)"></span></li>
                    </ul>
                  </div>
                  <p v-if="this.isDescriptionInObject(desc)"><span v-html="this.getDescriptionInObject(desc)"></span></p>
                  <div v-if="this.isListInObject(desc)" class="pl-8">
                    <ul>
                      <li v-for="(item, key) in this.getListInObject(desc)" :key="key"><span v-html="item"></span></li>
                    </ul>
                  </div>
                </div>
              </v-col>
            </v-row>
            <v-row v-if="displayOptions">
              <v-col>
                <v-radio-group v-model="selectedOption" column>
                  <v-radio v-for="(item, index) in this.course.options" :key="index" :value="index" :label="item.name" class="px2"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="px-0 pt-4 pb-8">
                <InterestForm :courseOptions="this.courseOptions"></InterestForm>
                <router-link v-if="course.acceptRegistration" class="clickToRegisterBtn" :to="getCheckoutUrl">{{ $t('courseview_clicktoregister') }}</router-link>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <p class="ba-session-title">{{ $t('courseview_sessions') }}</p>
              </v-col>
            </v-row>
            <v-row class="pb-6">
              <v-col>
                <v-tabs v-model="sessionType">
                  <v-tab value="upcoming">{{ $t('courseview_upcoming') }}</v-tab>
                  <v-tab value="completed">{{ $t('courseview_completed') }}</v-tab>
                </v-tabs>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <div v-for="(session, key) in displaySession" :key="key">
                  <p class="ba-session-date"><font-awesome-icon class="px-2" icon="fas fa-clock"/>{{ session.date }}
                      <span v-if="isUpcomingSession && session.price != null" class="ba-text ml-4">${{ session.price }}</span>
                      <router-link v-if="isUpcomingSession && session.price != null" class="clickToRegisterSessionBtn ml-2" :to="this.getCheckoutUrlBySession(session.id)">{{ $t('courseview_register') }}</router-link>
                  </p>
                  <p class="ba-session-time pl-8">From {{ session.start }} to {{ session.end }}</p>
                  <p class="ba-session-location pl-8">At {{ session.location }}</p>
                  <v-divider class="my-6 mx-6" v-if="key != displaySession.length-1"></v-divider>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
      <v-row class="pt-14">
        <v-col class="px-8">
          <v-container class="ba-card my-8" style="box-shadow: 0 2px 4px 3px rgb(223 223 223 / 50%);">
            <v-row>
              <v-col><v-img :src="course.image"></v-img></v-col>
            </v-row>
            <v-row justify="space-between">
              <v-col class="text-left" style="display: flex;"><font-awesome-icon class="px-2 ba-icon" icon="fas fa-users"/><span class="ba-text">{{ $t('courseview_attendees') }}</span></v-col>
              <v-col class="text-right"><span class="ba-text">{{ course.seats }}</span></v-col>
            </v-row>
            <v-row justify="space-between">
              <v-col class="text-left" style="display: flex;"><font-awesome-icon class="px-2 ba-icon" icon="fas fa-clock"/><span class="ba-text">{{ $t('courseview_duration') }}</span></v-col>
              <v-col class="text-right"><span class="ba-text">{{ course.duration }}</span></v-col>
            </v-row>
            <v-row justify="space-between">
              <v-col class="text-left" style="display: flex;"><font-awesome-icon class="px-2 ba-icon" icon="fas fa-child"/><span class="ba-text">{{ $t('courseview_age') }}</span></v-col>
              <v-col class="text-right"><span class="ba-text">{{ course.age }}</span></v-col>
            </v-row>
            <v-row justify="space-between">
              <v-col class="text-left" style="display: flex;"><font-awesome-icon class="px-2 ba-icon" icon="far fa-credit-card"/><span class="ba-text">{{ $t('courseview_price') }}</span></v-col>
              <v-col class="text-right"><span class="ba-text">{{ getCoursePrice }}</span></v-col>
            </v-row>
            <v-row justify="space-between">
              <v-col class="text-left" style="display: flex;"><font-awesome-icon class="px-2 ba-icon" icon="fas fa-language"/><span class="ba-text">{{ $t('courseview_languageofinstruction') }}</span></v-col>
              <v-col class="text-right"><span class="ba-text">{{ course.language }}</span></v-col>
            </v-row>
            <v-row justify="space-between">
              <v-col class="text-left" style="display: flex;"><font-awesome-icon class="px-2 ba-icon" icon="fab fa-connectdevelop"/><span class="ba-text">{{ $t('ourseview_languagelevel') }}</span></v-col>
              <v-col class="text-right"><span class="ba-text">{{ course.level }}</span></v-col>
            </v-row>
          </v-container>
          <v-container class="ba-card my-8" style="box-shadow: 0 2px 4px 3px rgb(223 223 223 / 50%);">
            <v-row>
              <v-col>
                <p class="ba-material">{{ $t('courseview_materials') }}</p>
                <p>{{ course.materials }}</p>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </div>
</CommonPageFramework>
</template>
    
<script>
import { defineComponent } from 'vue';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
      
// Components
import common from '@/assets/mixins/common.js';
import CommonPageFramework from '@/components/layout/CommonPageFramework.vue'
import InterestForm from '@/components/interest_form/InterestForm.vue'

export default defineComponent({
  name: 'CourseView',
  mixins: [common],
  components: {
    CommonPageFramework,
    InterestForm,
    FontAwesomeIcon,
  },
  computed: {
    courseName() {
      return this.$route.params.name;
    },
    course() {
      let allCourses = this.$store.getters.getAllCourses;
      let theCourse = allCourses.filter(x=>x.name == this.courseName);
      if (theCourse.length > 0)
        return theCourse[0];
      return null;
    },
    courseOptions() {
      return [this.course.options[this.selectedOption].name]
    },
    displaySession() {
      var today = new Date().toISOString().slice(0, 10);
      if (this.sessionType == 'upcoming') {
        return this.course.options[this.selectedOption].sessions.filter((x) => x.date >= today);
      } 

      return this.course.options[this.selectedOption].sessions.filter((x) => x.date < today);
    },
    getCheckoutUrl() {
      return '/checkout/' + this.courseName + "?option=" + this.selectedOption;
    },
    isUpcomingSession() {
      return this.sessionType == 'upcoming'
    },
    displayOptions() {
      if (this.course != null) {
        return this.course.options.length > 1;
      }
      return false;
    },
    getCoursePrice() {
      if (this.course == null) {
        return '';
      }

      let price = this.course.options[this.selectedOption].price;
      if (price == -1) {
        return 'Upcoming';
      }
      return '$' + price;
    }
  },
  methods: {
    isDescription(desc) {
      return typeof desc === "string"
    },
    isTitleInObject(desc) {
      return desc['title'] != null
    },
    getTitleInObject(desc) {
      return desc['title']
    },
    isDescriptionInObject(desc) {
      return desc['description'] != null
    },
    getDescriptionInObject(desc) {
      return desc['description']
    },
    isListInObject(desc) {
      return desc['list'] != null
    },
    getListInObject(desc) {
      return desc['list']
    },
    getCheckoutUrlBySession(id) {
      return '/checkout/' + this.courseName + '?session=' + id;
    }
  },
  data() {
    return {
      sessionType: 'upcoming',
      selectedOption: 0,
    }
  }
  });
</script>

<style lang="scss" scoped>
.ba-course-title {
  font-size: 32px;
  color: #56509f;
  letter-spacing: .27px;
  font-weight: 500;
  line-height: 1.2;
  font-family: 'Font Awesome 5 Free';
}

.ba-course-provider {
  font-size: 24px;
  color: #939292;
  letter-spacing: .2px;
  font-weight: 500;
  line-height: 1.2;
  font-family: 'Font Awesome 5 Free';
}

.ba-course-desc {
  font-size: 24px;
  color: #56509f;
  letter-spacing: .2px;
  font-weight: 500;
  line-height: 1.2;
  font-family: 'Font Awesome 5 Free';
}

.ba-course-desc-content {
  font-size: 1rem;
  color: #212529;
  letter-spacing: .2px;
  font-weight: 400;
  line-height: 28px;
  font-family: 'Font Awesome 5 Free';
}

.ba-card {
  background-clip: border-box;
  border: 1px solid rgba(0,0,0,.125);
  border-radius: 0.25rem;
}

.ba-icon {
  font-size: 25px;
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  color: #818384;
}

.ba-text {
  font-size: 20px;
  font-family: 'Font Awesome 5 Free';
  font-weight: 400;
  color: #212529;
  word-wrap: break-word;
  white-space: nowrap;
}

.ba-material {
  font-size: 18px;
  font-weight: 600;
  font-family: 'Font Awesome 5 Free';
}

.ba-material-text {
  font-size: 1rem;
  font-weight: 400;
  font-family: 'Font Awesome 5 Free';
}

.ba-session-title {
  font-size: 24px;
  color: #56509f;
  letter-spacing: .2px;
  font-weight: 500;
  line-height: 1.2;
  font-family: 'Font Awesome 5 Free';
}

.ba-session-date {
  color: #a3a3a3;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}

.ba-session-time {
  color: #343a40;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}

.ba-session-location {
  color: #6c757d;
  font-size: 1rem;
  font-weight: bolder;
  line-height: 1.5;
}

.clickToRegisterBtn {
  background-color: #460303;
  border-radius: 1rem;
  color: #ffffff;
  font-weight: 600;
  font-family: "Work Sans";
  font-size: 1rem;
  margin-left: 0.25rem;
  margin-right: 0.25rem;
  margin-bottom: 0.5rem;
  text-decoration: none;
  text-transform: none;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-bottom: 0.8rem;
  padding-top: 0.8rem;
  white-space: nowrap;
  word-break: keep-all;
}

.clickToRegisterSessionBtn {
  background-color: #460303;
  border-radius: 0.5rem;
  color: #ffffff;
  font-weight: 500;
  font-family: "Work Sans";
  font-size: 0.75rem;
  margin-left: 0.1rem;
  margin-right: 0.1rem;
  margin-bottom: 0.2rem;
  text-decoration: none;
  text-transform: none;
  padding-left: 0.6rem;
  padding-right: 0.6rem;
  padding-bottom: 0.35rem;
  padding-top: 0.35rem;
  white-space: nowrap;
  word-break: keep-all;
}

.courseDescriptionHighlight {
  font-size: 1rem;
  color: #212529;
  letter-spacing: .2px;
  font-weight: 800;
  line-height: 28px;
  font-family: 'Font Awesome 5 Free';
}
</style>
            