<template>
<v-dialog v-model="dialog" scrollable max-width="90%">
  <template v-slot:activator="{on, attrs}">
    <a class="openBtn" v-bind="attrs" v-on="on" @click="open">{{ $t('common_inquiry') }}</a>
  </template>
  <v-card>
    <v-card-title><span class="text-h5">{{ $t('interestform_inquiryform') }} <br><small>{{ $t('interestform_requiredfields') }}</small></span></v-card-title>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="12" sm="6" md="4" class="py-1">
            <v-text-field :label="$t('common_fullname_example')" v-model="userFullname" hide-details="auto" required></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4" class="py-1">
            <v-text-field :label="$t('common_email_example')" v-model="userEmail" hide-details="auto" required></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4" class="py-1">
            <v-text-field :label="$t('common_phone')" v-model="userPhone" hide-details="auto"></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4" class="py-1">
            <v-select v-model="isGreatParkNeighborhood" :label="$t('common_residency')" :items="['Yes', 'No']"></v-select>
          </v-col>
          <v-col cols="12" sm="6" md="4" class="py-1">
            <v-select class="py-0" v-if="moreClassOptions" v-model="whichClassAttend" :label="$t('common_whichclass')" :items="this.courseOptions"></v-select>
            <v-select class="py-0" v-else v-model="whichClassAttend" :label="$t('common_whichclass')" :items="this.courseOptions" disabled="true"></v-select>
          </v-col>
          <v-col cols="12" sm="6" md="4" class="py-1">
            <v-select v-model="gradeLevel" :label="$t('common_studentgradelevel')" :items="['Grade 1', 'Grade 2', 'Grade 3', 'Grade 4', 'Grade 5', 'Grade 6', 'Grade 7', 'Grade 8', ]"></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="py-1">
            <v-textarea :label="$t('common_bettertime')" auto-grow rows="3" :model-value="this.comment" v-model="this.comment"></v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="py-2 px-0"><b>{{ $t('interestform_contactus') }}</b></v-col>
        </v-row>
        <v-row>
          <v-col class="v-col-auto px-1 py-1">
            <p class="footer-title pb-2"><font-awesome-icon v-if="$vuetify.display.smAndUp" icon="fa-brands fa-weixin" class="px-1"/>WeChat</p>
            <img src="https://resource.beyondactivity.com/wechat_qrcode.png" width="80" height="80">
          </v-col>
          <v-col class="v-col-auto px-1 py-1">
            <p class="footer-title pb-2"><font-awesome-icon v-if="$vuetify.display.smAndUp" icon="fa-brands fa-whatsapp" class="px-1"/>WhatsApp</p>
            <img src="https://resource.beyondactivity.com/whatsapp_qrcode.jpg" width="80" height="80">
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions class="justify-end">
      <v-btn class="submitBtn" :disabled='isDisabled' @click="submit">{{ $t('common_submit') }}</v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>
<v-snackbar v-model="messageBar" :timeout="2000" color="success" variant="outlined">
  {{ $t('interestform_inquerysubmitted') }}
</v-snackbar>
</template>
    
<script>
import common from '@/assets/mixins/common.js';

import Service from '@/service.js'

export default {
  name: 'ba-interest-form',
  mixins: [common],
  props: ['courseOptions'],
  data: () => ({
    dialog: false,
    userFullname: '',
    userEmail: '',
    userPhone: '',
    isGreatParkNeighborhood: null,
    whichClassAttend: null,
    gradeLevel: null,
    comment: '',
    messageBar: false
  }),
  watch: {
    courseOptions: function (newValue) {
      if (newValue.length == 1) {
        this.whichClassAttend = newValue[0]
      }
    } 
  },
  computed: {
    isDisabled() {
      return this.userEmail === '' || this.userFullname === ''
        || this.isGreatParkNeighborhood == null || this.whichClassAttend == null
    },
    moreClassOptions() {
      return this.courseOptions.length > 1
    }
  },
  methods: {
    open() {
      this.dialog = true;
    },
    submit() {
      this.dialog = false;
      new Service().submitInterestForm(
        this.userFullname,
        this.userEmail,
        this.userPhone,
        this.isGreatParkNeighborhood,
        this.whichClassAttend,
        this.gradeLevel,
        this.comment,
        (response)=>{  // eslint-disable-line no-unused-vars
          this.messageBar = true;
        }
      );
    }
  },
  mounted () {
    if (this.courseOptions.length == 1) {
      this.whichClassAttend = this.courseOptions[0]
    }
  }
}
</script>
    
<style lang="scss" scoped>
.openBtn {
  background-color: #460303;
  border-radius: 1rem;
  color: #ffffff;
  font-weight: 600;
  font-family: "Work Sans";
  font-size: 1rem;
  margin-left: 0.25rem;
  margin-right: 0.25rem;
  margin-bottom: 0.5rem;
  text-decoration: none;
  text-transform: none;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-bottom: 0.8rem;
  padding-top: 0.8rem;
  cursor: pointer;
  white-space: nowrap;
  word-break: keep-all;
}

.submitBtn {
  background-color: #460303;
  border-radius: 1rem;
  color: #ffffff;
  font-weight: 600;
  font-family: "Work Sans";
  font-size: 1rem;
  margin-left: 0.25rem;
  margin-right: 0.25rem;
  text-decoration: none;
  text-transform: none;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
</style>
            