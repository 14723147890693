<template>
  <CommonPageFramework>
    <div style="background-color: #f7f2f2;font-family: 'Font Awesome 5 Free';">
      <v-container><v-row><v-col>
      <h1 class="ba-title">Privacy Policy<br><span class="ba-updated">Updated on April 16, 2023</span></h1>

      <p class="ba-paragraph">
        Welcome to the online service provided by Beyond Activity ("Beyond Activity", "we", "our", or "us"). 
        We are committed to protecting your privacy and to treating your personal information as you would 
        like it to be treated. This Privacy Policy explains how we collect, use, share, and protect your personal 
        information. It applies to all Beyond Activity websites, applications, services, and tools (collectively, 
        our “Service”). This Privacy Policy applies only to our own data practices, but not to the practices of third parties. 
        By using our Service, you agree to the terms of this Privacy Policy and our Terms of Use.</p>
        
      <ul class="ba-paragraph" style="list-style: none; text-align: left;">
        <li class="py-3">1. <b style="font-weight: 600;">Consent of Collection of Information</b>. 
          We collect personal information about our customers for providing better Service. We do our best to be as transparent 
          as possible about our data practices and to provide you choices about how your data is used when possible. The categories 
          of information we collect may include Registration and profile information collected when you join the Service. We may also 
          collect personal information such as a name, an email address, address, and age when you register for a Beyond Activity class, 
          participate in a Beyond Activity promotion or contest, etc. We may collect billing information if you make a payment via a Service Provider.
        </li>
        <li class="py-3">2. <b style="font-weight: 600;">How personal information is stored</b>?
          Personal information collected through the Service is stored and processed in the United States. Beyond Activity cares about the security of 
          personal information, and uses commercially reasonable and administrative technology to preserve the integrity and security of all information 
          collected through the Service. However, in the event that any information under our control is compromised as a result of a breach of security, 
          Beyond Activity will take reasonable steps to investigate the situation and notify those individuals whose information may have been compromised 
          and take other steps, in accordance with any applicable laws and regulations.
        </li>
        <li class="py-3">3. <b style="font-weight: 600;">Children's Privacy</b>.
          Beyond Activity does not knowingly collect or solicit any information from anyone under the age of 13 without the consent of your parent or guardian, 
          or as otherwise provided for by applicable law. No one under age 13 may provide any Personal Information to Beyond Activity. If you are under 13, 
          please do not provide any information through this website, or provide any information to us.</li>
        <li class="py-3">4. <b style="font-weight: 600;">Links to Other Websites</b>.
          Our Service may integrate with or contains links to other third party sites and services. We are not responsible for the practices employed by 
          third party websites or services embedded in, linked to, or linked from the Service and your interactions with any third-party websites or services
          are subject to their rules and policies. In addition, you agree that we are not responsible for and we do not have any control over any third-parties
          that you authorize to access your User Content, including the Service Provider you interact with our Service.
        </li>
        <li class="py-3">5. <b style="font-weight: 600;">Revisions to this Privacy Policy</b>. 
          Beyond Activity reserves the right to change, modify, add, or remove portions of this Privacy Policy at any time, without prior notice to you. 
          All revisions will be posted on this website and will apply to any Personal Information collected on or after the date posted. 
          Beyond Activity will obtain necessary consent required under applicable privacy laws if they seek to collect, use, or disclose your Personal 
          Information for purposes other than those to which consent has been obtained, unless otherwise required or permitted by law.
        </li>
        <li class="py-3">6. This Privacy Policy and the use of this website are governed by the laws of the United States and the State of California. 
          If a dispute arises, we agree to first resolve it through arbitration in the State of California.
        </li>
        <li class="py-3">7. By choosing to provide Beyond Activity with your Personal Information, you are consenting to its collection, use, and 
          disclosure in accordance with the principles outlined in this Privacy Policy.
        </li>
      </ul>
      </v-col></v-row></v-container>
    </div>
  </CommonPageFramework>
</template>
        
<script>
import { defineComponent } from 'vue';
          
// Components
import CommonPageFramework from '@/components/layout/CommonPageFramework.vue'
    
export default defineComponent({
  name: 'PrivacyPolicyView',

  components: {
    CommonPageFramework,
  },
});
</script>

<style lang="scss" scoped>
.ba-title {
  color: #2b0101;
  font-weight: 600;
  font-family: "Work Sans";
  padding-top: 0rem;
  padding-bottom: 0rem;
  letter-spacing: 0rem;
  line-height: 1;
  
  font-size: 2.25rem;
  white-space: pre-line;
  margin-bottom: 0rem;
  margin-top: 0rem;
  display: block;
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.ba-paragraph {
  color: #2b0101;
  font-weight: 300;
  font-family: "Work Sans";
  padding-top: 0rem;
  padding-bottom: 0.25rem;
  letter-spacing: 0rem;
  line-height: 1;
  
  font-size: 1.25rem;
  white-space: pre-line;
  margin-bottom: 0rem;
  margin-top: 0rem;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.ba-updated {
  color: #2b0101;
  font-weight: 300;
  font-family: "Work Sans";
  padding-top: 0rem;
  padding-bottom: 0.25rem;
  letter-spacing: 0rem;
  line-height: 1;
  
  font-size: 1.25rem;
  white-space: pre-line;
  margin-bottom: 0rem;
  margin-top: 0rem;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.ba-note {
  color: #2b0101;
  font-weight: 200;
  font-family: "Work Sans";
  padding-top: 0rem;
  padding-bottom: 0.25rem;
  letter-spacing: 0rem;
  line-height: 1;
  
  font-size: 1rem;
  white-space: pre-line;
  margin-bottom: 0rem;
  margin-top: 0rem;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.link {
  font-family: sans-serif;
  letter-spacing: 0rem;
  font-weight: 600;
  font-size: 1rem;
  color: #000;
  text-decoration: none;
  background-color: transparent;
  cursor: point;
  list-style: none;
  white-space: nowrap;
}

@media only screen and (max-width: 960px) {
  .ba-title {
    color: #2b0101;
    font-weight: 600;
    font-family: "Work Sans";
    padding-top: 0rem;
    padding-bottom: 0rem;
    letter-spacing: 0rem;
    line-height: 1.25;
    
    font-size: 1.5rem;
    white-space: pre-line;
    margin-bottom: 0rem;
    margin-top: 0rem;
    display: block;
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }
  
  .ba-paragraph {
    color: #2b0101;
    font-weight: 300;
    font-family: "Work Sans";
    padding-top: 0rem;
    padding-bottom: 0.25rem;
    letter-spacing: 0rem;
    line-height: 1;
    
    font-size: 0.75rem;
    white-space: pre-line;
    margin-bottom: 0rem;
    margin-top: 0rem;
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }
  
  .ba-updated {
    color: #2b0101;
    font-weight: 300;
    font-family: "Work Sans";
    padding-top: 0rem;
    padding-bottom: 0rem;
    letter-spacing: 0rem;
    line-height: 1;
    
    font-size: 0.75rem;
    white-space: pre-line;
    margin-bottom: 0rem;
    margin-top: 0rem;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }
  
  .ba-note {
    color: #2b0101;
    font-weight: 200;
    font-family: "Work Sans";
    padding-top: 0rem;
    padding-bottom: 0.25rem;
    letter-spacing: 0rem;
    line-height: 1;
    
    font-size: 0.5rem;
    white-space: pre-line;
    margin-bottom: 0rem;
    margin-top: 0rem;
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }
  
  .link {
    font-family: sans-serif;
    letter-spacing: 0rem;
    font-weight: 600;
    font-size: 0.75rem;
    color: #000;
    text-decoration: none;
    background-color: transparent;
    cursor: point;
    list-style: none;
    white-space: nowrap;
  }
}
</style>
        