export default {
    computed: {
        mainBodyHeight () {
            return (window.innerHeight - 350) + 'px'
        },
        isLogined() {
            return Object.keys(this.$store.getters.getUser).length > 0;
        },
        getUserName() {
            return this.$store.getters.getUser.name;
        },
        getUserEmail() {
            return this.$store.getters.getUser.email;
        },
        isAdmin() {
            if (!this.isLogined) return false;
            return this.$store.getters.getUserRoles.includes('admin');
        },
        isUser() {
            if (!this.isLogined) return false;
            return this.$store.getters.getUserRoles.includes('user');
        },
        getToken() {
            return this.$store.getters.getToken;
        },
        displayErrorMessage() {
            return this.$store.getters.getErrorMessage != null;
        },
        getErrorMessage() {
            return this.$store.getters.getErrorMessage;
        },
        isInEnglish() {
            return this.$i18n.locale === 'en'
        },
    },
    methods: {
        validateFullname (fullname) {
            return fullname != null && fullname.length >= 2 && fullname.length <= 40;
        },
        validateEmail (email) {
            return email != null && /.+@.+\..+/.test(email);
        },
        validatePhone (phone) {
            return phone != null && (
              /[0-9]{10}/.test(phone) ||
              /\([0-9]{3}\)[0-9]{3}-?[0-9]{4}/.test(phone) ||
              /\([0-9]{3}\) [0-9]{3}-?[0-9]{4}/.test(phone)
            );
        },
        validatePassword (password) {
            return password != null && password.length >= 8;
        },
        getFullnameErrorMessage(fullname) {
            if (fullname == null) {
                return [];
            }
            if (fullname.length == 0) {
                return ['Full name is required.'];
            }
            if (fullname.length < 2) {
                return ['Full name is too short.'];
            }
            if (fullname.length > 40) {
                return ['Full name is too long.']
            }
            return [];
        },
        getEmailErrorMessage(email) {
            if (email == null) {
                return [];
            }
            if (this.validateEmail(email)) {
                return [];
            }
            return ['Invalid e-mail.'];
        },
        getPhoneErrorMessage(phone) {
            if (phone == null || phone === '') {
                return [];
            }
            if (this.validatePhone(phone)) {
                return [];
            }
            return ['Invalid Phone.'];
        },
        clearErrorMessage() {
            this.$store.commit('clearError');
        },
        epochToLocalTimeStr(seconds) {
            return new Date(seconds * 1000).toDateString()
        },
        rolesToStr(roles) {
            return roles.sort().join(',')
        },
        uppercase(v) {
            return v.toUpperCase();
        },
        amountToStr(amount) {
            return '' + (amount/100).toFixed(2);
        }
    }
}