<template>
<CommonPageFramework>
  <v-container fill-height>
    <v-row class="py-0" justify="space-around">
      <v-col class="v-col-10">
        <span class="help-text text-center">Please contact us to reset your password. Thank you.</span>
      </v-col>
    </v-row>
    <v-row class="text-center" justify="space-around">
      <v-col class="v-col-4">
      <v-container class="px-0">
        <v-row class="px-0 text-center py-2" justify="space-around">
          <v-col class="v-col-auto px-1">
            <p class="footer-title pb-2"><font-awesome-icon v-if="$vuetify.display.smAndUp" icon="fa-brands fa-weixin" class="px-1"/>WeChat</p>
            <img src="https://resource.beyondactivity.com/wechat_qrcode.png" width="80" height="80">
          </v-col>
          <v-col class="v-col-auto px-1">
            <p class="footer-title pb-2"><font-awesome-icon v-if="$vuetify.display.smAndUp" icon="fa-brands fa-whatsapp" class="px-1"/>WhatsApp</p>
            <img src="https://resource.beyondactivity.com/whatsapp_qrcode.jpg" width="80" height="80">
          </v-col>
        </v-row>
      </v-container>
    </v-col>
    </v-row>
  </v-container>
</CommonPageFramework>
</template>

<script>
import { defineComponent } from 'vue';
  
// Components
import CommonPageFramework from '@/components/layout/CommonPageFramework.vue'
  
export default defineComponent({
  name: 'SignInView',
  components: {
    CommonPageFramework,
  },
  computed: {
  },
  data() {
    return {
    }
  },
});
</script>
      
<style lang="scss" scoped>
.help-text {
  color: #2b0101;
  font-weight: 500;
  font-family: sans-serif;
  display: block;
  padding-bottom: 0rem;
  padding-top: 1rem;
  font-size: 1.5rem;
  white-space: pre-line;
  line-height: 1.5;
  text-align: left;
}
</style>
            