import { createI18n } from 'vue-i18n'

import { messages as enMessages } from '@/i18n/en.js'
import { messages as zhMessages } from '@/i18n/zh.js'
import { messages as koMessages } from '@/i18n/ko.js'

export const i18n = createI18n({
    locale: 'en', // set locale
    fallbackLocale: 'en', // set fallback locale
    messages: {
      'en': enMessages,
      'ko': koMessages,
      'zh': zhMessages,
    }
})