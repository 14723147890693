import { createStore } from 'vuex'

import course from './course.js'
import user from './user.js'
import error from './error.js'
import i18n from './i18n.js'

export default createStore({
  modules: {
    course: course,
    user: user,
    error: error,
    i18n: i18n,
  }
})
