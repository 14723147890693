<template>
  <CommonPageFramework>
    <div style="background-color: #f7f2f2;font-family: 'Font Awesome 5 Free';">
      <v-container><v-row><v-col>
      <h1 class="ba-title">Beyond Activity - Service Provider Agreement<br><span class="ba-updated">Updated on April 16, 2023</span></h1>

      <p class="ba-paragraph">Welcome to Beyond Activity ("Beyond Activity," "we," or "us"). 
        This page explains the terms by which you may use our services (collectively the "Service").
      </p>
      <p class="ba-paragraph">By accessing or using the Service, you signify that you have read, 
        understood, and agree to be bound by this Service Provider Agreement ("Agreement"), to the collection 
        and use of your information as set forth in the Beyond Activity <a href="/privacypolicy" class="link" target="_blank">Privacy Policy</a>, 
        and the <a href="/termsofservice" class="link" target="_blank">Terms of Service</a>, whether or not 
        you are a registered user of our Service. Beyond Activity reserves the right to make unilateral modifications 
        to these terms and will provide notice of these changes as described below.
      </p>
      <p class="ba-paragraph">This Agreement applies to all visitors, users, and others who access the Service 
        in order to list programs, such as classes, camps, teams, leagues, and other groups and events ("Programs"), 
        on the Service and to enroll children in such Programs via the Service (each a "Service Provider").
      </p>
      <p class="ba-head">Listing Your Programs on the Service</p>
      <p class="ba-paragraph">
        In order to post a listing for your Program on the Service, you must submit a truthful, complete, and accurate 
        listing setting forth all material information necessary to enroll in your Program. We may evaluate your listing 
        in good faith and will, at our sole discretion, accept or reject your request and notify you accordingly.
      </p>
      <p class="ba-paragraph">
        Programs that are likely to be rejected include but are not limited to those that: (i) promote products or services 
        unrelated to activities; (ii) promote illegal, harmful, or offensive activities; or (iii) fail to conform to any 
        formatting or other technical specifications provided to you by us.
      </p>
      <p class="ba-paragraph">
        You acknowledge and agree that you are entirely responsible for, and we assume no liability for, the truthfulness, 
        accuracy, and completeness of your User Content (as defined in the <a href="/termsofservice" class="link" target="_blank">Terms of Service</a>) 
        you make available on the Service. You may correct any untruthful, inaccurate, or incomplete content in your User Content 
        through the functionality of the Service. We reserve the right (but not the obligation) to correct or edit any User Content 
        you post in relation to your Program if we believe it to be untruthful, inaccurate, or incomplete.
      </p>
      <p class="ba-paragraph">
        You acknowledge and agree that you have no control over any User Content posted about you or your Programs, 
        such as reviews posted by Users. We monitor all reviews prior to their posting on the Service in order to 
        assess their relevance and appropriateness. If you would like to report an irrelevant or inappropriate review 
        about you or your Program, please notify us at BeyondActivityBA@gmail.com.
      </p>
      <p class="ba-paragraph">
        You must have in place an appropriate document setting forth your policies and procedures regarding cancellations 
        and refunds, and you must make these policies and procedures available to Users prior to their enrollment in your Program.
      </p>
      <p class="ba-head">Beyond Activity Service</p>
      <p class="ba-paragraph">
        Subject to the terms and conditions of this Agreement, you are hereby granted a non-exclusive, limited, non-transferable, 
        freely revocable license to use the Service as permitted by the features of the Service. Beyond Activity reserves all rights 
        not expressly granted herein in the Service and the Beyond Activity Content (as defined in the <a href="/termsofservice" 
        class="link" target="_blank">Terms of Service</a>). Beyond Activity may terminate this license at any time for any reason or no reason.
      </p>
      <p class="ba-head">Paid Services</p>
      <p class="ba-paragraph">
        Billing Policies. Certain aspects of the Service may be provided for a fee or other charge. If you elect to use paid aspects 
        of the Service, you agree to our pricing and payment terms, as we may update them from time to time. We may add new services 
        for additional fees or charges, add or amend fees and charges for existing services, at any time, and in our sole discretion.
      </p>
      <p class="ba-paragraph">
        We will bill you for your use of paid aspects of the Service through your Beyond Activity account.
        Beyond Activity will be offering payments through a payment processor.
      </p>
      <p class="ba-paragraph">
        No Refunds. You may cancel your Beyond Activity account at any time. However, there are no refunds for cancellation. 
        In the event that we suspend or terminate your account or this Agreement for your breach hereof, you understand and 
        agree that you will receive no refund or exchange for any fees already paid for any portion of the Service, any content 
        or data associated with your account, or for anything else. You will be responsible for any fees outstanding and agree 
        to pay the balance.
      </p>
      <p class="ba-paragraph">
        Payment Information; Taxes. All information that you provide in connection with a purchase or transaction or other monetary 
        transaction interaction with the Service must be accurate, complete, and current. You agree to pay all charges incurred by 
        users of your credit card, debit card, or other payment method used in connection with a purchase or transaction or other 
        monetary transaction interaction with the Service at the prices in effect when such charges are incurred. You will pay any 
        applicable taxes, if any, relating to any such purchases, transactions or other monetary transaction interactions. If we do not 
        receive payment from you or your payment provider, you will pay all amounts due on your Beyond Activity account on demand.
      </p>
      <p class="ba-paragraph">
        California Residents. The provider of services is set forth herein. If you are a California resident, in accordance with Cal. 
        Civ. Code §1789.3, you may report complaints to the Complaint Assistance Unit of the Division of Consumer Services of the 
        California Department of Consumer Affairs by contacting them in writing at 1625 North Market Blvd., Suite N 112 Sacramento, 
        CA 95834, or by telephone at (800) 952-5210 or (916) 445-1254.
      </p>
      <p class="ba-head">User Disputes</p>
      <p class="ba-paragraph">
        You acknowledge and agree that Beyond Activity provides a platform and venue for Users to research and enroll in Programs, 
        and for you to list your Programs. Beyond Activity does not provide any services except the Services. We have no control 
        over and cannot guarantee the truthfulness, accuracy, or completeness of any User Content posted on the Service, and cannot 
        control or guarantee that any User will actually complete a transaction with you. If you have a dispute with one or more Users, 
        you hereby release Beyond Activity from any claims, demands, fees, expenses, or damages of any kind or nature, known or unknown, 
        arising out of or in any way connected with such disputes. If you are a California resident, you hereby waive California Civil Code 
        §1542, which provides that:
      </p>
      <p class="ba-paragraph">
        "A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR DOES NOT KNOW OR SUSPECT TO EXIST IN HIS OR HER FAVOR AT THE TIME OF 
        EXECUTING THE RELEASE, WHICH IF KNOWN BY HIM OR HER MUST HAVE MATERIALLY AFFECTED HIS OR HER SETTLEMENT WITH THE DEBTOR."
      </p>
      </v-col></v-row></v-container>
    </div>
  </CommonPageFramework>
</template>
          
<script>
import { defineComponent } from 'vue';
          
// Components
import CommonPageFramework from '@/components/layout/CommonPageFramework.vue'
    
export default defineComponent({
  name: 'PrivacyPolicyView',

  components: {
    CommonPageFramework,
  },
});
</script>
  
<style lang="scss" scoped>
  .ba-title {
    color: #2b0101;
    font-weight: 600;
    font-family: "Work Sans";
    padding-top: 0rem;
    padding-bottom: 0rem;
    letter-spacing: 0rem;
    line-height: 1.25;
    
    font-size: 2.25rem;
    white-space: pre-line;
    margin-bottom: 0rem;
    margin-top: 0rem;
    display: block;
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }

  .ba-head {
    color: #2b0101;
    font-weight: 600;
    font-family: "Work Sans";
    padding-top: 0rem;
    padding-bottom: 0.25rem;
    letter-spacing: 0rem;
    line-height: 1;
    
    font-size: 1.5rem;
    white-space: pre-line;
    margin-bottom: 0rem;
    margin-top: 0rem;
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }
  
  .ba-paragraph {
    color: #2b0101;
    font-weight: 300;
    font-family: "Work Sans";
    padding-top: 0rem;
    padding-bottom: 0.25rem;
    letter-spacing: 0rem;
    line-height: 1;
    
    font-size: 1.25rem;
    white-space: pre-line;
    margin-bottom: 0rem;
    margin-top: 0rem;
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }
  
  .ba-updated {
    color: #2b0101;
    font-weight: 300;
    font-family: "Work Sans";
    padding-top: 0rem;
    padding-bottom: 0rem;
    letter-spacing: 0rem;
    line-height: 1;
    
    font-size: 1.25rem;
    white-space: pre-line;
    margin-bottom: 0rem;
    margin-top: 0rem;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }
  
  .link {
    font-family: sans-serif;
    letter-spacing: 0rem;
    font-weight: 600;
    font-size: 1rem;
    color: #000;
    text-decoration: none;
    background-color: transparent;
    cursor: point;
    list-style: none;
    white-space: nowrap;
  }

@media only screen and (max-width: 960px) {
  .ba-title {
    color: #2b0101;
    font-weight: 600;
    font-family: "Work Sans";
    padding-top: 0rem;
    padding-bottom: 0rem;
    letter-spacing: 0rem;
    line-height: 1.25;
    
    font-size: 1.5rem;
    white-space: pre-line;
    margin-bottom: 0rem;
    margin-top: 0rem;
    display: block;
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }

  .ba-head {
    color: #2b0101;
    font-weight: 600;
    font-family: "Work Sans";
    padding-top: 0rem;
    padding-bottom: 0.25rem;
    letter-spacing: 0rem;
    line-height: 1;
    
    font-size: 0.75rem;
    white-space: pre-line;
    margin-bottom: 0rem;
    margin-top: 0rem;
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }
  
  .ba-paragraph {
    color: #2b0101;
    font-weight: 300;
    font-family: "Work Sans";
    padding-top: 0rem;
    padding-bottom: 0.25rem;
    letter-spacing: 0rem;
    line-height: 1;
    
    font-size: 0.75rem;
    white-space: pre-line;
    margin-bottom: 0rem;
    margin-top: 0rem;
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }
  
  .ba-updated {
    color: #2b0101;
    font-weight: 300;
    font-family: "Work Sans";
    padding-top: 0rem;
    padding-bottom: 0rem;
    letter-spacing: 0rem;
    line-height: 1;
    
    font-size: 0.75rem;
    white-space: pre-line;
    margin-bottom: 0rem;
    margin-top: 0rem;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }
  
  .link {
    font-family: sans-serif;
    letter-spacing: 0rem;
    font-weight: 600;
    font-size: 0.75rem;
    color: #000;
    text-decoration: none;
    background-color: transparent;
    cursor: point;
    list-style: none;
    white-space: nowrap;
  }
}
</style>
          