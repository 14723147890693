const state = () => ({
    token: null,
    user: {},
  })
        
  const mutations = {
    setToken: (state, token) => {
      state.token = token;
    },
    setUser: (state, user) => {
      state.user = user;
    },
    clearUser: (state) => { // eslint-disable-line no-unused-vars
      state.token = null;
      state.user = {};
    }
  }
  
  const getters = {
    getToken: state => {
      return state.token
    },
    getUser: state => {
      return state.user
    },
    getUserRoles: state => {
      return state.user.roles
    }
  }
  
  const actions = {
  }
  
  export default {
    namespaced: false,
    state,
    mutations,
    getters,
    actions,
  };