export const messages = {
    // Common
    common_fullname: '성명',
    common_fullname_example: '성명(예: John Smith) *',
    common_email: '이메일',
    common_email_example: '이메일 *',
    common_password: '비밀번호 *',
    common_repeatpassword: '비밀번호를 반복하세요 *',
    common_forgotpassword: '비밀번호를 잊으 셨나요',
    common_yourmessage: '귀하의 메시지',
    common_youremail: '귀하의 이메일',
    common_home: '홈페이지',
    common_phone: '핸드폰',
    common_residency: '그레이트파크 인근에 거주하시나요? *',
    common_whichclass: '어떤 수업에 참여할 예정인가요? *',
    common_studentgradelevel: '학생 학년 수준',
    common_bettertime: '더 좋은 시간을 제안하시겠습니까? 코멘트?',
    common_submit: '제출하다',
    common_close: '닫다',
    common_company: '회사',
    common_courses: '과정',
    common_contactus: '문의하기',
    common_aboutus: '회사 소개',
    common_privacypolicy: '개인 정보 정책',
    common_termsofservice: '서비스 약관',
    common_serviceprovideraggrement: '서비스 제공업체 계약',
    common_and: '그리고',
    common_inquiry: '문의',
    // HomeView
    homeview_title: 'Beyond Activity',
    homeview_subtitle_1: '매달 새로 추가되는 신뢰할 수 있는 현지 제공업체가 제공하는 활동을 통해 삶을 풍요롭게 하세요.',
    homeview_subtitle_2: '매달 새로 추가되는 신뢰할 수 있는 ',
    homeview_subtitle_3: '현지 제공업체가 제공하는 ',
    homeview_subtitle_4: '활동을 통해 삶을 풍요롭게 하세요.',
    homeview_seeAllCourses: '모든 강좌 보기',
    homeview_media_subtitle_1: '자신만의 속도로 가세요',
    homeview_media_subtitle_2: '시간과 활동을 선택하시면 저희가 준비해 드립니다!',
    homeview_media_subtitle_3: '업계 전문가로부터 배우세요',
    homeview_media_subtitle_4: '최고의 강사 중에서 선택하고 최고의 강사로부터 배우세요.',
    homeview_media_subtitle_5: '대면 수업 및 가상 수업',
    homeview_media_subtitle_6: '가상 또는 직접 방문 중 선택',
    homeview_category_subtitle_1: '상위 카테고리',
    homeview_category_subtitle_2: '춤',
    homeview_category_subtitle_3: '코딩',
    homeview_category_subtitle_4: '언어',
    homeview_category_subtitle_5: '설계',
    // AllCourseView
    allcourseview_subtitle_1: '다음에 배울 내용',
    allcourseview_allcategories: '모든 카테고리',
    // CourseView
    courseview_sorry: "죄송합니다. 강좌를 찾을 수 없습니다.",
    courseview_instructedby: 'Beyond Activity에서 지도함',
    courseview_categorydance: '카테고리 댄스',
    courseview_coursedescription: '코스 설명',
    courseview_clicktoregister: '등록하다',
    courseview_sessions: '세션',
    courseview_upcoming: '예정',
    courseview_completed: '완전한',
    courseview_register: '등록하다',
    courseview_attendees: '참석자',
    courseview_duration: '지속',
    courseview_price: '가격',
    courseview_age: '나이',
    courseview_languageofinstruction: '수업 언어',
    courseview_languagelevel: '언어 수준',
    courseview_materials: '재료',
    //GuestCheckout
    guestcheckout_checkout: '지불하다',
    guestcheckout_providedby: '에 의해 제공',
    guestcheckout_contactinfo: '연락처 정보',
    guestcheckout_submitcontactinfo: '연락처 정보 제출',
    guestcheckout_paymentmethod: '결제 방법',
    guestcheckout_subtitle: '소계',
    guestcheckout_paymentservicefee: '결제서비스 수수료',
    guestcheckout_total: '총',
    guestcheckout_paynow: '지불하다',
    guestcheckout_parentname: '상위 이름(예: John Smith) *',
    guestcheckout_kidname: '아이 이름(예: David Smith) *',
    guestcheckout_kidage: '어린이 연령 *',
    guestcheckout_anyquestion: '질문있으세요?',
    // InterestForm
    interestform_contactus: '또는 다음 주소로 문의하세요.',
    interestform_inquerysubmitted: '문의가 성공적으로 제출되었습니다.',
    interestform_inquiryform: '문의 양식',
    interestform_requiredfields: '*필수 필드를 나타냅니다',
    // AboutView
    aboutview_title: '우리 회사 소개',
    aboutview_abouttext: `우리가 매일 하는 일이 크든 작든 우리의 삶을 형성합니다. 이러한 활동 중 일부는 너무 습관적이어서 우리가 그것에 대해 생각조차 하지 않습니다. 다른 것들은 너무나 중요해서 우리 삶의 방향을 영원히 바꿔 놓습니다. 그러나 그들 각각은 우리가 누구인지, 우리 삶이 어떻게 전개되는지에 기여합니다.

    비욘드액티비티는 사람들이 삶을 풍요롭게 하고, 건강하고 행복한 삶을 누릴 수 있도록 노력하고 있습니다. 우리의 사명은 학습이든, 취미이든, 같은 생각을 가진 사람들과의 연결이든 관계없이 고객이 좋아하는 활동을 찾고, 배우고, 참여할 수 있도록 하는 것입니다. 우리는 뛰어난 고객 서비스와 사용하기 쉬운 도구를 바탕으로 고품질의 신뢰할 수 있는 활동 정보를 제공함으로써 이를 수행합니다.`,
    aboutview_ourvision: '우리의 비젼',
    aboutview_visiontext: '사람들이 삶을 풍요롭게 하고 건강하고 행복한 삶을 누릴 수 있도록 돕습니다.',
    aboutview_ourmission: '우리의 미션',
    aboutview_missiontext: '고품질의 신뢰할 수 있는 활동 정보와 서비스 및 도구를 제공함으로써 고객은 번거로움 없이 학습을 즐기고 취미를 추구하며 같은 생각을 가진 사람들과 연결하는 데 집중할 수 있습니다.',
    // ContactUsView
    contactusview_keepintouch: '계속 연락하세요',
    contactusview_location: '위치:',
    contactusview_email: '이메일:',
    contactusview_phone: '핸드폰:',
    contactusview_openhour: '월요일 – 금요일 9 am - 6 pm',
    contactusview_subscribe: '구독하다',
    contactusview_sendusamessage: '메시지 보내기',
    contactusview_submitinquery: '문의 제출',
    // ThankyouView
    thankyouview_thankyou: '감사합니다!',
    thankyouview_orderplaced: '주문이 완료되었습니다.',
    // SignInView
    signinview_signin: '로그인',
    signinview_signup: '가입하기',
    // SignUp
    signup_stmt1: '가입함으로써 귀하는 당사에 동의하게 됩니다.',
    signup_passwordtooshort: '비밀번호가 너무 짧습니다. 최소 8자 이상입니다.',
    signup_passwordnotmatch: "비밀번호가 일치하지 않습니다.",
    // Header
    header_signout: '로그아웃',
    header_allcourses: '모든 강좌',
    header_categories: '카테고리',
    // Payment
    payment_creditcard: '신용 카드',
    payment_stmt1: '신용카드 수수료 3%가 있습니다. 당신은 선택할 수 있습니다 Zelle',
    payment_stmt2: '결제 서비스 수수료를 피하기 위해.',
    unused: ''
};
