import {API_SERVICE_BASE} from '@/const'

import sha256 from 'crypto-js/sha256';

export default {
    get V1_SERVICE_PREFIX() {
        return API_SERVICE_BASE + '/v1'
    },

    trim(s) { // remove leading or trailing spaces
        return (s || '').replace(/^\s+|\s+$/g, '')
    },

    password(s) { // remove leading or trailing spaces
        return sha256(this.trim(s)).toString();
    }
}
