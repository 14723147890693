export const messages = {
    // Common
    common_fullname: 'Full Name',
    common_fullname_example: 'Full Name (e.g., John Smith) *',
    common_email: 'Email',
    common_email_example: 'Email *',
    common_password: 'Password *',
    common_repeatpassword: 'Repeat Password *',
    common_forgotpassword: 'Forgot Password',
    common_yourmessage: 'Your Message',
    common_youremail: 'Your email',
    common_home: 'Home',
    common_phone: 'Phone',
    common_residency: 'Are you a resident in Great Park neighborhood? *',
    common_whichclass: 'Which class will you attend? *',
    common_studentgradelevel: 'Student grade level',
    common_bettertime: 'Suggest a better time? Comments?',
    common_submit: 'Submit',
    common_close: 'Close',
    common_company: 'Company',
    common_courses: 'Courses',
    common_contactus: 'Contact Us',
    common_aboutus: 'About Us',
    common_admin_portal: 'Admin Portal',
    common_privacypolicy: 'Privacy Policy',
    common_termsofservice: 'Terms of Service',
    common_serviceprovideraggrement: 'Service Provider Agreement',
    common_and: 'and',
    common_inquiry: 'Inquiry',
    // HomeView
    homeview_title: 'Beyond Activity',
    homeview_subtitle_1: 'Enrich your life through activities provided by trusted local providers with new additions every month.',
    homeview_subtitle_2: 'Enrich your life through activities',
    homeview_subtitle_3: 'provided by trusted local providers',
    homeview_subtitle_4: 'with new additions every month.',
    homeview_seeAllCourses: 'See all courses',
    homeview_media_subtitle_1: 'Go at your own pace',
    homeview_media_subtitle_2: 'You select the time and the activity, we will make it happen!',
    homeview_media_subtitle_3: 'Learn from industry experts',
    homeview_media_subtitle_4: 'Select from top instructors and learn from the best',
    homeview_media_subtitle_5: 'In person classes and Virtual classes',
    homeview_media_subtitle_6: 'Your choice to go virtual or in person',
    homeview_category_subtitle_1: 'Top categories',
    homeview_category_subtitle_2: 'Dance',
    homeview_category_subtitle_3: 'Coding',
    homeview_category_subtitle_4: 'Language',
    homeview_category_subtitle_5: 'Design',
    // AllCourseView
    allcourseview_subtitle_1: 'What to learn next',
    allcourseview_allcategories: 'ALL CATEGORIES',
    // CourseView
    courseview_sorry: "Sorry, the course isn't found.",
    courseview_instructedby: 'Instructed by Beyond Activity',
    courseview_categorydance: 'Category Dance',
    courseview_coursedescription: 'Course Description',
    courseview_clicktoregister: 'Click to register',
    courseview_sessions: 'Sessions',
    courseview_upcoming: 'Upcoming',
    courseview_completed: 'Completed',
    courseview_register: 'Register',
    courseview_attendees: 'Attendees',
    courseview_duration: 'Duration',
    courseview_price: 'Price',
    courseview_age: 'Age',
    courseview_languageofinstruction: 'Language of Instruction',
    courseview_languagelevel: 'Language Level',
    courseview_materials: 'Materials',
    //GuestCheckout
    guestcheckout_checkout: 'Checkout',
    guestcheckout_providedby: 'Provided by',
    guestcheckout_contactinfo: 'Contact Info',
    guestcheckout_submitcontactinfo: 'Submit Contact Info',
    guestcheckout_paymentmethod: 'Payment Methods',
    guestcheckout_subtitle: 'Subtotal',
    guestcheckout_paymentservicefee: 'Payment Service Fee',
    guestcheckout_total: 'Total',
    guestcheckout_paynow: 'Pay Now',
    guestcheckout_parentname: 'Parent Name (e.g., John Smith) *',
    guestcheckout_kidname: 'Kid Name (e.g., David Smith) *',
    guestcheckout_kidage: 'Kid Age *',
    guestcheckout_anyquestion: 'Do you have any questions?',
    // InterestForm
    interestform_contactus: 'Or contact us at',
    interestform_inquerysubmitted: 'The inquiry has been submitted successfully.',
    interestform_inquiryform: 'Inquiry Form',
    interestform_requiredfields: '*indicates required field',
    // AboutView
    aboutview_title: 'About Our Company',
    aboutview_abouttext: `The things we do every day, big or small, shape our lives. Some of these activities are so habitual that we don't even think about them. Others are so momentous that they change the course of our lives forever. But each and every one of them contributes to who we are and how our life unfolds.

    At Beyond Activity, we strive to help people enrich their life and enjoy a healthy happy living. Our mission is to make it hassle free for our customers to find, learn about, and take on the activities they love no matter if it's a learning, hobby or connecting with like-minded people. We do this by offering high quality and reliable activity information which is backed by outstanding customer service and easy-to-use tooling.`,
    aboutview_ourvision: 'Our Vision',
    aboutview_visiontext: 'Help people enrich their life and enjoy a healthy happy living.',
    aboutview_ourmission: 'Our Mission',
    aboutview_missiontext: 'By offering high quality and reliable activity information as well as services and tooling, our customer can focus on enjoying the learnings, pursue their hobbies and connect with like-minded people without the hassle.',
    // ContactUsView
    contactusview_keepintouch: 'Keep in touch',
    contactusview_location: 'Location:',
    contactusview_email: 'Email:',
    contactusview_phone: 'Phone:',
    contactusview_openhour: 'Monday – Friday 9 am - 6 pm',
    contactusview_subscribe: 'Subscribe',
    contactusview_sendusamessage: 'Send us a message',
    contactusview_submitinquery: 'Submit Inquiry',
    // ThankyouView
    thankyouview_thankyou: 'Thank You!',
    thankyouview_orderplaced: 'Your order has been placed.',
    // SignInView
    signinview_signin: 'Sign In',
    signinview_signup: 'Sign Up',
    // SignUp
    signup_stmt1: 'By signing up, you agree to our',
    signup_passwordtooshort: 'Password is too short. At least 8 characters.',
    signup_passwordnotmatch: "Password don't match.",
    // Header
    header_signout: 'Sign Out',
    header_allcourses: 'All Courses',
    header_categories: 'Categories',
    // Payment
    payment_creditcard: 'Credit Card',
    payment_stmt1: 'There is 3% of credit card service fee. You can select Zelle',
    payment_stmt2: 'to avoid the payment service fee.',
    unused: ''
};
