<template>
<CommonPageFramework>
  <div class="py-10" :style="{'min-height': mainBodyHeight}">
    <v-container fill-height>
      <v-row v-if="$vuetify.display.lgAndUp" no-gutters>
        <v-col class="v-col-4 offset-2 px-4">
          <v-container>
            <v-row>
              <v-col>
                <span class="GetInTouch">{{ $t('contactusview_keepintouch') }}</span>
              </v-col>
            </v-row><v-row>
              <v-col>
                <div class="WithUs">{{withUs}}<span style="visibility:hidden;">|</span></div>
              </v-col>
            </v-row>
            <v-row v-if="false" class="py-2">
              <v-col>
                <span class="ContactUsText text-left">{{ $t('contactusview_location') }}</span>
                <span class="ContactUsText text-left">Beacon Park, Irvine, CA 92618</span>
              </v-col>
            </v-row>
            <v-row class="py-2">
              <v-col>
                <span class="ContactUsText text-left">{{ $t('contactusview_email') }}</span>
                <span class="ContactUsText text-left">BeyondActivityBA@gmail.com</span>
              </v-col>
            </v-row>
            <v-row class="py-2">
              <v-col>
                <span class="ContactUsText text-left">{{ $t('contactusview_phone') }}</span>
                <span class="ContactUsText text-left">949 678-5014</span>
                <span class="ContactUsText text-left">{{ $t('contactusview_openhour') }}</span>
              </v-col>
            </v-row>
            <v-row v-if="false" class="py-2" align="center" justify="center">
              <v-col class="v-col-7">
                <v-text-field :label="$t('common_youremail')" hide-details="auto"></v-text-field>
              </v-col>
              <v-col class="v-col-5">
                <v-btn class="SubscribeBtn">{{ $t('contactusview_subscribe') }}</v-btn>
              </v-col>
            </v-row>
            <v-row v-if="false" class="py-12">
              <v-col><p class="SendUsMessage">{{ $t('contactusview_sendusamessage') }}</p></v-col>
            </v-row>
            <v-row v-if="false">
              <v-col><v-text-field :label="$t('common_fullname')" hide-details="auto"></v-text-field></v-col>
            </v-row>
            <v-row v-if="false">
              <v-col><v-text-field :label="$t('common_email')" hide-details="auto"></v-text-field></v-col>
            </v-row>
            <v-row v-if="false">
              <v-col>
                <v-textarea filled :label="$t('common_yourmessage')"></v-textarea>
              </v-col>
            </v-row>
            <v-row v-if="false">
              <v-col><v-btn class="SubscribeBtn">{{ $t('contactusview_submitinquery') }}</v-btn></v-col>
            </v-row>
          </v-container>
        </v-col>
        <v-col class="v-col-4">
          <v-container class="fill-height">
            <v-row class="fill-height" align="center" justify="center">
              <v-col>
                  <v-img :src="contactUsImg"></v-img>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col class="v-col-12">
          <v-container class="px-0">
            <v-row>
              <v-col>
                <v-container>
                  <v-row>
                    <v-col class="v-col-12 GetInTouch text-center"><p>{{ $t('contactusview_keepintouch') }}</p></v-col>
                  </v-row>
                  <v-row class="py-6">
                    <v-col class="v-col-12 text-center py-0">
                      <div class="WithUs">{{withUs}}<span style="visibility:hidden;">|</span></div>
                    </v-col>
                  </v-row>
                  <v-row v-if="false">
                    <v-col class="v-col-12 ContactUsText text-center py-0">
                      <p>{{ $t('contactusview_location') }}</p>
                      <p>Beacon Park, Irvine, CA 92618</p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="v-col-12 ContactUsText text-center pt-10">
                      <p>{{ $t('contactusview_email') }}</p>
                      <p>BeyondActivityBA@gmail.com</p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="v-col-12 ContactUsText text-center pt-10">
                      <p>{{ $t('contactusview_phone') }}</p>
                      <p>949 678-5014</p>
                      <p>{{ $t('contactusview_openhour') }}</p>
                    </v-col>
                  </v-row>
                  <v-row v-if="false">
                    <v-col class="v-col-10 offset-1">
                      <v-text-field :label="$t('common_youremail')" hide-details="auto"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row v-if="false">
                    <v-col class="v-col-10 offset-1 text-center">
                      <v-btn class="SubscribeBtn">{{ $t('contactusview_subscribe') }}</v-btn>
                    </v-col>
                  </v-row>
                  <v-row v-if="false" class="pt-12">
                    <v-col><p class="SendUsMessage text-center">{{ $t('contactusview_sendusamessage') }}</p></v-col>
                  </v-row>
                  <v-row v-if="false">
                    <v-col class="v-col-10 offset-1 py-4"><v-text-field :label="$t('common_fullname')" hide-details="auto"></v-text-field></v-col>
                  </v-row>
                  <v-row v-if="false">
                    <v-col class="v-col-10 offset-1 py-4"><v-text-field :label="$t('common_email')" hide-details="auto"></v-text-field></v-col>
                  </v-row>
                  <v-row v-if="false">
                    <v-col class="v-col-10 offset-1 py-4"><v-textarea filled :label="$t('common_yourmessage')"></v-textarea></v-col>
                  </v-row>
                  <v-row v-if="false">
                    <v-col class="text-center"><v-btn class="SubscribeBtn">{{ $t('contactusview_submitinquery') }}</v-btn></v-col>
                  </v-row>
                </v-container>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-img :src="madKpopDanceCourseImg"></v-img>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </div>
  
  <div v-if="false">
    <v-container fill-height>
      <v-row v-if="$vuetify.display.lgAndUp" no-gutters>
        <v-col class="v-col-4 offset-2 px-4">
          <v-container>
            <v-row>
              <v-col><p class="danceClassTitle">Send us a message</p></v-col>
            </v-row>
            <v-row>
              <v-col><v-text-field hide-details="auto"></v-text-field></v-col>
            </v-row>
            <v-row>
              <v-col><v-text-field hide-details="auto"></v-text-field></v-col>
            </v-row>
            <v-row>
              <v-col><v-text-field hide-details="auto"></v-text-field></v-col>
            </v-row>
            <v-row>
              <v-col><v-btn class="JoinNowBtn" width="100%">Submit Inquiry</v-btn></v-col>
            </v-row>
          </v-container>
        </v-col>
        <v-col class="v-col-4 px-4">
          <GMapMap :center="center" :zoom="7" map-type-id="terrain" style="width: 500px; height: 300px">
            <GMapCluster>
              <GMapMarker
                :key="index"
                v-for="(m, index) in markers"
                :position="m.position"
                :clickable="true"
                :draggable="true"
                @click="center=m.position" />
            </GMapCluster>
          </GMapMap>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col class="v-col-12">
          <v-container>
            <v-row>
              <v-col><p class="danceClassTitle">Send us a message</p></v-col>
            </v-row>
            <v-row>
              <v-col><v-text-field hide-details="auto"></v-text-field></v-col>
            </v-row>
            <v-row>
              <v-col><v-text-field hide-details="auto"></v-text-field></v-col>
            </v-row>
            <v-row>
              <v-col><v-text-field hide-details="auto"></v-text-field></v-col>
            </v-row>
            <v-row>
              <v-col><v-btn class="JoinNowBtn" width="100%">Submit Inquiry</v-btn></v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </div>

  <div v-if="false">
    <v-container fill-height>
      <v-row v-if="$vuetify.display.lgAndUp" no-gutters>
        <v-col class="v-col-4 offset-2 px-4">
          <v-container>
            <v-row>
              <v-col><p class="danceClassTitle">Send us a message</p></v-col>
            </v-row>
            <v-row>
              <v-col><v-text-field hide-details="auto"></v-text-field></v-col>
            </v-row>
            <v-row>
              <v-col><v-text-field hide-details="auto"></v-text-field></v-col>
            </v-row>
            <v-row>
              <v-col><v-text-field hide-details="auto"></v-text-field></v-col>
            </v-row>
            <v-row>
              <v-col><v-btn class="JoinNowBtn" width="100%">Submit Inquiry</v-btn></v-col>
            </v-row>
          </v-container>
        </v-col>
        <v-col class="v-col-4 px-4">
          <GMapMap :center="center" :zoom="7" map-type-id="terrain" style="width: 500px; height: 300px">
            <GMapCluster>
              <GMapMarker
                :key="index"
                v-for="(m, index) in markers"
                :position="m.position"
                :clickable="true"
                :draggable="true"
                @click="center=m.position" />
            </GMapCluster>
          </GMapMap>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col class="v-col-12">
          <v-container>
            <v-row>
              <v-col><p class="danceClassTitle">Send us a message</p></v-col>
            </v-row>
            <v-row>
              <v-col><v-text-field hide-details="auto"></v-text-field></v-col>
            </v-row>
            <v-row>
              <v-col><v-text-field hide-details="auto"></v-text-field></v-col>
            </v-row>
            <v-row>
              <v-col><v-text-field hide-details="auto"></v-text-field></v-col>
            </v-row>
            <v-row>
              <v-col><v-btn class="JoinNowBtn" width="100%">Submit Inquiry</v-btn></v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </div>
</CommonPageFramework>
</template>
      
<script>
import { defineComponent } from 'vue';
  
// Components
import CommonPageFramework from '@/components/layout/CommonPageFramework.vue'
  
import contactUsImg from "../assets/contact-us.svg";
  
export default defineComponent({
  name: 'ContactUsView',
  components: {
    CommonPageFramework
  },
  computed: {
    withUs () {
      return this.withUsText;
    }
  },
  methods: {
    onTimeout() {
      var duration = 100;
      this.timer = null;
      if (this.forward) {
        if (this.withUsText == '') {
          this.withUsText = 'W';
        } else if (this.withUsText == 'W') {
          this.withUsText = 'Wi';
        } else if (this.withUsText == 'Wi') {
          this.withUsText = 'Wit';
        } else if (this.withUsText == 'Wit') {
          this.withUsText = 'With';
        } else if (this.withUsText == 'With') {
          this.withUsText = 'With ';
        } else if (this.withUsText == 'With ') {
          this.withUsText = 'With U';
        } else if (this.withUsText == 'With U') {
          this.withUsText = 'With Us';
        } else if (this.withUsText == 'With Us') {
          this.withUsText = 'With Us!';
        } else if (this.withUsText == 'With Us!') {
          this.forward = false;
          duration = 400;
        }
      } else {
        if (this.withUsText == '') {
          this.forward = true;
        } else if (this.withUsText == 'W') {
          this.withUsText = '';
        } else if (this.withUsText == 'Wi') {
          this.withUsText = 'W';
        } else if (this.withUsText == 'Wit') {
          this.withUsText = 'Wi';
        } else if (this.withUsText == 'With') {
          this.withUsText = 'Wit';
        } else if (this.withUsText == 'With ') {
          this.withUsText = 'With';
        } else if (this.withUsText == 'With U') {
          this.withUsText = 'With ';
        } else if (this.withUsText == 'With Us') {
          this.withUsText = 'With U';
        } else if (this.withUsText == 'With Us!') {
          this.withUsText = 'With Us';
        }
      }
      this.timer = setTimeout(this.onTimeout, duration);
    }
  },
  data() {
    return {
      contactUsImg: contactUsImg,
      timer: null,
      withUsText: '',
      forward: true,
      center: {lat: 51.093048, lng: 6.842120},
        markers: [
          {
            position: {
              lat: 51.093048, lng: 6.842120
            },
          }
          , // Along list of clusters
        ]
      }
   },
   created() {
     this.timer = setTimeout(this.onTimeout, 1);
   }
});
</script>

<style lang="scss" scoped>
.GetInTouch {
  color: #2b0101;
  font-weight: 600;
  font-family: "Work Sans";
  padding-top: 0rem;
  letter-spacing: 0rem;
  line-height: 1.5;
  padding-bottom: 0rem;
  font-size: 2.25rem;
  display: block;
  margin-top: 0;
  margin-bottom: 0.5rem;
  text-align: left;
}

.ContactUsText {
  color: #2b0101;
  font-weight: 300;
  font-family: "Work Sans";
  padding-top: 0rem;
  letter-spacing: 0rem;
  line-height: 1.5;
  font-size: 1.25rem;
  display: block;
  margin-top: 0;
}

.SubscribeBtn {
  border-radius: 0.5rem;
  background-color: #460303;
  color: #fff;
  font-family: "Work Sans";
  letter-spacing: 0rem;
  font-weight: 600;
  font-size: 1rem;
  text-decoration: none;
  text-transform: none;
}

.SendUsMessage {
  line-height: 1.5;
  font-size: 2.25rem;
  color: #2b0101;
  font-weight: 600;
  font-family: "Work Sans";
  white-space: pre-line;
  display: block;
}

.InquiryBtn {
  border-radius: 0.5rem;
  background-color: #460303;
  color: #fff;
  font-family: "Work Sans";
  letter-spacing: 0rem;
  font-weight: 600;
  font-size: 1rem;
  text-decoration: none;
  text-transform: none;
}

.WithUs {
  line-height: 1.5;
  font-size: 2.25rem;
  color: #ef3075;
  font-weight: 300;
  font-family: "Work Sans";
  white-space: pre-line;
  display: block;
}
</style>
