import axios from 'axios';
import ServiceBase from './base.js'

export default {
  ...ServiceBase,

  // list all courses
  getListCourses(token, succeed = (response)=>{}, failure = (error)=>{}, postExecution = ()=>{}) {  // eslint-disable-line no-unused-vars
    axios.get(this.V1_SERVICE_PREFIX + '/user/listcourses', {
      params: {
        token: this.trim(token)
      }
    })
    .then(succeed)
    .catch(failure)
    .finally(postExecution)
    ;
  },
}