<template>
<v-container>
  <v-row class="pb-8">
    <v-col>
      <v-tabs v-model="paymentType">
        <v-tab value="card">{{ $t('payment_creditcard') }}</v-tab>
        <v-tab value="zelle">Zelle<font-awesome-icon icon="fa-regular fa-registered"/></v-tab>
      </v-tabs>
    </v-col>
  </v-row>
  <v-row v-if="paymentType == 'card'">
    <v-col class="text-left">
      <div>
        <form id="payment-form">
          <div id="payment-element">
          <!-- Elements will create input elements here -->
          </div>  
          <!-- We'll put the error messages in this element -->
          <div id="payment-message" class="hidden"></div>
        </form>
      </div>
    </v-col>
  </v-row>
  <v-row v-if="needDisplayStripeLogo">
    <v-col>
      <img :src="stripeLogo" class="stripe-logo">
    </v-col>
  </v-row>
  <v-row v-if="paymentType == 'card'">
    <v-col class="text-center">
      <p>{{ $t('payment_stmt1') }} <font-awesome-icon icon="fa-regular fa-registered"/> {{ $t('payment_stmt2') }}</p>
    </v-col>
  </v-row>
  <v-row v-if="paymentType == 'zelle'">
    <v-col class="text-center">
      <img :src="zelloQRCode" class="zelle-logo">
    </v-col>
  </v-row>
</v-container>
</template>

<script>
import Service from '@/service.js'

import {STRIPE_PUBLIC_KEY} from '@/const.js';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import stripeLogo from "@/assets/stripe.svg";
import zelloQRCode from "@/assets/zelle.png";

export default {
  name: 'stripe-element',
  props: ['metadata', 'amount', 'description'],
  components: {
    FontAwesomeIcon,
  },
  data: () => ({
    stripeLogo: stripeLogo,
    zelloQRCode: zelloQRCode,
    paymentType: 'card',
    displayStripeLogo: false,
    paymentIntentId: null,
    elements: null,
    stripe: null,
  }),
  methods: {
    pay() {
      this.$emit('updatePaymentStatus', 'loading');

      new Service().updatePaymentIntent(
        this.paymentIntentId,
        this.metadata,
        (response) => {// eslint-disable-line no-unused-vars
          let param = {
            elements: this.elements,
            confirmParams: {
              return_url: "https://www.beyondactivity.com/thankyou",
            }
          };
          this.stripe.confirmPayment(param).then(
            (result) => { // eslint-disable-line no-unused-vars
              // will direct to the return url.
              if (result.error) {
                if (result.error.type == 'card_error' || result.error.type == 'validation_error') {
                  this.$emit('updatePaymentStatus', result.error.message);
                }
              } else {
                this.$emit('updatePaymentStatus', null);
              }
            },
            (reason) => { // eslint-disable-line no-unused-vars
              this.$emit('updatePaymentStatus', 'System Error, Please Try Again Later.');
            }
          );
        },
        (error) => { // eslint-disable-line no-unused-vars
          this.$emit('updatePaymentStatus', 'Beyond Activity System Error, Please Try Again Later.');
        }
      )
    },
    payWithZelle() {
      this.$emit('updatePaymentStatus', 'loading');

      new Service().createZellePayment(
        this.amount,
        this.description,
        this.metadata.fullname == undefined ? '' : this.metadata.fullname,
        this.metadata.email == undefined ? '' : this.metadata.email,
        this.metadata.phone == undefined ? '' : this.metadata.phone,
        this.metadata.kidname == undefined ? '' : this.metadata.kidname,
        this.metadata.kidage == undefined ? '' : this.metadata.kidage,
        this.metadata.great_park_neighborhood == undefined ? '' : this.metadata.great_park_neighborhood,
        this.metadata.question == undefined ? '' : this.metadata.question,
        (response) => {// eslint-disable-line no-unused-vars
          this.$router.push('/thankyou');
        },
        (error) => { // eslint-disable-line no-unused-vars
          this.$emit('updatePaymentStatus', 'Beyond Activity System Error, Please Try Again Later.');
        }
      );
    },
    initStripe() {
      this.$emit('updatePaymentStatus', null);
      
      this.paymentIntentId = null;
      this.displayStripeLogo = false;

      new Service().createClientSecret(
        this.amount, 'card', this.description, this.metadata,
        (response) => {
          let clientSecret = response.data.client_secret;
          this.paymentIntentId = response.data.id;
          const appearance = {
            theme: 'stripe',
            variables: {
              colorIcon: '#2b0101',
              colorPrimary: '#2b0101',
              colorBackground: '#ffffff',
              colorText: '#30313d',
              colorDanger: '#df1b41',
              fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
              fontSizeBase: '1rem',
              fontWeightNormal: 300,
              fontWeightLight: 300,
              spacingUnit: '0.25rem',
              borderRadius: '4px',
            }
          };
          this.stripe = window.Stripe(STRIPE_PUBLIC_KEY);
          this.elements = this.stripe.elements({clientSecret, appearance});
          const paymentElement = this.elements.create('payment');
          paymentElement.mount('#payment-element');
          this.displayStripeLogo = true;
          let thisObj = this;
          paymentElement.on('change', function(event) {
            thisObj.$emit("updatePaymentInfoComplete", event.complete);
          });

          this.$emit('paymentCallback', this.pay);
        },
        (error) => { // eslint-disable-line no-unused-vars
        },
        () => {
        }
      )
    }
  },
  watch: {
    paymentType (newValue) {
      if (newValue == 'card') {
        this.$emit('paymentCallback', this.pay);
        this.$emit('updatePaymentMethod', 'card');
        this.initStripe();
      } else {
        this.$emit('paymentCallback', null);
        this.$emit('updatePaymentMethod', 'zelle');
        this.$emit('paymentCallback', this.payWithZelle);
      }
    }
  },
  computed: {
    needDisplayStripeLogo() {
      return this.displayStripeLogo && this.paymentType == 'card';
    }
  },
  unmounted() {
    let stripeScript = document.getElementById("stripe");
    document.head.removeChild(stripeScript)
  },
  mounted() {
    this.$emit('updatePaymentMethod', 'card');

    let stripeScript = document.createElement('script');
    stripeScript.setAttribute("id", "stripe");
    stripeScript.setAttribute('src', 'https://js.stripe.com/v3/');
    
    stripeScript.onload = () => {
      this.initStripe();
    };
    
    document.head.appendChild(stripeScript);
  }
}
</script>
    
<style lang="scss" scoped>
.stripe-logo {
  height: 30px;
}

.zelle-logo {
  max-width: 100%;
}

.v-img__img--contain {
  background-position: right !important;
}
</style>
      