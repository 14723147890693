import axios from 'axios';
import ServiceBase from './base.js'

export default {
  ...ServiceBase,

  // list all payments
  getListPayments(token, succeed = (response)=>{}, failure = (error)=>{}, postExecution = ()=>{}) {  // eslint-disable-line no-unused-vars
    axios.get(this.V1_SERVICE_PREFIX + '/user/listpayments', {
      params: {
        token: this.trim(token)
      }
    })
    .then(succeed)
    .catch(failure)
    .finally(postExecution)
    ;
  },

  // create a Zelle payment record
  createZellePayment(amount, description, name, email, phone, kidname, kidage, great_park_neighborhood, question, succeed = (response)=>{}, failure = (error)=>{}, postExecution = ()=>{}) {  // eslint-disable-line no-unused-vars
    axios.post(this.V1_SERVICE_PREFIX + '/zelle/createpayment', {
      amount: amount,
      description: this.trim(description),
      name: this.trim(name),
      email: this.trim(email),
      phone: this.trim(phone),
      kidname: this.trim(kidname),
      kidage: this.trim(kidage),
      great_park_neighborhood: this.trim(great_park_neighborhood),
      question: this.trim(question),
    })
    .then(succeed)
    .catch(failure)
    .finally(postExecution)
    ;
  }
}