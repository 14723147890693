<template>
  <div width="100%">
    <div class="d-none d-md-flex ba-header">
      <BAHeader/>
    </div>
    <div class="d-md-none ba-header">
      <BARightNavigation/>
    </div>
    <div :style="{'min-height': mainBodyHeight}">
      <slot></slot>
    </div>
    

    <div class="footer-style">
      <BAFooter></BAFooter>
    </div>
    <v-snackbar v-model="displayErrorMessage" timeout="-1">
      {{ getErrorMessage }}
      <template v-slot:actions>
        <v-btn color="pink" variant="text" @click="clearErrorMessage">{{ $t('common_close') }}</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import common from '@/assets/mixins/common.js';

import BAHeader from './Header.vue';
import BAFooter from './Footer.vue';
import BARightNavigation from './RightNavigation.vue';

export default defineComponent({
  name: 'CommonPageFramework',

  components: {
    BAHeader,
    BARightNavigation,
    BAFooter
  },
  mixins: [common],
});
</script>

<style lang="scss" scoped>
</style>
  