<template>
<CommonPageFramework>
  <div class="ba-body pl-4 pr-4">
    <v-container>
      <v-row justify="space-around">
        <v-col class="v-col-xl-8 v-col-lg-10 v-col-md-10">
          <v-container>
            <v-row class="course-row" justify="space-around">
              <v-col>
                <v-container class="pa-0">
                  <v-row class="text-left" justify="space-around">
                    <v-col v-if="$vuetify.display.smAndDown" class="v-col-12 text-center">
                      <h1 class="course-title text-center">{{ $t('homeview_title') }}</h1>
                      <p class="course-text text-center">{{ $t('homeview_subtitle_1') }}</p>
                      <v-btn x-large class="all-courses-btn" @click="$router.push('/courses')">{{ $t('homeview_seeAllCourses') }}</v-btn>
                      <p style="visibility:hidden;">a</p>
                      <p style="visibility:hidden;">a</p>
                      <p style="visibility:hidden;">a</p>
                    </v-col>
                    <v-col v-else>
                      <h1 class="course-title text-left">{{ $t('homeview_title') }}</h1>
                      <p class="course-text text-left">{{ $t('homeview_subtitle_2') }}</p>
                      <p class="course-text text-left">{{ $t('homeview_subtitle_3') }}</p>
                      <p class="course-text text-left">{{ $t('homeview_subtitle_4') }}</p>
                      <v-btn x-large class="all-courses-btn" @click="$router.push('/courses')">{{ $t('homeview_seeAllCourses') }}</v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </div>
  <div class="four-course-row">
    <v-container>
      <v-row justify="space-around">
        <v-col class="v-col-xl-8 v-col-lg-10 v-col-md-10">
          <v-container v-if="$vuetify.display.smAndDown">
            <v-row justify="space-around">
              <v-col class="v-col-11">
                <div class="media">
                  <font-awesome-icon icon="fas fa-clock fa-fw" class="px-4"/>
                  <div class="media-body text-left pl-4">
                    <h6 class="media-title">{{ $t('homeview_media_subtitle_1') }}</h6>
                    <p class="media-text">{{ $t('homeview_media_subtitle_2') }}</p>
                  </div>
                </div>
              </v-col>
            </v-row>
            <v-row justify="space-around">
              <v-col class="v-col-11">
                <div class="media">
                  <font-awesome-icon icon="fas fa-chalkboard-teacher fa-fw" class="px-4"/>
                  <div class="media-body text-left pl-4">
                    <h6 class="media-title">{{ $t('homeview_media_subtitle_3') }}</h6>
                    <p class="media-text">{{ $t('homeview_media_subtitle_4') }}</p>
                  </div>
                </div>
              </v-col>
            </v-row>
            <v-row justify="space-around">
              <v-col class="v-col-11">
                <div class="media">
                  <font-awesome-icon icon="fas fa-photo-video fa-fw" class="px-4"/>
                  <div class="media-body text-left pl-4">
                    <h6 class="media-title">{{ $t('homeview_media_subtitle_5') }}</h6>
                    <p class="media-text">{{ $t('homeview_media_subtitle_6') }}</p>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-container>
          <v-container v-else class="text-center" >
            <v-row justify="space-around">
              <v-col class="v-col-4 px-2">
                <div class="media">
                  <font-awesome-icon icon="fas fa-clock fa-fw" class="px-4"/>
                  <div class="media-body text-left pl-4">
                    <h6 class="media-title">{{ $t('homeview_media_subtitle_1') }}</h6>
                    <p class="media-text">{{ $t('homeview_media_subtitle_2') }}</p>
                  </div>
                </div>
              </v-col>
              <v-col class="v-col-4">
                <div class="media">
                  <font-awesome-icon icon="fas fa-chalkboard-teacher fa-fw" class="px-4"/>
                  <div class="media-body text-left pl-4">
                    <h6 class="media-title">{{ $t('homeview_media_subtitle_3') }}</h6>
                    <p class="media-text">{{ $t('homeview_media_subtitle_4') }}</p>
                  </div>
                </div>
              </v-col>
              <v-col class="v-col-4">
                <div class="media">
                  <font-awesome-icon icon="fas fa-photo-video fa-fw" class="px-4"/>
                  <div class="media-body text-left pl-4">
                    <h6 class="media-title">{{ $t('homeview_media_subtitle_5') }}</h6>
                    <p class="media-text">{{ $t('homeview_media_subtitle_6') }}</p>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </div>
  <div id="categories" class="text-row">
    <v-container>
      <v-row justify="space-around">
        <v-col class="v-col-8 text-center">
          <h2 class="topcategories">{{ $t('homeview_category_subtitle_1') }}</h2>
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <v-row justify="space-around">
        <v-col class="v-col-xl-8 v-col-lg-10 v-col-md-12">
          <v-container class="px-0">
            <v-row class="text-center" justify="space-around" no-gutters>
              <v-col class="v-col-md-3 v-col-sm-6">
                <label class="specific-course course-1"></label>
                <h6 class="course-name py-5">{{ $t('homeview_category_subtitle_2') }}</h6>
              </v-col>
              <v-col class="v-col-md-3 v-col-sm-6">
                <label class="specific-course course-2"></label>
                <h6 class="course-name py-5">{{ $t('homeview_category_subtitle_3') }}</h6>
              </v-col>
              <v-col class="v-col-md-3 v-col-sm-6">
                <label class="specific-course course-3"></label>
                <h6 class="course-name py-5">{{ $t('homeview_category_subtitle_4') }}</h6>
              </v-col>
              <v-col class="v-col-md-3 v-col-sm-6">
                <label class="specific-course course-4"></label>
                <h6 class="course-name py-5">{{ $t('homeview_category_subtitle_5') }}</h6>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </div>
</CommonPageFramework>
</template>

<script>
import { defineComponent } from 'vue';

// Components
import CommonPageFramework from '@/components/layout/CommonPageFramework.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default defineComponent({
  name: 'HomeView',

  components: {
    CommonPageFramework,
    FontAwesomeIcon
  },
});
</script>


<style lang="scss" scoped>
.ba-header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
  background-color: #fff;
}

.ba-body {
  font-family: "Work Sans";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  padding-top: 6rem;
  display: block;
  padding-bottom: 2rem;
  background-attachment: scroll;
  background-position: center;
  background-size: auto;
  background-repeat: no-repeat;
  background-image: url('@/assets/home-background.jpeg');
}

.course-title {
  color: #2b0101;
  font-weight: 600;
  font-family: "Work Sans";
  padding-top: 0rem;
  padding-bottom: 1rem;
  letter-spacing: 0rem;
  line-height: 1.25;
  
  font-size: 2.25rem;
  white-space: pre-line;
  margin-bottom: 0.5rem;
  margin-top: 0rem;
  display: block;
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.course-text {
  color: #2b0101;
  font-weight: 300;
  font-family: "Work Sans";
  padding-top: 0rem;
  padding-bottom: 0.25rem;
  letter-spacing: 0rem;
  line-height: 1;
  
  font-size: 1.25rem;
  white-space: pre-line;
  margin-bottom: 0rem;
  margin-top: 0rem;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.all-courses-btn {
  font-family: "Work Sans";
  background-color: #460303;
  color: #ffffff;
  letter-spacing: 0rem;
  font-weight: 600;
  display: inline-block;
  font-size: 1.25rem;
  text-decoration: none;
  border-radius: 0.5rem;
  cursor: pointer;
  text-align: left;
  text-transform: none;
  margin-top: 1.5rem;
}

.media {
  display: flex;
  align-items: flex-start;
  font-size: 1.875rem;
  color: #460303;
}

.media-body {
  flex: 1;
}

.media-title {
  color: #2b0101;
  font-family: "Work Sans";
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  margin-top: 0;
  display: block;
  //margin-block-start: 2.33em;
  //margin-block-end: 2.33em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.media-text {
  color: #2b0101;
  font-family: "Work Sans";
  font-weight: 300;
  font-size: 1rem;
  line-height: 2;
  white-space: pre-line;
  margin-bottom: 1rem;
  display: block;
  letter-spacing: 0rem;
  white-space: pre-line;
}

.course-row {
  padding-bottom: 2rem;
  background-attachment: scroll;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 0rem;
  display: block;
}

.four-course-row {
  background-attachment: scroll;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-bottom-color: #000;
  border-bottom-width: 1px;
  border-bottom-style: none;
  border-top-color: #000;
  border-top-width: 1px;
  border-top-style: none;
  padding-bottom: 6rem;
  padding-top: 3rem;
  background-color: #fff;
}

.text-row {
  background-attachment: scroll;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-bottom-color: #000;
  border-bottom-width: 1px;
  border-bottom-style: none;
  border-top-color: #000;
  border-top-width: 1px;
  border-top-style: none;
  padding-bottom: 6rem;
  padding-top: 3rem;
  background-color: #f7f2f2;
}

.text-title {
  font-size: 30px;
  font-weight: 700;
  cursor: text;
  white-space: pre-line;
  line-height: inherit;
  word-wrap: break-word;
  tab-size: 4;
  text-align: left;
  letter-spacing: 0rem;
  font-family: "Work Sans";
  overflow-y: auto;
  padding: 12px 15px;
}

.text-text {
  font-size: 18px;
  cursor: text;
  white-space: pre-line;
  line-height: inherit;
  word-wrap: break-word;
  tab-size: 4;
  text-align: left;
  letter-spacing: 0rem;
  font-family: "Work Sans";
  overflow-y: auto;
  padding: 12px 15px;
}

.text-ul {
  list-style-type: disc;
  margin: 1.5em 1.5em;
  padding-left: 1.5em;
  cursor: text;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
  line-height: inherit;
  word-wrap: break-word;
  tab-size: 4;
  text-align: left;
  white-space: pre-wrap;
  font-size: 16px;
}

.btn-row {
  padding-top: 0rem;
  border-bottom-width: 1px;
  border-top-width: 1px;
  border-top-color: #000;
  border-top-style: none;
  padding-bottom: 3rem;
  background-color: #f7f2f2;
  background-attachment: scroll;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-bottom-color: #d9d2d2;
  border-bottom-style: solid;
  display: block;
}

.btn-btn {
  border-radius: 0.5rem;
  background-color: #460303;
  color: #fff;
  font-family: "Work Sans";
  letter-spacing: 0rem;
  font-weight: 600;
  display: inline-block;
  font-size: 0.75rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-right: 4rem;
  padding-left: 4rem;
  border-color: #000;
  margin-right: 0.25rem;
  margin-left: 0.25rem;
  margin-bottom: 0.5rem;
  text-decoration: none;
  border-width: 1px;
  border-style: none;
  cursor: pointer;
  text-align: center;
  line-height: 1.5;
}

.topcategories {
  color: #2b0101;
  font-weight: 600;
  font-family: "Work Sans";
  line-height: 1.5;
  padding-bottom: 1rem;
  font-size: 3rem;
  white-space: pre-line;
  margin-bottom: 0.5rem;
  margin-top: 0;
  display: block;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.course-name {
  color: #2b0101;
  font-family: "Work Sans";
  font-size: 1.875rem;
  margin-bottom: 0.5rem;
  font-weight: 600;
  line-height: 1.2;
  margin-top: 0;
  display: block;
  //margin-block-start: 2.33em;
  //margin-block-end: 2.33em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.course-1 {
  background-image: url('@/assets/home-dance-course.jpeg');
}

.course-2 {
  background-image: url('@/assets/home-coding-course.webp');
}

.course-3 {
  background-image: url('@/assets/home-language-course.webp');
}

.course-4 {
  background-image: url('@/assets/home-design-course.jpeg');
}

.specific-course {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 16rem;
  width: 16rem;
  //margin-bottom: 1.5rem;
  display: inline-block;
}

@media screen and (max-width: 1904px) {
  .specific-course {
    height: 14rem;
    width: 14rem;
  }
}

@media screen and (max-width: 1264px) {
  .specific-course {
    height: 12rem;
    width: 12rem;
  }
}

@media screen and (max-width: 960px) {
  .specific-course {
    height: 16rem;
    width: 16rem;
  }
}

.footer-style {
  background-color: #f7f2f2;
}
</style>
