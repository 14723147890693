<template>
  <CommonPageFramework>
    <div style="background-color: #f7f2f2;font-family: 'Font Awesome 5 Free';">
      <v-container><v-row><v-col>
      <h1 class="ba-title">Terms of Service<br><span class="ba-updated">Updated on April 16, 2023</span></h1>

      <p class="ba-paragraph">Welcome to Beyond Activity ("Beyond Activity," "we," "our", or "us"). This page explains 
        the terms by which you may use our services (collectively the "Service").</p>
      
      <p class="ba-paragraph">By accessing or using the Service, you signify that you have read, understood, and agree 
        to be bound by this User Agreement ("Agreement"), to the collection and use of your information as set forth in 
        the Beyond Activity <a href="/privacypolicy" class="link" target="_blank">Privacy Policy</a>, and, if you are a 
        Service Provider (as defined in the Service Provider Agreement) the <a href="/serviceprovideragreement" class="link" 
        target="_blank">Service Provider Agreement</a>, whether or not you are a registered user of our Service. 
        Beyond Activity reserves the right to make unilateral modifications to these terms and will provide notice of these 
        changes as described below.</p>
      <p class="ba-paragraph">This Agreement applies to all visitors, users, and others who access the Service, 
        including Service Providers (each a "User").</p>
    
      <p class="ba-paragraph">PLEASE READ THIS AGREEMENT CAREFULLY TO ENSURE THAT YOU UNDERSTAND EACH PROVISION. 
        THIS AGREEMENT CONTAINS A MANDATORY INDIVIDUAL ARBITRATION AND CLASS ACTION/JURY TRIAL WAIVER PROVISION 
        THAT REQUIRES THE USE OF ARBITRATION ON AN INDIVIDUAL BASIS TO RESOLVE DISPUTES, RATHER THAN JURY TRIALS 
        OR CLASS ACTIONS.</p>
      
      <ul class="ba-paragraph" style="list-style: none; text-align: left;">
        <li class="py-3">1. <b style="font-weight: 600;">Use of Our Service</b>. 
          <p class="py-3">Beyond Activity provides a simple and easy-to-use method for parents and Service Providers to connect 
          in order to provide adults and children with high-quality classes, camps, teams, leagues, and other 
          groups and events.</p>
            <ul class="pl-8" style="list-style-type:disc">
              <li class="py-3"><b style="font-weight: 600;">Eligibility</b>. You may use the Service only if you can form a binding contract with Beyond Activity, 
                and only in compliance with this Agreement and all applicable local, state, national, and international laws, 
                rules and regulations. Any use or access to the Service by anyone under 13 is strictly prohibited and 
                in violation of this Agreement. The Service is not available to any Users previously removed from the 
                Service by Beyond Activity.</li>
              <li class="py-3"><b style="font-weight: 600;">Beyond Activity Service</b>.
                Subject to the terms and conditions of this Agreement, you are hereby granted a non-exclusive, limited, 
                non-transferable, freely revocable license to use the Service for your personal, noncommercial use only 
                and as permitted by the features of the Service. Beyond Activity reserves all rights not expressly granted 
                herein in the Service and the Beyond Activity Content (as defined below). Beyond Activity may terminate this 
                license at any time for any reason or no reason.</li>
              <li class="py-3"><b style="font-weight: 600;">Beyond Activity Account Creation and Activation</b>.
                Your Beyond Activity account gives you access to the services and functionality that we may establish and maintain 
                from time to time and in our sole discretion. We may maintain different types of accounts for different types of 
                Users, including Service Providers. If you open a Beyond Activity account on behalf of a company, organization, 
                or other entity, then (a) “you” includes you and that entity, and (b) you represent and warrant that you are an 
                authorized representative of the entity with the authority to bind the entity to this Agreement (and, if you are a 
                Service Provider, the <a href="/serviceprovideragreement" class="link" target="_blank">Service Provider Agreement</a>).
                By connecting to Beyond Activity with a third-party service, you give us permission to access and use your information 
                from that service as permitted by that service.
                
                <p class="py-3">You may open an Beyond Activity account on your own, or, if you are a Service Provider, we may create one on your behalf 
                using publicly available information gathered via search engines such as Google or otherwise. If an Beyond Activity account 
                has been created for you, you may claim the account by emailing us at BeyondActivityBA@gmail.com and providing us sufficient 
                documentation, or you may request that we delete the account and we will do so. If you claim your Beyond Activity account, 
                you will be given the appropriate credentials by Beyond Activity and you will be able to use the account as if you had created 
                it on your own.</p></li>
              <li class="py-3"><b style="font-weight: 600;">Beyond Activity Account Rules and Management</b>.
                You may never use another account without permission. When creating your account, you must provide accurate and complete information. 
                You are solely responsible for the activity that occurs on your account, and you must keep your account credentials secure. 
                You must notify Beyond Activity immediately of any breach of security or unauthorized use of your account. Beyond Activity 
                will not be liable for any losses caused by any unauthorized use of your account.
                
                <p class="py-3">You may control your User profile and how you interact with the Service by changing the settings. By providing Beyond Activity your 
                email address you consent to our using the email address to send you Service-related notices, including any notices required by law, 
                in lieu of communication by postal mail. Any service providers you have enrolled with through Beyond Activity may also email you 
                regarding the activity or special offers. We may also use your email address to send you other messages, such as changes to features 
                of the Service and special offers. If you do not want to receive such email messages, you may opt out or change your preferences. 
                Opting out may prevent you from receiving email messages regarding updates, improvements, or offers.</p></li>
              <li class="py-3"><b style="font-weight: 600;">Service Rules</b>.
                You agree not to engage in any of the following prohibited activities: (i) copying, distributing, or disclosing any part of the Service 
                in any medium, including without limitation by any automated or non-automated “scraping”; (ii) unless permitted via a “robots.txt” file 
                or similar mechanism, using any automated system, including without limitation “robots,” “spiders,” “offline readers,” etc., to access 
                the Service in a manner that sends more request messages to the Beyond Activity servers than a human can reasonably produce in the same 
                period of time by using a conventional online web browser; (iii) transmitting spam, chain letters, or other unsolicited email; 
                (iv) attempting to interfere with, compromise the system integrity or security or decipher any transmissions to or from the servers 
                running the Service; (v) taking any action that imposes, or may impose at our sole discretion an unreasonable or disproportionately 
                large load on our infrastructure; (vi) uploading invalid data, viruses, worms, or other software agents through the Service; 
                (vii) collecting or harvesting any personally identifiable information, including account names, from the Service; (viii) using the 
                Service for any commercial solicitation purposes; (ix) impersonating another person or otherwise misrepresenting your affiliation with 
                a person or entity, conducting fraud, hiding or attempting to hide your identity; (x) interfering with the proper working of the Service; 
                (xi) accessing any content on the Service through any technology or means other than those provided or authorized by the Service; or 
                (xii) bypassing the measures we may use to prevent or restrict access to the Service, including without limitation features that prevent 
                or restrict use or copying of any content or enforce limitations on use of the Service or the content therein.
                
                <p class="py-3">We may, without prior notice, change the Service; stop providing the Service or features of the Service, to you or to users generally; 
                or create usage limits for the Service. We may permanently or temporarily terminate or suspend your access to the Service without notice 
                and liability for any reason, including if in our sole determination you violate any provision of this Agreement (or, if you are a Service 
                Provider, the <a href="/serviceprovideragreement" class="link" target="_blank">Service Provider Agreement</a>), or for no reason. 
                Upon termination for any reason or no reason, you continue to be bound by this Agreement (and, if you are a Service Provider, the 
                <a href="/serviceprovideragreement" class="link" target="_blank">Service Provider Agreement</a>).</p>
                
                <p class="py-3">You are solely responsible for your interactions with other Beyond Activity Users. We reserve the right, but have no obligation, to 
                monitor disputes between you and other Users. Beyond Activity shall have no liability for your interactions with other Users, or for 
                any action or inaction from users.</p></li>
            </ul>
          </li>
        <li class="py-3">2. <b style="font-weight: 600;">User Content</b>.
          <p class="py-3">Some Service allow Users to post content such as profile information, comments, questions, and other content or information (any such materials 
          a User submits, posts, displays, or otherwise makes available on the Service "User Content"). We claim no ownership rights over User Content 
          created by users. The User Content you create remains yours; however, by sharing User Content through the Service, you agree to allow others to 
          view, edit, and/or share your User Content in accordance with your settings and this Agreement (and, if you are a Service Provider, the 
          <a href="/serviceprovideragreement" class="link" target="_blank">Service Provider Agreement</a>). Beyond Activity has the right 
          (but not the obligation) in its sole discretion to remove any User Content that is shared via the Service.</p>
          
          <p class="py-3">You agree not to post User Content that: (i) may create a risk of harm, loss, physical or mental injury, emotional distress, death, disability, 
          disfigurement, or physical or mental illness to you, to any other person, or to any animal; (ii) may create a risk of any other loss or damage 
          to any person or property; (iii) seeks to harm or exploit children by exposing them to inappropriate content, asking for personally identifiable 
          details or otherwise; (iv) may constitute or contribute to a crime or tort; (v) contains any information or content that we deem to be unlawful, 
          harmful, abusive, racially or ethnically offensive, defamatory, infringing, invasive of personal privacy or publicity rights, harassing, humiliating 
          to other people (publicly or otherwise), libelous, threatening, profane, or otherwise objectionable; (vi) contains any information or content that 
          is illegal (including, without limitation, the disclosure of insider information under securities law or of another party’s trade secrets); 
          (vii) contains any information or content that you do not have a right to make available under any law or under contractual or fiduciary relationships; 
          or (viii) contains any information or content that you know is not correct and current. You agree that any User Content that you post does not 
          and will not violate third-party rights of any kind, including without limitation any Intellectual Property Rights (as defined below) or rights of privacy. 
          To the extent that your User Content contains music, you hereby represent that you are the owner of all the copyright rights, including without limitation 
          the performance, mechanical, and sound recordings rights, with respect to each and every musical composition (including lyrics) and sound recording 
          contained in such User Content and have the power to grant the license granted below. Beyond Activity reserves the right, but is not obligated, to reject 
          and/or remove any User Content that Beyond Activity believes, in its sole discretion, violates these provisions. You understand that publishing your User Content 
          on the Service is not a substitute for registering it with the U.S. Copyright Office, the Writer’s Guild of America, or any other rights organization.</p>
          
          <p class="py-3">For the purposes of this Agreement, "Intellectual Property Rights" means all patent rights, copyright rights, mask work rights, moral rights, rights of publicity, 
          trademark, trade dress and service mark rights, goodwill, trade secret rights and other intellectual property rights as may now exist or hereafter come into 
          existence, and all applications therefore and registrations, renewals and extensions thereof, under the laws of any state, country, territory or other jurisdiction.</p>
          
          <p class="py-3">In connection with your User Content, you affirm, represent and warrant the following:</p>
          <ul class="pl-8" style="list-style-type:disc">
            <li class="py-3">You have the written consent of each and every identifiable natural person in the User Content to use such person’s name or likeness in the manner 
              contemplated by the Service and this Agreement (and, if you are a Service Provider, the <a href="/serviceprovideragreement" class="link" 
              target="_blank">Service Provider Agreement</a>), and each such person has released you from any liability that may arise in relation to such use.</li>
            <li class="py-3">Your User Content and Beyond Activity’s use thereof as contemplated by this Agreement (and, if you are a Service Provider, the 
              <a href="/serviceprovideragreement" class="link" target="_blank">Service Provider Agreement</a>) and the Service will not violate any law or 
              infringe any rights of any third-party, including but not limited to any Intellectual Property Rights and privacy rights.</li>
            <li class="py-3">Beyond Activity may exercise the rights to your User Content granted under this Agreement (and, if you are a Service Provider, the 
              <a href="/serviceprovideragreement" class="link" target="_blank">Service Provider Agreement</a>) without liability for payment of any guild fees, 
              residuals, payments, fees, or royalties payable under any collective bargaining agreement or otherwise.</li>
            <li class="py-3">To the best of your knowledge, all your User Content and other information that you provide to us is truthful, complete, and accurate.</li>
          </ul>
          <p class="py-3">Beyond Activity takes no responsibility and assumes no liability for any User Content that you or any other User or third-party posts or sends over the Service. 
          You shall be solely responsible for your User Content and the consequences of posting or publishing it, and you agree that we are only acting as a passive conduit 
          for your online distribution and publication of your User Content. You understand and agree that you may be exposed to User Content that is inaccurate, objectionable, 
          inappropriate for children, or otherwise unsuited to your purpose, and you agree that Beyond Activity shall not be liable for any damages you allege to incur as a result of User Content.</p>
        </li>
        <li class="py-3">3. <b style="font-weight: 600;">User Content License Grant</b>.
          <p class="py-3">By posting any User Content on the Service, you expressly grant, and you represent and warrant that you have all rights necessary to grant, to Beyond Activity 
          a royalty-free, sublicensable, transferable, perpetual, irrevocable, non-exclusive, worldwide license to use, reproduce, modify, publish, list information regarding, 
          edit, translate, distribute, syndicate, publicly perform, publicly display, and make derivative works of all such User Content and your name, voice, and/or likeness 
          as contained in your User Content, in whole or in part, and in any form, media or technology, whether now known or hereafter developed, for use in connection with 
          the Service and Beyond Activity’s (and its successors’ and affiliates’) business, including without limitation for promoting and redistributing part or all of the Service 
          (and derivative works thereof) in any media formats and through any media channels. You also hereby grant each User of the Service a non-exclusive license to access your 
          User Content through the Service, and to use, reproduce, distribute, display, and perform such User Content as permitted through the functionality of the Service and 
          under this Agreement (and, if you are a Service Provider, the <a href="/serviceprovideragreement" class="link" target="_blank">Service Provider Agreement</a>).</p>
        </li>
        <li class="py-3">4. <b style="font-weight: 600;">Our Proprietary Rights</b>.
          <p class="py-3">Except for your User Content, the Service and all materials therein or transferred thereby, including, without limitation, software, images, text, graphics, illustrations, 
          logos, patents, trademarks, service marks, copyrights, photographs, audio, videos, music, and User Content belonging to other Users (the "Beyond Activity Content"), and 
          all Intellectual Property Rights related thereto, are the exclusive property of Beyond Activity and its licensors (including other Users who post User Content to the Service). 
          Except as explicitly provided herein, nothing in this Agreement (or, if you are a Service Provider, the <a href="/serviceprovideragreement" class="link" target="_blank">Service Provider Agreement</a>) 
          shall be deemed to create a license in or under any such Intellectual Property Rights, and you agree not to sell, license, rent, modify, distribute, copy, reproduce, transmit, 
          publicly display, publicly perform, publish, adapt, edit or create derivative works from any Beyond Activity Content. Use of the Beyond Activity Content for any purpose not 
          expressly permitted by this Agreement (or, if you are a Service Provider, the <a href="/serviceprovideragreement" class="link" target="_blank">Service Provider Agreement</a>) 
          is strictly prohibited.</p>
          
          <p class="py-3">You may choose to or we may invite you to submit comments or ideas about the Service, including without limitation about how to improve the Service or our products (“Ideas”). 
          By submitting any Idea, you agree that your disclosure is gratuitous, unsolicited and without restriction and will not place Beyond Activity under any fiduciary or other obligation, 
          and that we are free to use the Idea without any additional compensation to you, and/or to disclose the Idea on a non-confidential basis or otherwise to anyone. You further 
          acknowledge that, by acceptance of your submission, Beyond Activity does not waive any rights to use similar or related ideas previously known to Beyond Activity, or developed by 
          its employees, or obtained from sources other than you.</p>
        </li>
        <li class="py-3">5. <b style="font-weight: 600;">Payments to Service Providers</b></li>
        <ul class="pl-8" style="list-style-type:disc">
          <li class="py-3"><b style="font-weight: 600;">Payments to Service Providers</b>. When you make a reservation and/or enroll with a Service Provider through the Service, you agree to be bound by and to pay for that transaction, 
            and, unless you properly request and receive a refund from a Service Provider, you must pay for the activity you register for or enroll in. You authorize us to charge the full 
            amount to your chosen payment provider for the transaction. In the event of a pricing error, where the price on Beyond Activity is lower than authorized by the Service Provider, 
            you will be invoiced for the difference or the reservation will be cancelled and your payment will be refunded.
          </li>
          <li class="py-3"><b style="font-weight: 600;">Refunds and Credits</b>. Your ability to cancel a reservation or enrollment, or to obtain refunds or credits, is determined by the Service Provider. Referral fees and credit card processing 
            fees charged by Beyond Activity are non-refundable. Service Providers may modify class schedules at any time or cancel listings if, in their sole discretion, enrollment for the class is 
            insufficient. Refunds or credits for modified or canceled listings are determined by the Service Provider. Please contact the appropriate Service Provider directly to discuss their policies 
            on refunds and cancellations.
          </li>
          <li class="py-3"><b style="font-weight: 600;">Payment Information; Taxes</b>. All information that you provide in connection with a purchase or transaction or other monetary transaction interaction with the Service must be accurate, complete, 
            and current. You agree to pay all charges incurred by users of your credit card, debit card, or other payment method used in connection with a purchase or transaction or other monetary 
            transaction interaction with the Service at the prices in effect when such charges are incurred. You will pay any applicable taxes, if any, relating to any such purchases, transactions or 
            other monetary transaction interactions.
          </li>
          <li class="py-3"><b style="font-weight: 600;">California Residents</b>. The provider of services is set forth herein. If you are a California resident, in accordance with Cal. Civ. Code §1789.3, you may report complaints to the Complaint 
            Assistance Unit of the Division of Consumer Services of the California Department of Consumer Affairs by contacting them in writing at 1625 North Market Blvd., Suite N 112 Sacramento, CA 95834, 
            or by telephone at (800) 952-5210 or (916) 445-1254.
          </li>
        </ul>
        <li class="py-3">6. <b style="font-weight: 600;">Privacy</b>
          <p class="py-3">We care about the privacy of our Users. You understand that by using the Services you consent to the collection, use, and disclosure of your personally identifiable information and aggregate data 
          as set forth in our <a href="/privacypolicy" class="link" target="_blank">Privacy Policy</a>, and to have your personally identifiable information collected, used, transferred to and processed in 
          the United States.</p>
        </li>
        <li class="py-3">7. <b style="font-weight: 600;">Security</b>
          <p class="py-3">Beyond Activity cares about the integrity and security of your personal information. However, we cannot guarantee that unauthorized third-parties will never be able to defeat our security measures 
          or use your personal information for improper purposes. You acknowledge that you provide your personal information at your own risk.</p>
        </li>
        <li class="py-3">8. <b style="font-weight: 600;">DMCA Notice</b>
          <p class="py-3">Since we respect artist and content owner rights, it is the policy at Beyond Activity to respond to alleged infringement notices that comply with the Digital Millennium Copyright Act of 1998 ("DMCA").</p>
          
          <p class="py-3">If you believe that your copyrighted work has been copied in a way that constitutes copyright infringement and is accessible via the Service, please notify us at BeyondActivityBA@gmail.com as set forth 
          in the DMCA. For your complaint to be valid under the DMCA, you must provide the following information in writing:</p>
          <ul class="pl-8" style="list-style-type:disc">
            <li class="py-3">An electronic or physical signature of a person authorized to act on behalf of the copyright owner;</li>
            <li class="py-3">Identification of the copyrighted work that you claim has been infringed;</li>
            <li class="py-3">Identification of the material that is claimed to be infringing and where it is located on the Service;</li>
            <li class="py-3">Information reasonably sufficient to permit Beyond Activity to contact you, such as your address, telephone number, and, e-mail address;</li>
            <li class="py-3">A statement that you have a good faith belief that use of the material in the manner complained of is not authorized by the copyright owner, its agent, or law; and</li>
            <li class="py-3">A statement, made under penalty of perjury, that the above information is accurate, and that you are the copyright owner or are authorized to act on behalf of the owner.</li>
          </ul>
          <p class="py-3">UNDER FEDERAL LAW, IF YOU KNOWINGLY MISREPRESENT THAT ONLINE MATERIAL IS INFRINGING, YOU MAY BE SUBJECT TO CRIMINAL PROSECUTION FOR PERJURY AND CIVIL PENALTIES, INCLUDING MONETARY 
          DAMAGES, COURT COSTS, AND ATTORNEYS’ FEES.</p>
          
          <p class="py-3">Please note that this procedure is exclusively for notifying Beyond Activity and its affiliates that your copyrighted material has been infringed. The preceding requirements are 
          intended to comply with Beyond Activity’s rights and obligations under the DMCA, including 17 U.S.C. §512(c), but do not constitute legal advice. It may be advisable to contact 
          an attorney regarding your rights and obligations under the DMCA and other applicable laws.</p>
          
          <p class="py-3">In accordance with the DMCA and other applicable law, Beyond Activity has adopted a policy of terminating, in appropriate circumstances, Users who are deemed to be repeat infringers. 
          Beyond Activity may also at its sole discretion limit access to the Service and/or terminate the accounts of any Users who infringe any intellectual property rights of others, whether 
          or not there is any repeat infringement.</p>
        </li>
        <li class="py-3">9. <b style="font-weight: 600;">Third-Party Links</b>.
          <p class="py-3">The Service may contain links to third-party websites, advertisers, services, special offers, or other events or activities that are not owned or controlled by Beyond Activity. 
          Beyond Activity does not endorse or assume any responsibility for any such third-party sites, information, materials, products, or services. If you access a third-party website 
          from the Service, you do so at your own risk, and you understand that this Agreement, the <a href="/serviceprovideragreement" class="link" target="_blank">Service Provider Agreement</a>, 
          and the <a href="/privacypolicy" class="link" target="_blank">Privacy Policy</a> do not apply to your use of such sites. You expressly relieve Beyond Activity from any and all liability 
          arising from your use of any third-party website, service, or content. Additionally, your dealings with or participation in promotions of advertisers found on the Service, including 
          payment and delivery of goods, and any other terms (such as warranties) are solely between you and such advertisers. You agree that Beyond Activity shall not be responsible for any loss 
          or damage of any sort relating to your dealings with such advertisers.</p>
        </li>
        <li class="py-3">10. <b style="font-weight: 600;">Indemnity</b>.
          <p class="py-3">You agree to defend, indemnify and hold harmless Beyond Activity and its subsidiaries, agents, licensors, managers, and other affiliated companies, and their employees, contractors, agents, 
          officers and directors, from and against any and all claims, damages, obligations, losses, liabilities, costs or debt, and expenses (including but not limited to attorney’s fees) arising from: 
          (i) your use of and access to the Service, including any data or content transmitted or received by you; (ii) your violation of any term of this Agreement, the Privacy Policy, or, if you are a 
          Service Provider, the <a href="/serviceprovideragreement" class="link" target="_blank">Service Provider Agreement</a>, including without limitation your breach of any of the representations and 
          warranties above; (iii) your violation of any third-party right, including without limitation any right of privacy or Intellectual Property Rights; (iv) your violation of any applicable law, 
          rule or regulation; (v) any claim or damages that arise as a result of any of your User Content or any that is submitted via your account; or (vi) any other party’s access and use of the Service 
          with your unique username, password or other appropriate security code.</p>
        </li>
        <li class="py-3">11. <b style="font-weight: 600;">No Warranty</b>.
          <p class="py-3">THE SERVICE IS PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS. USE OF THE SERVICE IS AT YOUR OWN RISK. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE SERVICE IS PROVIDED WITHOUT WARRANTIES 
          OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT. WITHOUT LIMITING THE FOREGOING, 
          BEYOND ACTIVITY, ITS SUBSIDIARIES, ITS AFFILIATES, AND ITS LICENSORS DO NOT WARRANT THAT THE CONTENT IS ACCURATE, RELIABLE OR CORRECT; THAT THE SERVICE WILL MEET YOUR REQUIREMENTS; THAT THE SERVICE WILL 
          BE AVAILABLE AT ANY PARTICULAR TIME OR LOCATION, UNINTERRUPTED OR SECURE; THAT ANY DEFECTS OR ERRORS WILL BE CORRECTED; OR THAT THE SERVICE IS FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. ANY CONTENT 
          DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THE SERVICE IS DOWNLOADED AT YOUR OWN RISK AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR MOBILE DEVICE OR LOSS OF DATA 
          THAT RESULTS FROM SUCH DOWNLOAD OR YOUR USE OF THE SERVICE.</p>
          
          <p class="py-3">BEYOND ACTIVITY DOES NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A THIRD-PARTY THROUGH THE BEYOND ACTIVITY SERVICE OR ANY HYPERLINKED 
          WEBSITE OR SERVICE, AND BEYOND ACTIVITY WILL NOT BE A PARTY TO OR IN ANY WAY MONITOR ANY TRANSACTION BETWEEN YOU AND THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES.</p>
          
          <p class="py-3">FEDERAL LAW, SOME STATES, PROVINCES AND OTHER JURISDICTIONS DO NOT ALLOW EXCLUSIONS AND LIMITATIONS OF CERTAIN IMPLIED WARRANTIES, SO SOME OF THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU.</p></li>
        <li class="py-3">12. <b style="font-weight: 600;">Limitation of Liability</b>.
          <p class="py-3">TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL BEYOND ACTIVITY, ITS AFFILIATES, AGENTS, DIRECTORS, EMPLOYEES, SUPPLIERS OR LICENSORS BE LIABLE FOR ANY INDIRECT, PUNITIVE, INCIDENTAL, 
          SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES, INCLUDING WITHOUT LIMITATION DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA OR OTHER INTANGIBLE LOSSES, THAT RESULT FROM THE USE OF, OR INABILITY TO USE, 
          THIS SERVICE. UNDER NO CIRCUMSTANCES WILL BEYOND ACTIVITY BE RESPONSIBLE FOR ANY DAMAGE, LOSS OR INJURY RESULTING FROM HACKING, TAMPERING OR OTHER UNAUTHORIZED ACCESS OR USE OF THE SERVICE OR YOUR ACCOUNT 
          OR THE INFORMATION CONTAINED THEREIN.</p>
          
          <p class="py-3">TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, BEYOND ACTIVITY ASSUMES NO LIABILITY OR RESPONSIBILITY FOR ANY (I) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT; (II) PERSONAL INJURY OR PROPERTY DAMAGE, 
          OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO OR USE OF OUR SERVICE; (III) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION STORED THEREIN; (IV) ANY 
          INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE SERVICE; (V) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE THAT MAY BE TRANSMITTED TO OR THROUGH OUR SERVICE BY ANY THIRD-PARTY; (VI) ANY ERRORS OR 
          OMISSIONS IN ANY CONTENT OR FOR ANY LOSS OR DAMAGE INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED, EMAILED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE THROUGH THE SERVICE; AND/OR (VII) USER CONTENT OR 
          THE DEFAMATORY, OFFENSIVE, OR ILLEGAL CONDUCT OF ANY THIRD-PARTY. IN NO EVENT SHALL BEYOND ACTIVITY, ITS AFFILIATES, AGENTS, DIRECTORS, EMPLOYEES, SUPPLIERS, OR LICENSORS BE LIABLE TO YOU FOR ANY CLAIMS, 
          PROCEEDINGS, LIABILITIES, OBLIGATIONS, DAMAGES, LOSSES OR COSTS IN AN AMOUNT EXCEEDING THE AMOUNT YOU PAID TO BEYOND ACTIVITY HEREUNDER OR $100.00, WHICHEVER IS GREATER.</p>
          
          <p class="py-3">THIS LIMITATION OF LIABILITY SECTION APPLIES WHETHER THE ALLEGED LIABILITY IS BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY, OR ANY OTHER BASIS, EVEN IF BEYOND ACTIVITY HAS BEEN ADVISED OF THE POSSIBILITY 
          OF SUCH DAMAGE. THE FOREGOING LIMITATION OF LIABILITY SHALL APPLY TO THE FULLEST EXTENT PERMITTED BY LAW IN THE APPLICABLE JURISDICTION.</p>
          
          <p class="py-3">SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATIONS OR EXCLUSIONS MAY NOT APPLY TO YOU. THIS AGREEMENT GIVES YOU SPECIFIC LEGAL RIGHTS, AND YOU 
          MAY ALSO HAVE OTHER RIGHTS WHICH VARY FROM STATE TO STATE. THE DISCLAIMERS, EXCLUSIONS, AND LIMITATIONS OF LIABILITY UNDER THIS AGREEMENT WILL NOT APPLY TO THE EXTENT PROHIBITED BY APPLICABLE LAW.</p>
          
          <p class="py-3">The Service is controlled and operated from facilities in the United States. Beyond Activity makes no representations that the Service is appropriate or available for use in other locations. Those who access or 
          use the Service from other jurisdictions do so at their own volition and are entirely responsible for compliance with all applicable United States and local laws and regulations, including but not limited to export 
          and import regulations. You may not use the Service if you are a resident of a country embargoed by the United States, or are a foreign person or entity blocked or denied by the United States government. Unless otherwise 
          explicitly stated, all materials found on the Service are solely directed to individuals, companies, or other entities located in the United States.</p>
        </li>
        <li class="py-3">13. <b style="font-weight: 600;">Governing Law, Arbitration, and Class Action/Jury Trial Waiver</b>.
          <p class="py-3"><b style="font-weight: 600;">Governing Law</b>. You agree that: (i) the Service shall be deemed solely based in California; and (ii) the Service shall be deemed a passive one that does not give rise to personal jurisdiction over us, either specific 
          or general, in jurisdictions other than California. This Agreement, the <a href="/privacypolicy" class="link" target="_blank">Privacy Policy</a>, and the <a href="/serviceprovideragreement" class="link" target="_blank">Service Provider Agreement</a> 
          shall be governed by the internal substantive laws of the State of California, without respect to its conflict of laws principles. The parties acknowledge that this Agreement and the <a href="/serviceprovideragreement" class="link" target="_blank">Service Provider Agreement</a> 
          evidence a transaction involving interstate commerce. Notwithstanding the preceding sentences with respect to the substantive law, any arbitration conducted pursuant to the terms of this Agreement shall be governed 
          by the Federal Arbitration Act (9 U.S.C. §§ 1-16). The application of the United Nations Convention on Contracts for the International Sale of Goods is expressly excluded. You agree to submit to the personal jurisdiction 
          of the federal and state courts located in Santa Clara County, California for any actions for which we retain the right to seek injunctive or other equitable relief in a court of competent jurisdiction to prevent the actual 
          or threatened infringement, misappropriation or violation of a our copyrights, trademarks, trade secrets, patents, or other intellectual property or proprietary rights, as set forth in the Arbitration provision below, including 
          any provisional relief required to prevent irreparable harm. You agree that Santa Clara County, California is the proper forum for any appeals of an arbitration award or for trial court proceedings in the event that the arbitration 
          provision below is found to be unenforceable.</p>
          
          <p class="py-3"><b style="font-weight: 600;">Arbitration</b>. READ THIS SECTION CAREFULLY BECAUSE IT REQUIRES THE PARTIES TO ARBITRATE THEIR DISPUTES AND LIMITS THE MANNER IN WHICH YOU CAN SEEK RELIEF FROM BEYOND ACTIVITY. For any dispute with Beyond Activity, you agree to first contact 
          us at BeyondActivityBA@gmail.com and attempt to resolve the dispute with us informally. In the unlikely event that Beyond Activity has not been able to resolve a dispute it has with you after sixty (60) days, we each agree to resolve 
          any claim, dispute, or controversy (excluding any claims for injunctive or other equitable relief as provided below) arising out of or in connection with or relating to this Agreement, the <a href="/privacypolicy" class="link" target="_blank">Privacy Policy</a>, 
          and the <a href="/serviceprovideragreement" class="link" target="_blank">Service Provider Agreement</a> , or the breach or alleged breach thereof (collectively, "Claims"). The arbitration will be conducted in Santa Clara County, California, 
          unless you and Beyond Activity agree otherwise. If you are using the Service for commercial purposes, each party will be responsible for paying any filing, administrative and arbitrator fees in accordance with related rules, and the award 
          rendered by the arbitrator shall include costs of arbitration, reasonable attorneys’ fees and reasonable costs for expert and other witnesses. If you are an individual using the Service for non-commercial purposes: (i) the rules may require 
          you to pay a fee for the initiation of your case, unless you apply for and successfully obtain a fee waiver; (ii) the award rendered by the arbitrator may include your costs of arbitration, your reasonable attorney’s fees, and your reasonable 
          costs for expert and other witnesses; and (iii) you may sue in a small claims court of competent jurisdiction without first engaging in arbitration, but this does not absolve you of your commitment to engage in the informal dispute resolution 
          process. Any judgment on the award rendered by the arbitrator may be entered in any court of competent jurisdiction. Nothing in this Section shall be deemed as preventing Beyond Activity from seeking injunctive or other equitable relief from 
          the courts as necessary to prevent the actual or threatened infringement, misappropriation, or violation of our data security, Intellectual Property Rights or other proprietary rights.</p>
          
          <p class="py-3"><b style="font-weight: 600;">Class Action/Jury Trial Waiver</b>. WITH RESPECT TO ALL PERSONS AND ENTITIES, REGARDLESS OF WHETHER THEY HAVE OBTAINED OR USED THE SERVICE FOR PERSONAL, COMMERCIAL OR OTHER PURPOSES, ALL CLAIMS MUST BE BROUGHT IN THE PARTIES’ INDIVIDUAL CAPACITY, 
          AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS ACTION, COLLECTIVE ACTION, PRIVATE ATTORNEY GENERAL ACTION OR OTHER REPRESENTATIVE PROCEEDING. THIS WAIVER APPLIES TO CLASS ARBITRATION, AND, UNLESS WE AGREE OTHERWISE, THE ARBITRATOR 
          MAY NOT CONSOLIDATE MORE THAN ONE PERSON’S CLAIMS. YOU AGREE THAT, BY ENTERING INTO THIS AGREEMENT, YOU AND BEYOND ACTIVITY ARE EACH WAIVING THE RIGHT TO A TRIAL BY JURY OR TO PARTICIPATE IN A CLASS ACTION, COLLECTIVE ACTION, PRIVATE ATTORNEY 
          GENERAL ACTION, OR OTHER REPRESENTATIVE PROCEEDING OF ANY KIND.</p></li>
        <li class="py-3">14. <b style="font-weight: 600;">General</b>.
          <b style="font-weight: 600;">Assignment</b>. This Agreement, the <a href="/privacypolicy" class="link" target="_blank">Privacy Policy</a>, and the <a href="/serviceprovideragreement" class="link" target="_blank">Service Provider Agreement</a>, and any rights and licenses granted 
          hereunder or thereunder, may not be transferred or assigned by you, but may be assigned by Beyond Activity without restriction. Any attempted transfer or assignment in violation hereof shall be null and void.
          
          <p class="py-3"><b style="font-weight: 600;">Notification Procedures and Changes to the Agreement</b>. Beyond Activity may provide notifications, whether such notifications are required by law or are for marketing or other business related purposes, to you via email notice, written or hard copy 
          notice, or through posting of such notice on our website, as determined by Beyond Activity in our sole discretion. Beyond Activity reserves the right to determine the form and means of providing notifications to our Users, provided that you may opt 
          out of certain means of notification as described in this Agreement. Beyond Activity is not responsible for any automatic filtering you or your network provider may apply to email notifications we send to the email address you provide us. Beyond Activity 
          may, in its sole discretion, modify or update this Agreement, the <a href="/privacypolicy" class="link" target="_blank">Privacy Policy</a>, or the <a href="/serviceprovideragreement" class="link" target="_blank">Service Provider Agreement</a> from time to time, 
          and so you should review this page periodically. When we change any of these agreements in a material manner, we will update the ‘last modified’ date at the top of this page. Your continued use of the Service after any such change constitutes your acceptance of 
          the new terms of use. If you do not agree to any of these terms or any future terms of use, do not use or access (or continue to access) the Service.</p>
          
          <p class="py-3"><b style="font-weight: 600;">Entire Agreement/Severability</b>. This Agreement, together with any amendments and any additional agreements you may enter into with Beyond Activity in connection with the Service, including the <a href="/privacypolicy" class="link" target="_blank">Privacy Policy</a> 
          and, if applicable, the <a href="/serviceprovideragreement" class="link" target="_blank">Service Provider Agreement</a>, shall constitute the entire agreement between you and Beyond Activity concerning the Service. If any provision of this Agreement is deemed 
          invalid by a court of competent jurisdiction, the invalidity of such provision shall not affect the validity of the remaining provisions of this Agreement, which shall remain in full force and effect, except that in the event of unenforceability of the universal 
          Class Action/Jury Trial Waiver, the entire arbitration agreement shall be unenforceable.</p>
          
          <p class="py-3"><b style="font-weight: 600;">No Waiver</b>. No waiver of any term of this Agreement, the <a href="/privacypolicy" class="link" target="_blank">Privacy Policy</a>, or the <a href="/serviceprovideragreement" class="link" target="_blank">Service Provider Agreement</a> shall be deemed a further or 
          continuing waiver of such term or any other term, and failure at Beyond Activity to assert any right or provision under any of these agreements shall not constitute a waiver of such right or provision.</p>
          
          <p class="py-3"><b style="font-weight: 600;">Contact</b>. Please contact us at BeyondActivityBA@gmail.com with any questions regarding this Agreement, the <a href="/privacypolicy" class="link" target="_blank">Privacy Policy</a>, or the <a href="/serviceprovideragreement" class="link" target="_blank">Service Provider Agreement</a>.</p></li>
      </ul>
      </v-col></v-row></v-container>
    </div>
  </CommonPageFramework>
</template>
          
<script>
import { defineComponent } from 'vue';
          
// Components
import CommonPageFramework from '@/components/layout/CommonPageFramework.vue'
    
export default defineComponent({
  name: 'PrivacyPolicyView',

  components: {
    CommonPageFramework,
  },
});
</script>
  
<style lang="scss" scoped>  
  .ba-title {
    color: #2b0101;
    font-weight: 600;
    font-family: "Work Sans";
    padding-top: 0rem;
    padding-bottom: 0rem;
    letter-spacing: 0rem;
    line-height: 1.25;
    
    font-size: 2.25rem;
    white-space: pre-line;
    margin-bottom: 0rem;
    margin-top: 0rem;
    display: block;
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }
  
  .ba-paragraph {
    color: #2b0101;
    font-weight: 300;
    font-family: "Work Sans";
    padding-top: 0rem;
    padding-bottom: 0.25rem;
    letter-spacing: 0rem;
    line-height: 1;
    
    font-size: 1.25rem;
    white-space: pre-line;
    margin-bottom: 0rem;
    margin-top: 0rem;
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }
  
  .ba-updated {
    color: #2b0101;
    font-weight: 300;
    font-family: "Work Sans";
    padding-top: 0rem;
    padding-bottom: 0rem;
    letter-spacing: 0rem;
    line-height: 1;
    
    font-size: 1.25rem;
    white-space: pre-line;
    margin-bottom: 0rem;
    margin-top: 0rem;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }
  
  .ba-note {
    color: #2b0101;
    font-weight: 200;
    font-family: "Work Sans";
    padding-top: 0rem;
    padding-bottom: 0.25rem;
    letter-spacing: 0rem;
    line-height: 1;
    
    font-size: 1rem;
    white-space: pre-line;
    margin-bottom: 0rem;
    margin-top: 0rem;
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }
  
  .link {
    font-family: sans-serif;
    letter-spacing: 0rem;
    font-weight: 600;
    font-size: 1rem;
    color: #000;
    text-decoration: none;
    background-color: transparent;
    cursor: point;
    list-style: none;
    white-space: nowrap;
  }


@media only screen and (max-width: 960px) {
  .ba-title {
    color: #2b0101;
    font-weight: 600;
    font-family: "Work Sans";
    padding-top: 0rem;
    padding-bottom: 0rem;
    letter-spacing: 0rem;
    line-height: 1;
    
    font-size: 1.5rem;
    white-space: pre-line;
    margin-bottom: 0rem;
    margin-top: 0rem;
    display: block;
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }
  
  .ba-paragraph {
    color: #2b0101;
    font-weight: 300;
    font-family: "Work Sans";
    padding-top: 0rem;
    padding-bottom: 0.25rem;
    letter-spacing: 0rem;
    line-height: 1;
    
    font-size: 0.75rem;
    white-space: pre-line;
    margin-bottom: 0rem;
    margin-top: 0rem;
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }
  
  .ba-updated {
    color: #2b0101;
    font-weight: 300;
    font-family: "Work Sans";
    padding-top: 0rem;
    padding-bottom: 0rem;
    letter-spacing: 0rem;
    line-height: 1;
    
    font-size: 0.75rem;
    white-space: pre-line;
    margin-bottom: 0rem;
    margin-top: 0rem;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }
  
  .ba-note {
    color: #2b0101;
    font-weight: 200;
    font-family: "Work Sans";
    padding-top: 0rem;
    padding-bottom: 0.25rem;
    letter-spacing: 0rem;
    line-height: 1;
    
    font-size: 0.5rem;
    white-space: pre-line;
    margin-bottom: 0rem;
    margin-top: 0rem;
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }
  
  .link {
    font-family: sans-serif;
    letter-spacing: 0rem;
    font-weight: 600;
    font-size: 0.75rem;
    color: #000;
    text-decoration: none;
    background-color: transparent;
    cursor: point;
    list-style: none;
    white-space: nowrap;
  }
}
</style>
          