<template>
<v-container>
  <v-row class="text-center py-2" justify="space-around" no-gutters>
    <v-col>
      <v-text-field v-model="userName" hide-details="auto" disabled></v-text-field>
    </v-col>
  </v-row>
  <v-row class="text-center py-2" justify="space-around" no-gutters>
    <v-col>
      <v-text-field v-model="userEmail" hide-details="auto" disabled></v-text-field>
    </v-col>
  </v-row>
  <v-row class="text-center pt-8" justify="space-around" no-gutters>
    <v-col>
      <v-text-field label="Password" type="password" v-model="password" hide-details="auto"></v-text-field>
    </v-col>
  </v-row>
  
  <v-row class="text-center py-2" justify="space-around" no-gutters>
    <v-col>
      <v-btn class="save-password-btn" @click="savePassword">Save Password</v-btn>
    </v-col>
  </v-row>
</v-container>
</template>

<script>
//import Service from '@/service.js'
import common from '@/assets/mixins/common.js';

export default {
  name: 'ba-user-profile',
  mixins: [common],
  data: () => ({
    userName: null,
    userEmail: null,
    password: null,
    isLoading: false,
  }),
  computed: {
    getLoading() {
      return this.isLoading;
    }
  },
  methods: {
    savePassword() {

    }
  },
  mounted () {
    this.userName = this.getUserName;
    this.userEmail = this.getUserEmail;
  }
}
</script>

<style lang="scss" scoped>
.save-password-btn {
  font-family: "Work Sans";
  background-color: #460303;
  color: #ffffff;
  letter-spacing: 0rem;
  font-weight: 600;
  display: inline-block;
  font-size: 1.25rem;
  text-decoration: none;
  border-radius: 0.5rem;
  cursor: pointer;
  text-align: left;
  text-transform: none;
  margin-top: 1.5rem;
}
</style>
    