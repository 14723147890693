<template>
<CommonPageFramework>
  <div :style="{'min-height': mainBodyHeight}">
    <v-container fill-height>
      <v-row v-if="$vuetify.display.mdAndUp" justify="space-around" no-gutters>
        <v-col class="v-col-6">
          <v-container>
            <v-row>
              <v-col>
                <p class="aboutOurCompany text-left">{{ $t('aboutview_title') }}</p>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <p class="aboutOurCompanyText text-left">{{ $t('aboutview_abouttext') }}</p>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-container>
                  <v-row class="py-4">
                    <v-col class="v-col-2"><font-awesome-icon class="aboutIcon aboutIconColor1 py-1" icon="fa-solid fa-right-left" /></v-col>
                    <v-col class="v-col-10">
                      <p class="aboutTitle">{{ $t('aboutview_ourvision') }}</p>
                      <p class="aboutText">{{ $t('aboutview_visiontext') }}</p>
                    </v-col>
                  </v-row>
                  <v-row class="py-4">
                    <v-col class="v-col-2"><font-awesome-icon  class="aboutIcon aboutIconColor2 py-1" icon="fas fa-check-double fa-fw"/></v-col>
                    <v-col class="v-col-10">
                      <p class="aboutTitle">{{ $t('aboutview_ourmission') }}</p>
                      <p class="aboutText">{{ $t('aboutview_missiontext') }}</p>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
        <v-col class="v-col-6">
          <v-container class="fill-height">
            <v-row class="fill-height" align="center" justify="center">
              <v-col>
                <v-img :src="virtualMeetingImg"></v-img>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col class="v-col-10 offset-1">
        <v-container>
          <v-row>
            <v-col><p class="aboutOurCompany text-center">{{ $t('aboutview_title') }}</p></v-col>
          </v-row>
          <v-row>
            <v-col>
              <p class="aboutOurCompanyText text-center">{{ $t('aboutview_abouttext') }}</p>
            </v-col>
          </v-row>
          <v-row class="py-4">
            <v-col class="v-col-2 text-right"><font-awesome-icon class="aboutIcon aboutIconColor1 py-1" icon="fa-solid fa-right-left" /></v-col>
              <v-col class="v-col-10">
                <p class="aboutTitle">{{ $t('aboutview_ourvision') }}</p>
                <p class="aboutText">{{ $t('aboutview_visiontext') }}</p>
              </v-col>
            </v-row>
            <v-row class="py-4">
              <v-col class="v-col-2 text-right"><font-awesome-icon  class="aboutIcon aboutIconColor2 py-1" icon="fas fa-check-double fa-fw"/></v-col>
              <v-col class="v-col-10">
                <p class="aboutTitle">{{ $t('aboutview_ourmission') }}</p>
                <p class="aboutText">{{ $t('aboutview_missiontext') }}</p>
              </v-col>
            </v-row>
            <v-row class="py-8" justify="center">
              <v-col class="v-col-10 offset-1">
                <v-img :src="virtualMeetingImg"></v-img>
              </v-col>
            </v-row>
        </v-container>
      </v-col>
      </v-row>
    </v-container>
  </div>
</CommonPageFramework>
</template>
  
<script>
import { defineComponent } from 'vue';
  
// Components
import CommonPageFramework from '@/components/layout/CommonPageFramework.vue'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
  
import virtualMeetingImg from "../assets/virtual-team-meeting.svg";
  
export default defineComponent({
    name: 'AboutView',
    components: {
      CommonPageFramework,
      FontAwesomeIcon,
    },
    data() {
      return {
        virtualMeetingImg: virtualMeetingImg,
      }
    }
  });
</script>
  
<style lang="scss" scoped>
.aboutOurCompany {
  color: #2b0101;
  font-weight: 600;
  font-family: "Work Sans";
  letter-spacing: 0rem;
  line-height: 1.5;
  font-size: 2.25rem;
  padding-top: 0rem;
  padding-bottom: 1rem;
  white-space: pre-line;
  margin-top: 0;
  margin-bottom: 0.5rem;
  display: block;
}

.aboutOurCompanyText {
  color: #2b0101;
  font-weight: 300;
  font-family: "Work Sans";
  letter-spacing: 0rem;
  line-height: 1.5;
  font-size: 1.25rem;
  padding-top: 0rem;
  padding-bottom: 1.25rem;
  white-space: pre-line;
  margin-top: 0;
  margin-bottom: 1rem;
  display: block;
}

.aboutIcon {
  width: 45px;
  height: 36px;
  font-size: 2.25rem;
  font-weight: 900;
}

.aboutIconColor1 {
  color: #2977c9;
}

.aboutIconColor2 {
  color: #979998;
}

.aboutTitle {
  font-family: "Work Sans";
  color: #2b0101;
  letter-spacing: 0rem;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 1.2;
  display: block;
  text-align: left;
}

.aboutText {
  font-family: "Work Sans";
  color: #2b0101;
  letter-spacing: 0rem;
  font-weight: 300;
  font-size: 1rem;
  line-height: 1.5;
  display: block;
  text-align: left;
  white-space: pre-line;
}
</style>
    