import adminService from '@/service/admin_service.js'
import stripeService from '@/service/stripe_service.js'
import userMgrService from '@/service/user_mgr_service.js'
import courseMgrService from '@/service/course_mgr_service.js'
import paymentMgrService from '@/service/payment_mgr_service.js'
import miscService from '@/service/misc.js'
import localizationService from '@/service/localization.js'

// Service Facade
class Service {
  constructor() {
  }
}

Object.assign(Service.prototype, adminService);
Object.assign(Service.prototype, stripeService);
Object.assign(Service.prototype, userMgrService);
Object.assign(Service.prototype, courseMgrService);
Object.assign(Service.prototype, paymentMgrService);
Object.assign(Service.prototype, miscService);
Object.assign(Service.prototype, localizationService);

export default Service