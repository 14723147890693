<template>
<CommonPageFramework>
  <div class="text-row">
    <v-container>
      <v-row justify="space-around" no-gutters>
        <v-col class="v-col-11">
          <h1 v-if="$vuetify.display.smAndUp" class="whatToLearn px-4">{{ $t('allcourseview_subtitle_1') }}</h1>
          <h1 v-else class="whatToLearn text-center">{{ $t('allcourseview_subtitle_1') }}</h1>
        </v-col>
      </v-row>
      <v-row justify="space-around" no-gutters>
        <v-col>
          <v-container>
            <v-row v-if="$vuetify.display.mdAndUp" >
              <v-col class="text-center v-col-3">
                <div v-if="false" class="d-flex flex-column">
                  <p class="categories pl-5">{{ $t('allcourseview_allcategories') }}</p>
                  <label style="display: block;" :class = "{ 'category-btn': true, 'category-btn-unselected': !x.selected, 'category-btn-selected': x.selected }" v-for="(x, index) in filters" :key="x.name" @click="select(index)">{{x.name}}</label>
                </div>
              </v-col>
              <v-col>
                <v-container>
                  <v-row>
                    <v-col v-for="c in filteredCourses" :key="c.id" class="v-col-sm-6 v-col-md-6 v-col-lg-4">
                      <div :class="{'course-up-half': c.url != null}"  @click="goto(c.url)">
                        <div>
                          <v-img class="course-img" :src="c.imageHead">
                            <span class="course-tag">{{c.price}}</span>
                          </v-img>
                          <p class="course-title">{{c.title}}</p>
                        </div>
                      </div>
                      <div>
                        <h3 class="course-author">{{c.author}}</h3>
                        <v-divider></v-divider>
                        <p class="course-length">{{c.length}}</p>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
            </v-row>
            <v-row v-else>
              <v-container>
                <v-row v-if="false">
                  <v-col class="text-center">
                    <p class="categories pl-5">{{ $t('allcourseview_allcategories') }}</p>
                  </v-col>
                </v-row>
                <v-row v-if="false">
                  <v-col :class="{'v-col-12': $vuetify.display.xs}">
                    <div style="display: inline-block;" class="py-2">
                      <label :class = "{ 'category-btn': true, 'category-btn-unselected': !x.selected, 'category-btn-selected': x.selected }" v-for="(x, index) in filters" :key="x.name" @click="select(index)">{{x.name}}</label>
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col v-for="c in filteredCourses" :key="c.id" :class="{'v-col-12': $vuetify.display.xs, 'v-col-5': $vuetify.display.sm}">
                    <div :class="{'course-up-half': c.url != null, 'px-4': true}" @click="goto(c.url)">
                        <div>
                          <v-img class="course-img" :src="c.imageHead">
                            <span class="course-tag">{{c.price}}</span>
                          </v-img>
                          <p class="course-title">{{c.title}}</p>
                        </div>
                      </div>
                      <div>
                        <h3 class="course-author">{{c.author}}</h3>
                        <v-divider></v-divider>
                        <p class="course-length">{{c.length}}</p>
                      </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </div>
</CommonPageFramework>
</template>

<script>
import { defineComponent } from 'vue';

// Components
import CommonPageFramework from '@/components/layout/CommonPageFramework.vue'

import router from '@/router';

export default defineComponent({
  name: 'HomeView',

  components: {
    CommonPageFramework,
  },
  computed: {
    selectedFilter() {
      return this.filters.filter(x => x.selected == true).map(x => x.name)
    },
    filteredCourses() {
      // if the filter is empty, return all courses;
      if (this.selectedFilter.length == 0) {
        return this.$store.getters.getAllCourses;
      }

      // otherwise, apply filters
      return this.$store.getters.getAllCourses.filter(x => 
        // intersection of two arrays
        this.selectedFilter.some(y => x.categories.includes(y))
      )
    },
    courses() {
      return this.$store.getters.getAllCourses
    }
  },
  methods: {
    select: function (index) {
      if (this.filters[index].selected) {
        // already selected, unselect the item
        this.filters[index].selected = false;
      } else {
        this.filters.forEach(x => x.selected = false);
        this.filters[index].selected = true;
      }
    },
    goto: function(url) {
      if (url != null) {
        router.push(url)
      }
    }
  },
  data() {
    return {
      filters: [ {
            name: 'Development',
            selected: false
        }, {
            name: 'Design',
            selected: false
        }, {
            name: 'Photography',
            selected: false
        }, {
            name: 'Marketing',
            selected: false
        }],
    }
  }
});
</script>

<style lang="scss" scoped>

.text-row {
  background-attachment: scroll;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-bottom-color: #000;
  border-bottom-width: 1px;
  border-bottom-style: none;
  border-top-color: #000;
  border-top-width: 1px;
  border-top-style: none;
  padding-bottom: 6rem;
  padding-top: 3rem;
  background-color: #f7f2f2;
}

.whatToLearn {
  color: #2b0101;
  font-weight: 600;
  font-family: "Work Sans";
  letter-spacing: 0rem;
  line-height: 1.5;
  font-size: 2.25rem;
  padding-top: 0rem;
  padding-bottom: 1rem;
  white-space: pre-line;
  margin-top: 0;
  margin-bottom: 0.5rem;
  display: block;
}

.categories {
  font-family: "Work Sans";
  letter-spacing: 0rem;
  color: #3b3b3b;
  font-size: 0.75rem;
  padding-top: 0.25rem;
  margin-right: 0.5rem;
  padding-bottom: 0.5rem;
  font-weight: 400;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  display: block;
  text-align: left;
}

.category-btn {
  white-space: nowrap;
  font-family: "Work Sans";
  font-weight: 600;
  color: #664242;
  border-radius: 9999rem;
  padding-top: 0.25rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  border-style: solid;
  margin-right: 0.25rem;
  border-width: 1px;
  padding-bottom: 0.25rem;
  font-size: 0.875rem;
  margin-left: 0.25rem;
  margin-bottom: 0.5rem;
  line-height: 1.5;
  text-align: left;
  cursor: pointer;
}

@media screen and (max-width: 600px) {
  .category-btn {
    font-weight: 600;
    font-size: 0.6rem;
  }
}

.category-btn-unselected {
  border-color: #d9d2d2;
  background-color: #f7f2f2;
}

.category-btn-unselected:hover {
  //background-color: #d9d2d2;
  background-color: #e5cfcf;
}

.category-btn-selected {
  border-color: #757575;
  background-color: #e5cfcf;
}

.course-title {
  word-break: break-word;
  overflow: hidden;
  font-family: "Work Sans";
  padding-top: 0rem;
  letter-spacing: 0rem;
  font-weight: 600;
  color: #664242;
  font-size: 1.25rem;
  padding-bottom: 0.75rem;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  text-align: left;
  display: block;
  line-height: 1.5;
}

.course-author {
  font-family: "Work Sans";
  padding-top: 0rem;
  letter-spacing: 0rem;
  color: #460303;
  padding-bottom: 0.5rem;
  font-weight: 400;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  text-align: left;
  font-size: 0.875rem;
  white-space: pre-line;
  margin-bottom: 0.5rem;
  display: block;
}

.course-length {
  word-break: break-word;
  font-family: "Work Sans";
  padding-top: 0rem;
  letter-spacing: 0rem;
  color: #969696;
  padding-bottom: 0.75rem;
  font-weight: 400;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  text-align: left;
  font-size: 0.875rem;
  display: block;
}

.course-tag-row {
  padding: 8px 8px;
  background-color: transparent;
  margin: 0;
  border: none;
  border-radius: 0;
  font-family: "Work Sans";
  color: #2e3e48;
  text-align: right;
  font-size: 0.875rem;
  position: absolute;
  display: block;
  font-weight: 400;
  line-height: 1.5;
}

.course-tag {
  font-family: "Work Sans";
  background-color: #eaeced;
  position: absolute;
  right: 0;
  color: #2e3e48;
  margin-top: 0.5rem;
  text-align: right;

  margin-bottom: 0.5rem;
  margin-right: 0.5rem;
  margin-left: 0.5rem;

  padding-top: 0.25rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;

  font-size: 0.875rem;
  display: block;
  font-weight: 400;
  line-height: 1.5;
}

.course-img {
  padding-top: 0;
  padding-bottom: 0;
  object-fit: fill;
}

.course-up-half {
  cursor: pointer;
}
</style>
  