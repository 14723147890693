const state = () => ({
    message: null
  })
        
  const mutations = {
    setErrorMessage: (state, msg) => {
      state.message = msg;
    },
    clearError: (state) => {
      state.message = null;
    }
  }
  
  const getters = {
    getErrorMessage: state => {
      return state.message
    },
  }
  
  const actions = {
  }
  
  export default {
    namespaced: false,
    state,
    mutations,
    getters,
    actions,
  };