import axios from 'axios';
import ServiceBase from './base.js'

export default {
  ...ServiceBase,

  // Login
  postUserLogin(e, p, succeed = (response)=>{}, failure = (error)=>{}, postExecution = ()=>{}) {  // eslint-disable-line no-unused-vars
    axios.post(this.V1_SERVICE_PREFIX + '/user/signin', {
      email: this.trim(e),
      password: this.password(p)
    })
    .then(succeed)
    .catch(failure)
    .finally(postExecution)
    ;
  },

  // Sign Up
  postUserSignUp(name, email, phone, passwordStr, succeed = (response)=>{}, failure = (error)=>{}, postExecution = ()=>{}) {  // eslint-disable-line no-unused-vars
    axios.post(this.V1_SERVICE_PREFIX + '/user/signup', {
      name: this.trim(name),
      email: this.trim(email),
      phone: this.trim(phone),
      password: this.password(passwordStr)
    })
    .then(succeed)
    .catch(failure)
    .finally(postExecution)
    ;
  },

  // get user profile
  getUserSignUp(token, succeed = (response)=>{}, failure = (error)=>{}, postExecution = ()=>{}) {  // eslint-disable-line no-unused-vars
    axios.post(this.V1_SERVICE_PREFIX + '/user/profile', {
      token: this.trim(token),
    })
    .then(succeed)
    .catch(failure)
    .finally(postExecution)
    ;
  },

  // update user profile
  putUserProfile(token, passwordStr, succeed = (response)=>{}, failure = (error)=>{}, postExecution = ()=>{}) {  // eslint-disable-line no-unused-vars
    axios.put(this.V1_SERVICE_PREFIX + '/user/profile', {
      token: this.trim(token),
      password: this.password(passwordStr),
    })
    .then(succeed)
    .catch(failure)
    .finally(postExecution)
    ;
  },

  // forgot password
  postForgotPassword(e, lang, succeed = (response)=>{}, failure = (error)=>{}, postExecution = ()=>{}) {  // eslint-disable-line no-unused-vars
    axios.post(this.V1_SERVICE_PREFIX + '/user/forgotpassword', {
      email: this.trim(e),
      lang: this.trim(lang)
    })
    .then(succeed)
    .catch(failure)
    .finally(postExecution)
    ;
  },

  // list all users in the account
  getListUsers(token, succeed = (response)=>{}, failure = (error)=>{}, postExecution = ()=>{}) {  // eslint-disable-line no-unused-vars
    axios.get(this.V1_SERVICE_PREFIX + '/user/listusers', {
      params: {
        token: this.trim(token)
      }
    })
    .then(succeed)
    .catch(failure)
    .finally(postExecution)
    ;
  },
}