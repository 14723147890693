<template>
  <CommonPageFramework>
    <div :style="{'min-height': mainBodyHeight}">
      <v-container fill-height>
        <v-row justify="space-around" no-gutters>
          <v-col class="v-col-10">
            <AdminPageComponent v-if="isAdmin"></AdminPageComponent>
            <SignInComponent v-else></SignInComponent>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </CommonPageFramework>
</template>

<script>
import { defineComponent } from 'vue';
  
// Components
import common from '@/assets/mixins/common.js';
import CommonPageFramework from '@/components/layout/CommonPageFramework.vue'
import SignInComponent from '@/components/admin/SignIn.vue';
import AdminPageComponent from '@/components/admin/AdminPage.vue';

export default defineComponent({
  name: 'AboutView',
  mixins: [common],
  components: {
    CommonPageFramework,
    SignInComponent,
    AdminPageComponent,
  },
  data() {
    return {
    }
  }
});
</script>

<style lang="scss" scoped>
</style>
        