<template>
  <v-container>
    <v-row class="text-center py-2" justify="space-around" no-gutters>
      <v-col>
        <v-text-field :label="$t('common_email_example')" v-model="email" hide-details="auto"></v-text-field>
      </v-col>
    </v-row>
    <v-row class="text-center py-2" justify="space-around" no-gutters>
      <v-col>
        <v-text-field :label="$t('common_password')" type="password" v-model="password" hide-details="auto"></v-text-field>
      </v-col>
    </v-row>
    
    <v-row class="text-center py-2" justify="space-around" no-gutters>
      <v-col>
        <v-btn :loading="getLoading" class="signin-btn" @click="signin">{{ $t('signinview_signin') }}</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
  
<script>
import Service from '@/service.js'
import common from '@/assets/mixins/common.js';

export default {
  name: 'ba-admin-sign-in',
  mixins: [common],
  data: () => ({
    email: null,
    password: null,
    isLoading: false,
  }),
  computed: {
    getLoading() {
      return this.isLoading;
    }
  },
  methods: {
    signin() {
      this.isLoading = true;
      new Service().postUserLogin(
        this.email, 
        this.password,
        (response) => { // eslint-disable-line no-unused-vars
          console.log(response.data.roles)
          this.$store.commit('setToken', response.data.token);
          this.$store.commit('setUser', {
            name: response.data.name,
            email: response.data.email,
            roles: response.data.roles,
          });

          this.$router.push('/admin');
        },
        (error) => { // eslint-disable-line no-unused-vars
          console.log(error);
        },
        () => {
          this.isLoading = false;
        }
      );
    },
  }
}
</script>
  
<style lang="scss" scoped>
.signin-btn {
  font-family: "Work Sans";
  background-color: #460303;
  color: #ffffff;
  letter-spacing: 0rem;
  font-weight: 600;
  display: inline-block;
  font-size: 1.25rem;
  text-decoration: none;
  border-radius: 0.5rem;
  cursor: pointer;
  text-align: left;
  text-transform: none;
  margin-top: 1.5rem;
}
</style>
    