<template>
  <v-container>
    <v-row class="text-center py-2" justify="space-around" no-gutters>
      <v-col>
        <v-table density="compact">
          <thead>
            <tr>
              <th>Id</th>
              <th>Name</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="c in courses" :key="c.id">
              <td>{{ c.id }}</td>
              <td>{{ c.name }}</td>
            </tr>
          </tbody>
        </v-table>
      </v-col>
    </v-row>
  </v-container>
</template>
      
<script>
import common from '@/assets/mixins/common.js';
import Service from '@/service.js'
    
export default {
  name: 'ba-admin-course-page',
  mixins: [common],
  data: () => ({
    courses: []
  }),
  computed: {
  },
  methods: {
  },
  mounted() {
    this.courses = [];
    new Service().getAdminLoadCourse(
      this.getToken,
      (response) => { // eslint-disable-line no-unused-vars
        this.courses = response.data.courses;
      },
      (error) => { // eslint-disable-line no-unused-vars
        console.log(error);
      }
    );
  }
}
</script>
    
<style lang="scss" scoped>
</style>
        