<template>
<CommonPageFramework>
  <div class="py-10">
    <v-container fill-height>
      <v-row>
        <v-col class="py-6 text-center">
          <p class="title">{{ $t('thankyouview_thankyou') }}</p>
          <p class="content">{{ $t('thankyouview_orderplaced') }}</p>
          <v-btn class="go-back-home-btn" @click="$router.push('/')">{{ $t('common_home') }}</v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</CommonPageFramework>
</template>
        
<script>
import { defineComponent } from 'vue';

// Components
import CommonPageFramework from '@/components/layout/CommonPageFramework.vue'

export default defineComponent({
  name: 'ThankYouView',
  components: {
    CommonPageFramework,
  },
  computed: {
  },
  methods: {
  },
  data() {
    return {
    }
  },
  mounted() {
    //console.log(this.$route.query.payment_intent);
    //console.log(this.$route.query.payment_intent_client_secret);
    //console.log(this.$route.query.redirect_status);
  }
});
</script>

<style lang="scss" scoped>
.title {
  color: #2b0101;
  font-family: "Work Sans";
  font-size: 1.875rem;
  margin-bottom: 0.5rem;
  font-weight: 800;
  line-height: 1.2;
  margin-top: 0;
  display: block;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.content {
  color: #2b0101;
  font-family: "Work Sans";
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  font-weight: 600;
  line-height: 1.2;
  margin-top: 0;
  display: block;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.go-back-home-btn {
  font-family: "Work Sans";
  background-color: #460303;
  color: #ffffff;
  letter-spacing: 0rem;
  font-weight: 600;
  display: inline-block;
  font-size: 1.25rem;
  text-decoration: none;
  border-radius: 0.5rem;
  cursor: pointer;
  text-align: left;
  text-transform: none;
  margin-top: 1.5rem;
}
</style>
              