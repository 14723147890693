<template>
<CommonPageFramework>
  <div class="py-10">
    <v-container fill-height  style="background-color: #f7f2f2;" class="mx-0 px-0" width="100%" fluid><v-row align="center" justify="center"><v-col>
    <v-container fill-width v-if="$vuetify.display.mdAndUp">
      <v-row>
        <v-col class="title py-6">{{ $t('guestcheckout_checkout') }}</v-col>
      </v-row>
      <v-row>
        <v-col class="v-col-8">
          <v-container class="px-0 py-0" width="100%">
            <v-row class="px-0 py-0">
              <v-col class="v-col-4" width="30%">
                <v-img :src="course.image" width="100%"></v-img>
              </v-col>
              <v-col class="pr-0 v-col-8">
                <v-container class="px-0 py-0">
                  <v-row>
                    <v-col class="v-col-auto product-title">{{ classTitle }}</v-col>
                    <v-col class="v-col-auto price text-right">${{ getAmount }}</v-col>
                  </v-row>
                </v-container>
                <p class="product-desc">{{ $t('guestcheckout_providedby') }} {{ classProvidedBy }}</p>
                <div v-if="this.session">
                  <p class="ba-session-date"><font-awesome-icon class="px-2" icon="fas fa-clock"/>{{ session.date }}</p>
                  <p class="ba-session-time pl-8">From {{ session.start }} to {{ session.end }}</p>
                  <p class="ba-session-location pl-8">At {{ session.location }}</p>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="title py-6">{{ $t('guestcheckout_contactinfo') }}</v-col>
            </v-row>
            <v-row>
              <v-col class="">
                <v-container class="px-0">
                  <v-row class="py-2">
                    <v-col><v-text-field :label="$t('guestcheckout_parentname')" :error="!isFullnameValidForValidation" :error-messages="getFullnameErrorMessage(parentFullname)" v-model="parentFullname" hide-details="auto"></v-text-field></v-col>
                    <v-col><v-text-field :label="$t('common_email_example')" :error="!isEmailValidForValidation" :error-messages="getEmailErrorMessage(userEmail)" v-model="userEmail" hide-details="auto"></v-text-field></v-col>
                    <v-col><v-text-field :label="$t('common_phone')" :error="!isPhoneValidForValidation" :error-messages="getPhoneErrorMessage(userPhone)" v-model="userPhone" hide-details="auto"></v-text-field></v-col>
                  </v-row>
                  <v-row class="py-2">
                    <v-col><v-text-field :label="$t('guestcheckout_kidname')" :error="!isKidnameValidForValidation" :error-messages="getFullnameErrorMessage(kidFullname)" v-model="kidFullname" hide-details="auto"></v-text-field></v-col>
                    <v-col><v-select v-model="kidAge" :label="$t('guestcheckout_kidage')" :items="['7', '8', '9', '10']"></v-select></v-col>
                  </v-row>
                  <v-row>
                    <v-col class="py-0">
                      <v-select v-model="isGreatParkNeighborhood" :label="$t('common_residency')" :items="['Yes', 'No']"></v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="py-0"><v-textarea :label="$t('guestcheckout_anyquestion')" v-model="anyQuestion" hide-details="auto"></v-textarea></v-col>
                  </v-row>
                  <v-row>
                    <v-col class="text-right">
                      <v-btn v-if="paymentMethod == 'zelle' && !isZellePayable" disabled class="checkout-btn" @click="pay">{{ $t('guestcheckout_submitcontactinfo') }}</v-btn>
                      <v-btn v-if="paymentMethod == 'zelle' && isZellePayable" class="checkout-btn" @click="pay">{{ $t('guestcheckout_submitcontactinfo') }}</v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="title py-6">{{ $t('guestcheckout_paymentmethod') }}</v-col>
            </v-row>
            <v-row>
              <v-col>
                <StripeElement :metadata="getMetadata" :amount="getTotalAmount" :description="paymentDescription" @paymentCallback="setPaymentCallback" @updatePaymentMethod="updatePaymentMethod" @updatePaymentStatus="updatePaymentStatus" @updatePaymentInfoComplete="updatePaymentInfoComplete"/>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
        <v-col class="v-col-4 pl-4">
          <v-container class="px-0 py-0">
            <v-row justify="space-between">
              <v-col class="subtotal">{{ $t('guestcheckout_subtitle') }}</v-col>
              <v-col class="price text-right">${{ getSubtotalAmountToDisplay }}</v-col>
            </v-row>
            <v-row justify="space-between">
              <v-col class="serviceFee">{{ $t('guestcheckout_paymentservicefee') }}</v-col>
              <v-col class="price text-right">${{ getPaymentServiceFee }}</v-col>
            </v-row>
            <v-row justify="space-between">
              <v-col class="total">{{ $t('guestcheckout_total') }}</v-col>
              <v-col class="price text-right">${{ getTotalAmountToDisplay }}</v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-btn v-if="paymentMethod == 'card'" :disabled="!isPayable" :loading="paymentStatus=='loading'" class="checkout-btn" id="submit" @click="pay">{{ $t('guestcheckout_paynow') }}</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
    <v-container v-else class="px-8" style="background-color: #f7f2f2;" >
      <v-row>
        <v-col class="title py-6">{{ $t('guestcheckout_checkout') }}</v-col>
      </v-row>
      <v-row class="px-0 py-0" justify="space-between">
        <v-col>
          <v-img :src="course.image" width="100%"></v-img>
        </v-col>
        <v-col>
          <p class="product-title">{{ classTitle }}</p>
          <p class="product-desc">{{ $t('guestcheckout_providedby') }} {{ classProvidedBy }}</p>
          <div v-if="this.session">
            <p class="ba-session-date"><font-awesome-icon class="px-2" icon="fas fa-clock"/>{{ session.date }}</p>
            <p class="ba-session-time pl-8">From {{ session.start }} to {{ session.end }}</p>
            <p class="ba-session-location pl-8">At {{ session.location }}</p>
          </div>
        </v-col>
        <v-col class="text-right px-6">
          <p class="price"><font-awesome-icon icon="fa-solid fa-tag" /><span class="pl-2">${{ getAmount }}</span></p>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="title py-6">{{ $t('guestcheckout_contactinfo') }}</v-col>
      </v-row>
      <v-row>
        <v-col class="">
          <v-container class="px-0">
            <v-row class="py-1"><v-col><v-text-field :label="$t('guestcheckout_parentname')" :error="!isFullnameValidForValidation" :error-messages="getFullnameErrorMessage(parentFullname)" v-model="parentFullname" hide-details="auto"></v-text-field></v-col></v-row>
            <v-row class="py-1"><v-col><v-text-field :label="$t('common_email_example')" :error="!isEmailValidForValidation" :error-messages="getEmailErrorMessage(userEmail)" v-model="userEmail" hide-details="auto"></v-text-field></v-col></v-row>
            <v-row class="py-1"><v-col><v-text-field :label="$t('common_phone')" :error="!isPhoneValidForValidation" :error-messages="getPhoneErrorMessage(userPhone)" v-model="userPhone" hide-details="auto"></v-text-field></v-col></v-row>
            <v-row class="py-1">
              <v-col><v-text-field :label="$t('guestcheckout_kidname')" :error="!isKidnameValidForValidation" :error-messages="getFullnameErrorMessage(kidFullname)" v-model="kidFullname" hide-details="auto"></v-text-field></v-col>     
            </v-row>
            <v-row class="py-1">
              <v-col><v-select v-model="kidAge" :label="$t('guestcheckout_kidage')" :items="['7', '8', '9', '10']"></v-select></v-col>
            </v-row>
            <v-row>
              <v-col class="py-2">
                <v-select v-model="isGreatParkNeighborhood" :label="$t('common_residency')" :items="['Yes', 'No']"></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-2"><v-textarea :label="$t('guestcheckout_anyquestion')" v-model="anyQuestion" hide-details="auto"></v-textarea></v-col>
            </v-row>
            <v-row>
              <v-col class="text-right">
                <v-btn v-if="paymentMethod == 'zelle' && !isZellePayable" disabled class="checkout-btn" @click="pay">{{ $t('guestcheckout_submitcontactinfo') }}</v-btn>
                <v-btn v-if="paymentMethod == 'zelle' && isZellePayable" class="checkout-btn" @click="pay">S{{ $t('guestcheckout_submitcontactinfo') }}</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="title py-6">{{ $t('guestcheckout_paymentmethod') }}</v-col>
      </v-row>
      <v-row>
        <v-col>
          <StripeElement :metadata="getMetadata" :amount="getTotalAmount" :description="paymentDescription" @paymentCallback="setPaymentCallback" @updatePaymentMethod="updatePaymentMethod" @updatePaymentStatus="updatePaymentStatus" @updatePaymentInfoComplete="updatePaymentInfoComplete"/>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-container class="px-0 py-0">
            <v-row justify="space-between">
              <v-col class="subtotal">{{ $t('guestcheckout_subtitle') }}</v-col>
              <v-col class="price text-right">${{ getSubtotalAmountToDisplay }}</v-col>
            </v-row>
            <v-row justify="space-between">
              <v-col class="serviceFee">{{ $t('guestcheckout_paymentservicefee') }}</v-col>
              <v-col class="price text-right">${{ getPaymentServiceFee }}</v-col>
            </v-row>
            <v-row justify="space-between">
              <v-col class="total">{{ $t('guestcheckout_total') }}</v-col>
              <v-col class="price text-right">${{ getTotalAmountToDisplay }}</v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-right">
          <v-btn v-if="paymentMethod == 'card'" :disabled="!isPayable" :loading="paymentStatus=='loading'" class="checkout-btn" id="submit" @click="pay">{{ $t('guestcheckout_paynow') }}</v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-col></v-row></v-container>
  </div>
</CommonPageFramework>
</template>
        
<script>
import { defineComponent } from 'vue';
import common from '@/assets/mixins/common.js';

// Components
import CommonPageFramework from '@/components/layout/CommonPageFramework.vue'
import StripeElement from '@/components/payment/StripeElement.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default defineComponent({
  name: 'ContactUsView',
  props: ['email'],
  mixins: [common],
  components: {
    CommonPageFramework,
    StripeElement,
    FontAwesomeIcon,
  },
  computed: {
    isPayable() {
      return this.paymentCallback != null &&
        this.isFullnameValidForPay && 
        this.isEmailValidForPay && 
        (this.userPhone == null || this.userPhone === '' || this.isPhoneValidForPay) &&
        this.isKidnameValidForPay &&
        this.kidAge != null &&
        this.isNotLoading &&
        this.isComplete &&
        this.isGreatParkNeighborhood != null;
    },
    isZellePayable() {
      return this.isFullnameValidForPay && 
        this.isEmailValidForPay && 
        (this.userPhone == null || this.userPhone === '' || this.isPhoneValidForPay) &&
        this.isKidnameValidForPay &&
        this.kidAge != null &&
        this.isNotLoading &&
        this.isGreatParkNeighborhood != null;
    },
    isFullnameValidForPay() {
      return this.validateFullname(this.parentFullname);
    },
    isKidnameValidForPay() {
      return this.validateFullname(this.kidFullname);
    },
    isEmailValidForPay() {
      return this.validateEmail(this.userEmail);
    },
    isPhoneValidForPay() {
      return this.validatePhone(this.userPhone);
    },
    isFullnameValidForValidation() {
      return this.parentFullname == null || this.validateFullname(this.parentFullname);
    },
    isKidnameValidForValidation() {
      return this.kidFullname == null || this.validateFullname(this.kidFullname);
    },
    isEmailValidForValidation() {
      return this.userEmail == null || this.validateEmail(this.userEmail);
    },
    isPhoneValidForValidation() {
      return this.userPhone == null || this.userPhone === '' || this.validatePhone(this.userPhone);
    },
    isNotLoading() {
      return !(this.paymentStatus == 'loading');
    },
    isComplete() {
      return this.paymentInfoComplete;
    },
    getAmount() {
      return (this.amount / 100).toFixed(2);
    },
    getSubtotalAmountToDisplay() {
      return (this.amount / 100).toFixed(2)
    },
    getSubTotalAmount() {
      return this.getAmount;
    },
    getPaymentServiceFee() {
      if (this.paymentMethod == 'card') {
        return (Math.round(this.amount * 0.03) / 100).toFixed(2);
      }
      return (0).toFixed(2);
    },
    getTotalAmount() {
      return (parseFloat(this.getSubTotalAmount) + parseFloat(this.getPaymentServiceFee)) * 100;
    },
    getTotalAmountToDisplay() {
      return (this.getTotalAmount / 100).toFixed(2);
    },
    courseName() {
      return this.$route.params.name;
    },
    course() {
      let allCourses = this.$store.getters.getAllCourses;
      let theCourse = allCourses.filter(x=>x.name == this.courseName);
      if (theCourse.length > 0)
        return theCourse[0];
      return null;
    },
    selectedOption() {
      if (this.$route.query.option) {
        return this.$route.query.option;
      }
      return 0;
    },
    session() {
      if (!this.$route.query.session)
        return null;
      let allSessions = this.course.options[this.selectedOption].sessions;
      let theSession = allSessions.filter(x=>x.id != null && x.id == this.$route.query.session);
      if (theSession.length > 0)
        return theSession[0];
      return null;
    },
    paymentDescription() {
      if (this.session != null) {
        let sessionText = this.session.date + ' from ' + this.session.start + ' to ' + this.session.end + ' at ' + this.session.location
        return this.courseName + ' (' + sessionText + ') provided by ' + this.classProvidedBy
      } else {
        return this.courseName + ' provided by ' + this.classProvidedBy
      }
    },
    getMetadata() {
      let sessionText = '';
      if (this.session != null) {
        sessionText = this.session.date + ' from ' + this.session.start + ' to ' + this.session.end + ' at ' + this.session.location;
      }
      return {
        fullname: this.parentFullname,
        email: this.userEmail,
        phone: this.userPhone,
        kidname: this.kidFullname,
        kidage: this.kidAge,
        great_park_neighborhood: this.isGreatParkNeighborhood,
        question: this.anyQuestion,
        session: sessionText
      };
    },
  },
  methods: {
    setPaymentCallback(paymentCallback) {
      this.paymentCallback = paymentCallback;
    },
    updatePaymentStatus(status) {
      this.paymentStatus = status;
      if (status != null && status != 'loading') {
        this.$store.commit('setErrorMessage', status);
      }
    },
    updatePaymentInfoComplete(isComplete) {
      this.paymentInfoComplete = isComplete;
    },
    updatePaymentMethod(paymentMethod) {
      this.paymentMethod = paymentMethod;
    },
    pay() {
      this.paymentCallback();
    }
  },
  data() {
    return {
      paymentCallback: null,
      paymentStatus: null,
      paymentInfoComplete: false,
      paymentMethod: null,

      parentFullname: null,
      userEmail: null,
      userPhone: null,
      kidFullname: null,
      kidAge: null,

      amount: null,
      classTitle: null,
      classProvidedBy: null,

      // dropdown selection
      isGreatParkNeighborhood: null,
      anyQuestion: null,
    }
  },
  mounted() {
    this.classTitle = this.course.title;
    this.classProvidedBy = this.course.providedBy;
    this.userEmail = this.email;

    if (this.session == null) {
      let amountStr = '' + this.course.options[this.selectedOption].price;
      this.amount = Math.round(parseFloat(amountStr) * 100);
    } else {
      this.amount = Math.round(parseFloat(this.session.price) * 100);
    }
  }
});
</script>

<style lang="scss" scoped>
.title {
  color: #2b0101;
  font-family: "Work Sans";
  font-size: 1.875rem;
  margin-bottom: 0.5rem;
  font-weight: 800;
  line-height: 1.2;
  margin-top: 0;
  display: block;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.product-title {
  color: #2b0101;
  font-family: "Work Sans";
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  font-weight: 600;
  line-height: 1.2;
  margin-top: 0;
  display: block;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.product-desc {
  color: #2b0101;
  font-family: "Work Sans";
  font-weight: 300;
  font-size: 1rem;
  line-height: 2;
  white-space: pre-line;
  margin-bottom: 1rem;
  display: block;
  letter-spacing: 0rem;
  white-space: pre-line;
}

.price {
  color: #2b0101;
  font-family: "Work Sans";
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  margin-top: 0;
  display: block;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.serviceFee {
  color: #2b0101;
  font-family: "Work Sans";
  font-size: 1rem;
  margin-bottom: 0.5rem;
  font-weight: 600;
  line-height: 1.2;
  margin-top: 0;
  display: block;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.subtotal {
  color: #2b0101;
  font-family: "Work Sans";
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
  font-weight: 600;
  line-height: 1.2;
  margin-top: 0;
  display: block;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.total {
  color: #2b0101;
  font-family: "Work Sans";
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  font-weight: 600;
  line-height: 1.2;
  margin-top: 0;
  display: block;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.checkout-btn {
  font-family: "Work Sans";
  background-color: #460303;
  color: #ffffff;
  letter-spacing: 0rem;
  font-weight: 600;
  display: inline-block;
  font-size: 1.25rem;
  text-decoration: none;
  border-radius: 0.5rem;
  cursor: pointer;
  text-align: left;
  text-transform: none;
  margin-top: 1.5rem;
}

.ba-session-date {
  color: #a3a3a3;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}

.ba-session-time {
  color: #343a40;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}

.ba-session-location {
  color: #6c757d;
  font-size: 1rem;
  font-weight: bolder;
  line-height: 1.5;
}
</style>
          