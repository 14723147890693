import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { i18n } from './i18n'
import { loadFonts } from './plugins/webfontloader'
import VueGoogleMaps from '@fawmi/vue-google-maps'


import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faUserSecret } from '@fortawesome/free-solid-svg-icons'
library.add(faUserSecret)

import { faClock } from "@fortawesome/free-solid-svg-icons";
import { faChalkboardTeacher } from "@fortawesome/free-solid-svg-icons";
import { faPhotoVideo } from "@fortawesome/free-solid-svg-icons";
import { faRightLeft } from "@fortawesome/free-solid-svg-icons";
import { faCheckDouble } from "@fortawesome/free-solid-svg-icons";
import { faAlignJustify } from "@fortawesome/free-solid-svg-icons";
import { faAd } from "@fortawesome/free-solid-svg-icons";
import { faBook } from "@fortawesome/free-solid-svg-icons";
import { faPencilRuler } from "@fortawesome/free-solid-svg-icons";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { faTag } from "@fortawesome/free-solid-svg-icons";
import { faRegistered } from "@fortawesome/free-regular-svg-icons";
import { faUsers } from "@fortawesome/free-solid-svg-icons";
import { faChild } from "@fortawesome/free-solid-svg-icons";
import { faCreditCard } from "@fortawesome/free-regular-svg-icons";
import { faLanguage } from "@fortawesome/free-solid-svg-icons";
import { faConnectdevelop } from "@fortawesome/free-brands-svg-icons";
import { faWeixin } from "@fortawesome/free-brands-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons"; 

library.add(faClock)
library.add(faChalkboardTeacher)
library.add(faPhotoVideo)
library.add(faRightLeft)
library.add(faCheckDouble)
library.add(faAlignJustify)
library.add(faAd)
library.add(faBook)
library.add(faPencilRuler)
library.add(faArrowRight)
library.add(faTag)
library.add(faRegistered)
library.add(faUsers)
library.add(faChild)
library.add(faCreditCard)
library.add(faLanguage)
library.add(faConnectdevelop)
library.add(faWeixin)
library.add(faWhatsapp)

loadFonts()

import Service from '@/service.js'
new Service().loadWithLang(store);

createApp(App)
  .use(router)
  .use(store)
  .use(vuetify)
  .use(i18n)
  .use(VueGoogleMaps, {
    load: {
        key: 'YOUR_API_KEY_COMES_HERE',
    },
  })
  .component('font-awesome-icon', FontAwesomeIcon)
  .mount('#app')
