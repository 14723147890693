<template>
<v-container>
  <v-row>
    <v-col class="text-center table-head">All Courses</v-col>
  </v-row>
  <v-row class="text-center py-2" justify="space-around" no-gutters>
    <v-col class="v-col-auto">
      <v-table fixed-header density="compact">
        <thead>
          <th class="px-1">ID</th>
          <th class="px-1">Course Id</th>
        </thead>
        <tr v-for="(c, index) in getEnrollments" :key="index">
          <td class="px-1" >{{ c.id }}</td>
          <td class="px-1" >{{ c.course_id }}</td>
        </tr>
      </v-table>
    </v-col>
  </v-row>
</v-container>
</template>

<script>
import Service from '@/service.js'
import common from '@/assets/mixins/common.js';

export default {
  name: 'ba-user-mgr',
  mixins: [common],
  data: () => ({
    enrollments: [],
  }),
  computed: {
    getLoading() {
      return this.isLoading;
    },
    getEnrollments() {
      return this.enrollments;
    }
  },
  methods: {
  },
  mounted () {
    this.enrollments = [];
    new Service()
    .getListCourses(this.getToken, 
      (response) => {
        this.enrollments = response.data.enrollments
      },
      (error) => { // eslint-disable-line no-unused-vars

      }
    )
  }
}
</script>
    
<style lang="scss" scoped>
.table-head {
  color: #2b0101;
  font-weight: 700;
  font-family: "Work Sans";
  padding-bottom: 1.25rem;
  padding-top: 0rem;
  letter-spacing: 0rem;
  font-size: 1.5rem;
  line-height: 1.5;
  white-space: pre-line;
  margin-top: 0;
  margin-bottom: 1rem;
  display: block;
}
</style>
            