<template>
<v-container>
  <v-row>
    <v-col class="text-center table-head">All Payments</v-col>
  </v-row>
  <v-row class="text-center py-2" justify="space-around" no-gutters>
    <v-col class="v-col-auto text-center" justify="space-around">
      <v-table fixed-header density="compact" class="ba-table">
        <thead>
          <th class="px-1">ID</th>
          <th class="px-1">Vendor</th>
          <th class="px-1">Amount</th>
          <th class="px-1">Name</th>
          <th class="px-1">Email</th>
          <th class="px-1">Created</th>
          <th class="px-1">Phone</th>
          <th class="px-1">Description</th>
          <th class="px-1" v-if="false">PaymentIntent Id</th>
          <th class="px-1" v-if="false">PaymentMethod Id</th>
          <th class="px-1" v-if="false">Free Self Practice</th>
          <th class="px-1" v-if="false">Great Park Neighborhood</th>
          <th class="px-1" v-if="false">Which Class Attend</th>
        </thead>
        <tr v-for="(u, index) in getPayments" :key="index">
          <td class="px-1">{{ u.id }}</td>
          <td class="px-1">{{ u.vendor }}</td>
          <td class="px-1">{{ u.amount }}</td>
          <td class="px-1">{{ u.name }}</td>
          <td class="px-1">{{ u.email }}</td>
          <td class="px-1">{{ u.created }}</td>
          <td class="px-1">{{ u.phone }}</td>
          <td class="px-1">{{ u.description }}</td>
          <td class="px-1" v-if="false">{{ u.payment_intent_id }}</td>
          <td class="px-1" v-if="false">{{ u.payment_method_id }}</td>
          <td class="px-1" v-if="false">{{ u.free_self_practice }}</td>
          <td class="px-1" v-if="false">{{ u.great_park_neighborhood }}</td>
          <td class="px-1" v-if="false">{{ u.which_class_attend }}</td>
        </tr>
      </v-table>
    </v-col>
  </v-row>
</v-container>
</template>
    
<script>
import Service from '@/service.js'
import common from '@/assets/mixins/common.js';

export default {
  name: 'ba-payment-mgr',
  mixins: [common],
  data: () => ({
    payments: [],
  }),
  computed: {
    getLoading() {
      return this.isLoading;
    },
    getPayments() {
      return this.payments;
    }
  },
  methods: {
  },
  mounted () {
    new Service()
    .getListPayments(this.getToken, 
      (response) => {
        this.payments = response.data.payments
      },
      (error) => { // eslint-disable-line no-unused-vars

      }
    )
  }
}
</script>
    
<style lang="scss" scoped>
@import '@/assets/scss/global.scss';

.table-head {
  color: #2b0101;
  font-weight: 700;
  font-family: "Work Sans";
  padding-bottom: 1.25rem;
  padding-top: 0rem;
  letter-spacing: 0rem;
  font-size: 1.5rem;
  line-height: 1.5;
  white-space: pre-line;
  margin-top: 0;
  margin-bottom: 1rem;
  display: block;
}
</style>
            