const state = () => ({
  courses: []
})
      
const mutations = {
  setAllCourses: (state, courses) => {
    state.courses = courses;
  }
}

const getters = {
  getAllCourses: state => {
    return state.courses
  },
}

const actions = {
}

export default {
  namespaced: false,
  state,
  mutations,
  getters,
  actions,
};