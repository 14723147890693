export const messages = {
    // Common
    common_fullname: '姓名',
    common_fullname_example: '姓名 (例如：John Smith) *',
    common_email: '电子邮箱',
    common_email_example: '电子邮箱 *',
    common_password: '密码 *',
    common_repeatpassword: '重复密码 *',
    common_forgotpassword: '找回密码',
    common_yourmessage: '您的消息',
    common_youremail: '您的邮箱',
    common_home: '主页',
    common_phone: '电话',
    common_residency: '您是大公园的居民吗？ *',
    common_whichclass: '感兴趣的课程？ *',
    common_studentgradelevel: '学生年级',
    common_bettertime: '建议一个更合适的时间？其他建议或者意见？',
    common_submit: '提交',
    common_close: '关闭',
    common_company: '公司信息',
    common_courses: '全部课程',
    common_contactus: '联系我们',
    common_aboutus: '关于我们',
    common_privacypolicy: '隐私政策',
    common_termsofservice: '服务条款',
    common_serviceprovideraggrement: '服务提供商协议',
    common_and: '和',
    common_inquiry: '咨询',
    // HomeView
    homeview_title: 'Beyond Activity',
    homeview_subtitle_1: '通过值得信赖的本地提供商提供的活动来丰富您的生活，每月都会添加新内容。',
    homeview_subtitle_2: '通过值得信赖的本地提供商',
    homeview_subtitle_3: '提供的活动来丰富您的生活，',
    homeview_subtitle_4: '每月都会添加新内容。',
    homeview_seeAllCourses: '查看所有课程',
    homeview_media_subtitle_1: '按照自己的节奏',
    homeview_media_subtitle_2: '您选择活动时间，我们来安排!',
    homeview_media_subtitle_3: '向行业专家学习',
    homeview_media_subtitle_4: '选择最优秀的导师，并向她们学习',
    homeview_media_subtitle_5: '面对面课程和虚拟课程',
    homeview_media_subtitle_6: '您来选择虚拟课程或者面对面课程',
    homeview_category_subtitle_1: '热门类别',
    homeview_category_subtitle_2: '舞蹈',
    homeview_category_subtitle_3: '编程',
    homeview_category_subtitle_4: '语言学习',
    homeview_category_subtitle_5: '设计',
    // AllCourseView
    allcourseview_subtitle_1: '下一步学什么',
    allcourseview_allcategories: '所有类别',
    // CourseView
    courseview_sorry: "抱歉，找不到该课程。",
    courseview_instructedby: '由Beyond Activity授课',
    courseview_categorydance: '舞蹈类别',
    courseview_coursedescription: '课程简介',
    courseview_clicktoregister: '注册',
    courseview_sessions: '课程时间',
    courseview_upcoming: '即将推出',
    courseview_completed: '已完成',
    courseview_register: '注册',
    courseview_attendees: '出席者',
    courseview_duration: '时长',
    courseview_price: '价格',
    courseview_age: '年龄',
    courseview_languageofinstruction: '授课语言',
    courseview_languagelevel: '语言要求',
    courseview_materials: '授课材料',
    //GuestCheckout
    guestcheckout_checkout: '付款',
    guestcheckout_providedby: '授课导师',
    guestcheckout_contactinfo: '联系方式',
    guestcheckout_submitcontactinfo: '提交联系方式',
    guestcheckout_paymentmethod: '支付方式',
    guestcheckout_subtitle: '小计',
    guestcheckout_paymentservicefee: '支付服务费',
    guestcheckout_total: '总额',
    guestcheckout_paynow: '支付',
    guestcheckout_parentname: '父/母姓名 (例如：John Smith) *',
    guestcheckout_kidname: '孩子姓名 (例如：David Smith) *',
    guestcheckout_kidage: '孩子年龄 *',
    guestcheckout_anyquestion: '您有其他问题吗？',
    // InterestForm
    interestform_contactus: '或者与我们联系',
    interestform_inquerysubmitted: '已成功提交咨询信息。',
    interestform_inquiryform: '咨询表格',
    interestform_requiredfields: '* 为必填项',
    // AboutView
    aboutview_title: '关于我们公司',
    aboutview_abouttext: `我们每天所做的事情，无论大小，都在塑造着我们的生活。 其中一些活动已习以为常，以至于我们甚至都没有想到它们。然而，其他的事情是如此重要，以至于他们永远改变了我们的生活轨迹。但它们中的每一个都对塑造自我以及我们的生活做出了贡献。

    在Beyond Activity，我们致力于帮助人们获得和享受丰富且健康快乐的生活。我们的使命是让我们的用户轻松找到、了解和从事他们喜欢的活动，无论是学习、爱好还是与朋友联系。 为此，我们提供了高质量且可靠的活动信息，优质的客户服务和易于使用的工具。`,
    aboutview_ourvision: '我们的愿景',
    aboutview_visiontext: '帮助人们获得更加丰富、更加健康的生活。',
    aboutview_ourmission: '我们的使命',
    aboutview_missiontext: '通过提供高质量和可靠的活动信息和服务，帮助我们的用户能够专注于享受学习、追求爱好并结识更多朋友。',
    // ContactUsView
    contactusview_keepintouch: '联系方式',
    contactusview_location: '地址:',
    contactusview_email: '电子邮箱:',
    contactusview_phone: '电话:',
    contactusview_openhour: '周一至周五 上午9点至下午6点',
    contactusview_subscribe: '订阅',
    contactusview_sendusamessage: '留言',
    contactusview_submitinquery: '提交',
    // ThankyouView
    thankyouview_thankyou: '非常感谢!',
    thankyouview_orderplaced: '您的订单已成功提交。',
    // SignInView
    signinview_signin: '登陆',
    signinview_signup: '注册',
    // SignUp
    signup_stmt1: '注册时，您已同意我们的',
    signup_passwordtooshort: '密码太短，不得少于8个字符。',
    signup_passwordnotmatch: "密码不匹配。",
    // Header
    header_signout: '退出',
    header_allcourses: '所有课程',
    header_categories: '课程类别',
    // Payment
    payment_creditcard: '信用卡',
    payment_stmt1: '信用卡支付提供商会收取额外3%服务费。 您可以选择使用Zelle',
    payment_stmt2: '以避免支付该项费用。',
    unused: ''
};
