const state = () => ({
  localization: [
    { name: 'English', value: 'en' },
    { name: '中文', value: 'zh' },
    { name: '한국어', value: 'ko' },
  ],
})

const mutations = {
}

const getters = {
  getLocalization: state => {
    return state.localization
  }
}

const actions = {
}

export default {
  namespaced: false,
  state,
  mutations,
  getters,
  actions,
};