import axios from 'axios';
import ServiceBase from './base.js'

export default {
  ...ServiceBase,

  // Create Payment Intent
  createClientSecret(amount, paymentMethodType, description, metadata, succeed = (response)=>{}, failure = (error)=>{}, postExecution = ()=>{}) {  // eslint-disable-line no-unused-vars
    axios.post(this.V1_SERVICE_PREFIX + '/stripe/paymentintent', {
      amount: amount,
      method: this.trim(paymentMethodType),
      description: description,
      metadata: metadata
    })
    .then(succeed)
    .catch(failure)
    .finally(postExecution)
    ;
  },

  // Update Payment Intent
  updatePaymentIntent(id, metadata, succeed = (response)=>{}, failure = (error)=>{}, postExecution = ()=>{}) {  // eslint-disable-line no-unused-vars
    axios.put(this.V1_SERVICE_PREFIX + '/stripe/paymentintent', {
      id: this.trim(id),
      metadata: metadata
    })
    .then(succeed)
    .catch(failure)
    .finally(postExecution)
    ;
  },
}