import axios from 'axios';
import ServiceBase from './base.js'

export default {
  ...ServiceBase,

  // submit interest form
  submitInterestForm(username, email, phone, isNeighbor, classname, gradeLevel, comment, succeed = (response)=>{}, failure = (error)=>{}, postExecution = ()=>{}) {  // eslint-disable-line no-unused-vars
    axios.post(this.V1_SERVICE_PREFIX + '/misc/interestform', {
      username: this.trim(username),
      email: this.trim(email),
      phone: this.trim(phone),
      isNeighbor: this.trim(isNeighbor),
      classname: this.trim(classname),
      gradeLevel: this.trim(gradeLevel),
      comment: this.trim(comment)
    })
    .then(succeed)
    .catch(failure)
    .finally(postExecution)
    ;
  },
}