<template>
  <v-container>
    <v-row class="text-center py-2" justify="space-around" no-gutters>
      <v-col>
        <v-text-field :label="$t('common_email_example')" :error="!isEmailValidForValidation" :error-messages="getEmailErrorMessage(userEmail)" v-model="userEmail" hide-details="auto"></v-text-field>
      </v-col>
    </v-row>
    <v-row class="text-center py-2" justify="space-around" no-gutters>
      <v-col>
        <v-text-field :label="$t('common_password')" type="password" :error="!isPasswordValidForValidation" :error-messages="getPasswordErrorMessage(password)" v-model="password" hide-details="auto"></v-text-field>
      </v-col>
    </v-row>
    <v-row class="text-left" justify="space-around" no-gutters>
      <v-col>
        <router-link class="forgot-password-text" to="/forgotpassword">{{ $t('common_forgotpassword') }}</router-link>
      </v-col>
    </v-row>
    <v-row class="text-center py-2" justify="space-around" no-gutters>
      <v-col>
        <v-btn :disabled="!ableToSignIn" :loading="getLoading" class="signin-btn" @click="signin">{{ $t('signinview_signin') }}</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Service from '@/service.js'
import common from '@/assets/mixins/common.js';

export default {
  name: 'ba-sign-in',
  mixins: [common],
  data: () => ({
    userEmail: null,
    password: null,
    isLoading: false,
  }),
  computed: {
    isEmailValidForValidation() {
      return this.userEmail == null || this.validateEmail(this.userEmail);
    },
    isPasswordValidForValidation() {
      return this.password == null || this.validatePassword(this.password);
    },
    ableToSignIn() {
      return this.userEmail != null && this.isEmailValidForValidation 
          && this.password != null && this.isPasswordValidForValidation;
    },
    getLoading() {
      return this.isLoading;
    }
  },
  methods: {
    signin() {
      this.isLoading = true;
      new Service().postUserLogin(
        this.userEmail, 
        this.password,
        (response) => { // eslint-disable-line no-unused-vars
          this.$store.commit('setToken', response.data.token);
          this.$store.commit('setUser', {
            name: response.data.name,
            email: response.data.email,
            roles: response.data.roles,
          });

          this.$router.push('/');
        },
        (error) => { // eslint-disable-line no-unused-vars
          console.log(error);
        },
        () => {
          this.isLoading = false;
        }
      );
    },
    getPasswordErrorMessage(password) { // eslint-disable-line no-unused-vars
      if (password == null) {
        return [];
      }

      if (password.length < 8) {
        return ['Password is too short. At least 8 characters.'];
      }

      return [];
    },
  }
}
</script>

<style lang="scss" scoped>
.signin-btn {
  font-family: "Work Sans";
  background-color: #460303;
  color: #ffffff;
  letter-spacing: 0rem;
  font-weight: 600;
  display: inline-block;
  font-size: 1.25rem;
  text-decoration: none;
  border-radius: 0.5rem;
  cursor: pointer;
  text-align: left;
  text-transform: none;
  margin-top: 1.5rem;
}

.forgot-password-text {
  color: #2b0101;
  font-weight: 400;
  font-family: sans-serif;
  display: block;
  padding-bottom: 1.25rem;
  padding-top: 0rem;
  font-size: 1rem;
  white-space: pre-line;
  line-height: 1.5;
  text-align: left;
}
</style>
  