const API_SERVICE_BASE = 'https://api.beyondactivity.com/alpha'
//const API_SERVICE_BASE = 'http://localhost:8081'

const STRIPE_PUBLIC_KEY = 'pk_live_51MMPtUFJvP0W02oWIkneqv98ghfGy4KroeyaRAMD4pkhcluHTNrCXX3E6SjIYudgxdkAf7okoET5LLjthaKiWQpY00AFDs8gri'

export { 
    API_SERVICE_BASE,
    STRIPE_PUBLIC_KEY
}

