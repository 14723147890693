<template>
<CommonPageFramework>
  <v-container fill-height>
    <v-row justify="space-around">
      <v-col class="v-col-3">
        <v-card class="mx-auto" max-width="400" tile>
          <v-list v-for="(item, index) in getAdminItems" :key="index" :class="{'item-active': item.value == state}">
            <a target class="px-8 py-1 ba-list-item" @click="item.callback">{{ item.title }}</a>
          </v-list>
          <v-divider v-if="isAdmin"></v-divider>
          <v-list v-for="(item, index) in getUserItems" :key="index" :class="{'item-active': item.value == state}">
            <a target class="px-8 py-1 ba-list-item" @click="item.callback">{{ item.title }}</a>
          </v-list>
        </v-card>
      </v-col>
      <v-col class="v-col-9">
        <UserProfile v-if="displayProfile"></UserProfile>
        <UserMgr v-else-if="displayAdminAllUsers"></UserMgr>
        <CourseMgr v-else-if="displayAdminAllCourses"></CourseMgr>
        <PaymentMgr v-else-if="displayAdminAllPayment"></PaymentMgr>
      </v-col>
    </v-row>
  </v-container>
</CommonPageFramework>
</template>
  
<script>
import { defineComponent } from 'vue';

import common from '@/assets/mixins/common.js';
  
// Components
import CommonPageFramework from '@/components/layout/CommonPageFramework.vue'
import UserProfile from '@/components/profile/UserProfile.vue'
import UserMgr from '@/components/profile/UserMgr.vue'
import CourseMgr from '@/components/profile/CourseMgr.vue'
import PaymentMgr from '@/components/profile/PaymentMgr.vue'
  
export default defineComponent({
  name: 'UserProfileView',
  mixins: [common],
  components: {
    CommonPageFramework,
    UserProfile,
    UserMgr,
    CourseMgr,
    PaymentMgr,
  },
  computed: {
    getUserItems() {
      return this.isLogined && this.isUser ? this.userItems : [];
    },
    getAdminItems() {
      return this.isLogined && this.isAdmin ? this.adminItem : [];
    },
    displayProfile() {
      return this.state == 'profile';
    },
    displayAdminAllUsers() {
      return this.state == 'userMgr';
    },
    displayAdminAllCourses() {
      return this.state == 'courseMgr';
    },
    displayAdminAllPayment() {
      return this.state == 'paymentMgr';
    }
  },
  data() {
    return {
      state: 'profile',
      userItems: [
        {
          title: 'User Profile',
          value: 'profile',
          callback: () => this.clicked('profile'),
        }
      ],
      adminItem: [
        {
          title: 'User Management',
          value: 'userMgr',
          callback: () => this.clicked('userMgr'),
        },
        {
          title: 'Course Management',
          value: 'courseMgr',
          callback: () => this.clicked('courseMgr'),
        },
        {
          title: 'Payment Management',
          value: 'paymentMgr',
          callback: () => this.clicked('paymentMgr'),
        }
      ],
    }
  },
  methods: {
    clicked(name) {
      this.state = name;
    }
  },
  created () {
    if (!this.isLogined) {
      this.$router.push('/')
    }
  }
});
</script>
  
<style lang="scss" scoped>
.ba-list-item {
  cursor: pointer;
}

.item-active {
  color: white;
  background-color: #460303;
  font-family: "Work Sans";
  font-weight: 600;
}
</style>
            