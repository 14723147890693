<template>
  <v-app>
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
  created: function() {
    var storeName = 'com.beyondactivity.session.store'
    
    // If the page is refreshed, load the store data from sessionStorage
    if (sessionStorage.getItem(storeName)) {
      this.$store.replaceState(Object.assign({}, this.$store.state, JSON.parse(sessionStorage.getItem(storeName))))
      sessionStorage.removeItem(storeName)
    }

    // window refresh event handler, if the page is refresh, save store data in sessionStorage
    window.addEventListener('beforeunload', ()=>{
      sessionStorage.setItem(storeName, JSON.stringify(this.$store.state))
    })
  },
}
</script>
