<template>
  <v-container>
    <v-row class="text-center py-2" justify="space-around" no-gutters>
      <v-col>
        <v-text-field :label="$t('common_fullname_example')" :error="!isFullnameValidForValidation" :error-messages="getFullnameErrorMessage(userFullname)" v-model="userFullname" hide-details="auto"></v-text-field>
      </v-col>
    </v-row>
    <v-row class="text-center py-2" justify="space-around" no-gutters>
      <v-col>
        <v-text-field :label="$t('common_email_example')" :error="!isEmailValidForValidation" :error-messages="getEmailErrorMessage(userEmail)" v-model="userEmail" hide-details="auto"></v-text-field>
      </v-col>
    </v-row>
    <v-row class="text-center py-2" justify="space-around" no-gutters>
      <v-col>
        <v-text-field :label="$t('common_phone')" :error="!isPhoneValidForValidation" :error-messages="getPhoneErrorMessage(userPhone)" v-model="userPhone" hide-details="auto"></v-text-field>
      </v-col>
    </v-row>
    <v-row class="text-center pt-10" justify="space-around" no-gutters>
      <v-col>
        <v-text-field :label="$t('common_password')" type="password" :error="!isPassword1ValidForValidation" :error-messages="getPassword1ErrorMessage(password1)" v-model="password1" hide-details="auto"></v-text-field>
      </v-col>
    </v-row>
    <v-row class="text-center py-2" justify="space-around" no-gutters>
      <v-col>
        <v-text-field :label="$t('common_repeatpassword')" type="password" :error="!isPassword2ValidForValidation" :error-messages="getPassword2ErrorMessage(password2)" v-model="password2" hide-details="auto"></v-text-field>
      </v-col>
    </v-row>

    <v-row class="text-center py-2" justify="space-around" no-gutters>
      <v-col>
        <v-btn :disabled="!ableToSignUp" :loading="isLoading" class="signup-btn" @click="signup">{{ $t('signinview_signup') }}</v-btn>
      </v-col>
    </v-row>

    <v-row class="text-center py-2" justify="space-around" no-gutters>
      <v-col>
          {{ $t('signup_stmt1') }} <router-link to="/" target="_blank">{{ $t('common_termsofservice') }}</router-link> {{ $t('common_and') }} <router-link to="/" target="_blank">{{ $t('common_privacypolicy') }}</router-link>.
      </v-col>
    </v-row>
  </v-container>
</template>
  
<script>
import Service from '@/service.js'
import common from '@/assets/mixins/common.js';
  
export default {
  name: 'ba-sign-up',
  mixins: [common],
  data: () => ({
      userFullname: null,
      userEmail: null,
      userPhone: null,
      password1: null,
      password2: null,
      loading: false,
  }),
  computed: {
    isFullnameValidForValidation() {
      return this.userFullname == null || this.validateFullname(this.userFullname);
    },
    isEmailValidForValidation() {
      return this.userEmail == null || this.validateEmail(this.userEmail);
    },
    isPhoneValidForValidation() {
      return this.userPhone == null || this.userPhone === '' || this.validatePhone(this.userPhone);
    },
    isPassword1ValidForValidation() {
      return this.password1 == null || this.password1.length >= 8;
    },
    isPassword2ValidForValidation() {
      return this.password2 == null || this.password2 == this.password1;
    },
    ableToSignUp() {
      return this.userFullname != null && this.isFullnameValidForValidation 
          && this.userEmail != null && this.isEmailValidForValidation 
          && this.isPhoneValidForValidation 
          && this.password1 != null && this.isPassword1ValidForValidation && this.isPassword2ValidForValidation;
    },
    isLoading() {
      return this.loading;
    }
  },
  methods: {
    signup() {
      if (this.userPhone == null) {
        this.userPhone = '';
      }

      this.loading = true;
      new Service().postUserSignUp(
        this.userFullname,
        this.userEmail,
        this.userPhone,
        this.password1,
        (response) => { // eslint-disable-line no-unused-vars
          this.$router.push('/signin');
        },
        (error) => { // eslint-disable-line no-unused-vars
          console.log(error);
        },
        () => {
          this.loading = false;
        }
      );
    },
    getPassword1ErrorMessage() {
      if (this.password1 == null) {
        return [];
      }

      if (this.password1.length < 8) {
        return [this.$t('signup_passwordtooshort')];
      }

      return []
    },
    getPassword2ErrorMessage() {
      if (this.password2 == null) {
        return [];
      }

      if (this.password2 != this.password1) {
        return [this.$t('signup_passwordnotmatch')];
      }

      return []
    }
  }
}
</script>

<style lang="scss" scoped>
.termOfService {
  font-family: "Proxima Nova", apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 0.75rem;
  color: rgb(159, 164, 181);
}

.signup-btn {
  font-family: "Work Sans";
  background-color: #460303;
  color: #ffffff;
  letter-spacing: 0rem;
  font-weight: 600;
  display: inline-block;
  font-size: 1.25rem;
  text-decoration: none;
  border-radius: 0.5rem;
  cursor: pointer;
  text-align: left;
  text-transform: none;
  margin-top: 1.5rem;
}
</style>
  