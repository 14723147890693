<template>
<CommonPageFramework>
  <v-container fill-height>
    <v-row justify="space-around">
      <v-col class="v-col-10">
        <v-tabs v-model="choice">
          <v-tab value="signin" @click="$router.push('/signin')">{{ $t('signinview_signin') }}</v-tab>
          <v-tab value="signup" @click="$router.push('/signup')">{{ $t('signinview_signup') }}</v-tab>
        </v-tabs>
      </v-col>
    </v-row>
    <v-row justify="space-around">
      <v-col class="v-col-10">
        <SignUpComponent v-if="isSignUp"></SignUpComponent>
        <SignInComponent v-else></SignInComponent>
      </v-col>
    </v-row>
  </v-container>
</CommonPageFramework>
</template>
  
<script>
import { defineComponent } from 'vue';
  
// Components
import CommonPageFramework from '@/components/layout/CommonPageFramework.vue'
import SignInComponent from '@/components/usermgr/SignIn.vue';
import SignUpComponent from '@/components/usermgr/SignUp.vue';
  
export default defineComponent({
  name: 'SignInView',
  components: {
    CommonPageFramework,
    SignInComponent,
    SignUpComponent
  },
  computed: {
    isSignUp() {
      return this.choice == 'signup'
    }
  },
  data() {
    return {
      choice: 'signin',
    }
  },
  watch: {
    $route (to){
      if (to.path == '/signup') {
        this.choice = 'signup';
      } else {
        this.choice = 'signin';
      }
    },
  },
  mounted() {
    if (this.$route.path == '/signup') {
      this.choice = 'signup';
    } else {
      this.choice = 'signin';
    }
  }
});
</script>
  
<style lang="scss" scoped>
</style>
        