<template>
    <v-container>
      <v-row class="text-center py-2" justify="space-around" no-gutters>
        <v-col>
          <v-table density="compact">
            <thead>
              <tr>
                <th>Name</th>
                <th>Amount</th>
                <th>Vendor</th>
                <th>Timestamp</th>
                <th>Description</th>
                <th>Phone</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="p in payments" :key="p.id">
                <td>{{ p.name }}</td>
                <td>{{ amountToStr(p.amount) }} {{ uppercase(p.currency) }}</td>
                <td>{{ p.vendor }}</td>
                <td>{{ p.created }}</td>
                <td>{{ p.description }}</td>
                <td>{{ p.phone }}</td>
              </tr>
            </tbody>
          </v-table>
        </v-col>
      </v-row>
    </v-container>
  </template>
        
  <script>
  import common from '@/assets/mixins/common.js';
  import Service from '@/service.js'
      
  export default {
    name: 'ba-admin-payment-page',
    mixins: [common],
    data: () => ({
      payments: []
    }),
    computed: {
    },
    methods: {
    },
    mounted() {
      this.payments = [];
      new Service().getAdminLoadPayment(
        this.getToken, 
        (response) => { // eslint-disable-line no-unused-vars
          this.payments = response.data.payments;
        },
        (error) => { // eslint-disable-line no-unused-vars
          console.log(error);
        }
      );
    }
  }
  </script>
      
  <style lang="scss" scoped>
  </style>
          