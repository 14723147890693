<template>
<v-container>
  <v-row class="text-center" justify="space-around" align-self="center">
    <v-col class="d-flex flex-row-reverse justify-center align-center v-col-xl-8 v-col-lg-10 v-col-md-10 py-0" align-self="center">
      <v-container fill-width>
        <v-row justify="space-between">
          <v-col v-if="$vuetify.display.smAndUp" class="v-col-3 d-flex flex-row" align-self="center">
            <router-link to="/" >
              <v-img src="@/assets/logo.png" class="logo"></v-img>
            </router-link>
          </v-col>
          <v-col>
            <v-container fill-width>
              <v-row justify="center">
                <v-col v-if="$vuetify.display.smAndUp"></v-col>
                <v-col class="v-col-auto px-0 pr-6">
                  <p class="footer-title pb-2" style="text-align: left;">{{ $t('common_company') }}</p>
                  <ul style="list-style: none; text-align: left;">
                    <li><router-link to="/courses" class="pl-2 py-1 link">{{ $t('common_courses') }}</router-link></li>
                    <li><router-link to="/contact-us" class="pl-2 py-1 link">{{ $t('common_contactus') }}</router-link></li>
                    <li><router-link to="/about" class="pl-2 py-1 link">{{ $t('common_aboutus') }}</router-link></li>
                    <li v-if="isInEnglish"><router-link to="/admin" class="pl-2 py-1 link">{{ $t('common_admin_portal') }}</router-link></li>
                  </ul>
                </v-col>
                <v-col class="v-col-auto px-0 pr-6">
                  <p class="footer-title pb-2" style="text-align: left; visibility: hidden;">{{ $t('common_company') }}</p>
                  <ul style="list-style: none; text-align: left;">
                    <li><a href="/privacypolicy" class="pl-2 py-1 link" target="_blank">{{ $t('common_privacypolicy') }}</a></li>
                    <li><a href="/termsofservice" class="pl-2 py-1 link" target="_blank">{{ $t('common_termsofservice') }}</a></li>
                    <li><a href="/serviceprovideragreement" class="pl-2 py-1 link" target="_blank">{{ $t('common_serviceprovideraggrement') }}</a></li>
                  </ul>
                </v-col>
                <v-col class="v-col-auto px-1">
                  <v-container class="px-0">
                    <v-row class="px-0">
                      <v-col class="v-col-auto px-1">
                        <p class="footer-title pb-2"><font-awesome-icon v-if="$vuetify.display.smAndUp" icon="fa-brands fa-weixin" class="px-1"/>WeChat</p>
                        <img src="https://resource.beyondactivity.com/wechat_qrcode.png" width="80" height="80">
                      </v-col>
                      <v-col class="v-col-auto px-1">
                        <p class="footer-title pb-2"><font-awesome-icon v-if="$vuetify.display.smAndUp" icon="fa-brands fa-whatsapp" class="px-1"/>WhatsApp</p>
                        <img src="https://resource.beyondactivity.com/whatsapp_qrcode.jpg" width="80" height="80">
                      </v-col>
                    </v-row>
                  </v-container>
                </v-col>
              </v-row>
            </v-container>
          </v-col>
        </v-row>
      </v-container>
    </v-col>
  </v-row>
  <v-row justify="space-around">
    <v-col class="v-col-xl-8 v-col-lg-10 v-col-md-10">
      <v-divider></v-divider>
    </v-col>
  </v-row>
  <v-row justify="space-around">
    <v-col class="v-col-xl-8 v-col-lg-10 v-col-md-10">
      <small class="footer-text">© 2022 All rights reserved.</small>
    </v-col>
  </v-row>
</v-container>
</template>
  
<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import common from '@/assets/mixins/common.js';

export default {
  name: 'ba-footer',
  mixins: [common],
  components: {
    FontAwesomeIcon,
  },
  data: () => ({
  }),
  methods: {
  }
}
</script>

<style lang="scss" scoped>
.logo {
  width: 64px;
  height: 64px;
}

.link {
  font-family: sans-serif;
  letter-spacing: 0rem;
  font-weight: 600;
  font-size: 1rem;
  color: #000;
  display: block;
  text-decoration: none;
  background-color: transparent;
  cursor: point;
  list-style: none;
  white-space: nowrap;
}

.footer-title {
  color: #2b0101;
  font-weight: 600;
  font-family: sans-serif;
  display: block;
  font-size: 1rem;
  white-space: pre-line;
  line-height: 1.5;
}

.footer-text {
  color: #2b0101;
  font-weight: 300;
  font-family: sans-serif;
  display: block;
  padding-bottom: 1.25rem;
  padding-top: 0rem;
  font-size: 1rem;
  white-space: pre-line;
  line-height: 1.5;
  text-align: left;
}
</style>
  